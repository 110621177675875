export enum ErrorEnums {
    SERVER_ERROR = 'SERVER_ERROR',
    FORBIDDEN = 'FORBIDDEN',
    UNAUTHORIZED = 'UNAUTHORIZED',
    ACCOUNT_ALREADY_EXIST = 'ACCOUNT_ALREADY_EXIST',
    ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND',
    PASSWORD_INCORRECT = 'PASSWORD_INCORRECT',
    ACCOUNT_BLOCKED = 'ACCOUNT_BLOCKED',
    INVALID_DATA = 'INVALID_DATA',
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    INVALID_FILE = 'INVALID_FILE',
    CATEGORY_ALREADY_EXIST = 'CATEGORY_ALREADY_EXIST',
    SUBCATEGORY_ALREADY_EXIST = 'SUBCATEGORY_ALREADY_EXIST',
    MERCHANT_ALREADY_EXIST = 'MERCHANT_ALREADY_EXIST',
    TRANSACTION_LABEL_NOT_FOUND = 'TRANSACTION_LABEL_NOT_FOUND',
    TRANSACTION_LABEL_ALREADY_EXISTS = 'TRANSACTION_LABEL_ALREADY_EXISTS',
    PROVIDER_ALREADY_EXISTS = 'PROVIDER_ALREADY_EXISTS',
    PROVIDER_NOT_FOUND = 'PROVIDER_NOT_FOUND',
    MERCHANT_MAPPER_ALREADY_EXISTS = 'MERCHANT_MAPPER_ALREADY_EXISTS',
    MERCHANT_MAPPER_NOT_FOUND = 'MERCHANT_MAPPER_NOT_FOUND',
    DELETE_ACTIVE_TARGET_AUDIENCE_RESTRICTED = 'DELETE_ACTIVE_TARGET_AUDIENCE_RESTRICTED',
    TARGETED_USERS_LESS_THAN_SECRETS = 'TARGETED_USERS_LESS_THAN_SECRETS',
    TARGETED_USERS_GREATER_THAN_SECRETS = 'TARGETED_USERS_GREATER_THAN_SECRETS',
    NO_USER_TARGETED = 'NO_USER_TARGETED',
    TARGET_AUDIENCE_NOT_FOUND = 'TARGET_AUDIENCE_NOT_FOUND',
    UPDATE_ACTIVE_TARGET_AUDIENCE_RESTRICT = 'UPDATE_ACTIVE_TARGET_AUDIENCE_RESTRICT',
    INVALID_VERIFICATION_CODE = 'INVALID_VERIFICATION_CODE',
    VERIFICATION_CODE_EXPIRED = 'VERIFICATION_CODE_EXPIRED',
    ACCOUNT_NOT_VERIFIED = 'ACCOUNT_NOT_VERIFIED',
    INVALID_EMAIL = 'INVALID_EMAIL',
    CATEGORY_NOT_FOUND = 'CATEGORY_NOT_FOUND',
    SUBCATEGORY_NOT_FOUND = 'SUBCATEGORY_NOT_FOUND',
    MERCHANT_NOT_FOUND = 'MERCHANT_NOT_FOUND',
    BANKACCOUNT_NOT_FOUND = 'BANKACCOUNT_NOT_FOUND',
    BANKACCOUNT_OUT_LIST = 'BANKACCOUNT_OUT_LIST',
    BANKACCOUNT_ALREADY_EXISTS = 'BANKACCOUNT_ALREADY_EXISTS',
    INVALID_CURRENCY = 'INVALID_CURRENCY',
    CATEGORY_MAP_NOT_FOUND = 'CATEGORY_MAP_NOT_FOUND',
    CATEGORY_MAP_ALREADY_EXISTS = 'CATEGORY_MAP_ALREADY_EXISTS',
    FILTER_NOT_FOUND = 'FILTER_NOT_FOUND',
    AREA_NOT_FOUND = 'AREA_NOT_FOUND',
    DELETE_BANK_ACCOUNT_RESTRICTED = 'DELETE_BANK_ACCOUNT_RESTRICTED',
    SMS_SERVICE_ERROR = 'SMS_SERVICE_ERROR',
    BANK_ACCOUNT_MISSING = 'BANK_ACCOUNT_MISSING',
    SALT_EDGE_ERROR = 'SALT_EDGE_ERROR',
    CITY_NOT_FOUND = 'CITY_NOT_FOUND',
    UPDATE_ACTIVE_TARGET_AUDIENCE_RESTRICTED = 'UPDATE_ACTIVE_TARGET_AUDIENCE_RESTRICTED',
    PROVIDER_ALREADY_LINKED = 'PROVIDER_ALREADY_LINKED',
    NOTE_NOT_FOUND = 'NOTE_NOT_FOUND',
    COUNTRY_NOT_FOUND = 'COUNTRY_NOT_FOUND',
    TRANSACTION_NOT_FOUND = 'TRANSACTION_NOT_FOUND',
    TRANSACTION_REPORT_NOT_FOUND = 'TRANSACTION_REPORT_NOT_FOUND',
    BUDGET_NOT_FOUND = 'BUDGET_NOT_FOUND',
    MERCHANT_INFORMATION_MISSING = 'MERCHANT_INFORMATION_MISSING',
    OFFER_NOT_FOUND = 'OFFER_NOT_FOUND',
    NEWS_NOT_FOUND = 'NEWS_NOT_FOUND',
    DELETE_BANK_CARD_RESTRICTED = 'DELETE_BANK_CARD_RESTRICTED',
    FEEDBACK_NOT_FOUND = 'FEEDBACK_NOT_FOUND',
    UPDATING_IN_USE_SECRET_IS_RESTRICTED = 'UPDATING_IN_USE_SECRET_IS_RESTRICTED',
    REVOKING_IN_USE_SECRET_IS_RESTRICTED = 'REVOKING_IN_USE_SECRET_IS_RESTRICTED',
    OFFER_EXPIRED = 'OFFER_EXPIRED',
    CONNECTION_INACTIVE = 'CONNECTION_INACTIVE',
    PHONE_NUMBER_USE_EXCEEDED = 'PHONE_NUMBER_USE_EXCEEDED',
    SECRETS_NOT_FOUND = 'SECRETS_NOT_FOUND',
    OFFER_NOT_ENABLED = 'OFFER_NOT_ENABLED',
    REQUEST_ID_NOT_FOUND = 'REQUEST_ID_NOT_FOUND',
    REQUEST_EXPIRED = 'REQUEST_EXPIRED',
    TWO_FACTOR_ENABLED = 'TWO_FACTOR_ENABLED',
    TWO_FACTOR_NOT_ENABLED = 'TWO_FACTOR_NOT_ENABLED',
    RSA_PUBLIC_KEY_MISSING = 'RSA_PUBLIC_KEY_MISSING',
    BAD_ENCRYPTION = 'BAD_ENCRYPTION',
    TWO_FACTOR_REQUIRED = 'TWO_FACTOR_REQUIRED',
    VERSION_NOT_FOUND = 'VERSION_NOT_FOUND',
    SESSION_NOT_FOUND = 'SESSION_NOT_FOUND',
    SESSION_DELETED_OR_EXPIRED = 'SESSION_DELETED_OR_EXPIRED',
    PRIORITY_UNAVAILABLE = 'PRIORITY_UNAVAILABLE',
    BUSINESS_ACCOUNT_PLAN_IS_MISSING = 'BUSINESS_ACCOUNT_PLAN_IS_MISSING',
    BUSINESS_PLAN_IN_USE = 'BUSINESS_PLAN_IN_USE'

}