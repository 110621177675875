import {FunctionComponent} from "react";
import {Form} from "react-bootstrap";
import "../settings.scss"
import {PasswordFromProp} from "../../../Models/Props/Settings/PassworFromProp";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useAppDispatch, useAppSelector} from "../../../Store/hooks";
import {UpdatePasswordAsync} from "../../../Api/Settings/SettingsApi";
import {EcdhAesEncryption} from "../../../Security/Crypto/Ecc/EcdhAesEncryption";
import {NewPasswordModel} from "../../../Models/Api/Login/NewPasswordModel";
import {EncryptedHeader} from "../../../Api/Login/encryptedHeader";
import {settingActionLoading} from "../../../Store/Settings/SettingsSlice";
import SButton from "../../UI/Button/Button";

export const PasswordForm: FunctionComponent<PasswordFromProp> = (props) => {
    const serverKey = process.env.REACT_APP_SERVER_KEY;
    const dispatch = useAppDispatch();
    const loading = useAppSelector(settingActionLoading);

    const Submit = (values: NewPasswordModel) => {
        if (serverKey) {
            const keys = EcdhAesEncryption.fetchKeys();
            const nonce = EcdhAesEncryption.generatePRNG();
            const newPasswordCipher = EcdhAesEncryption.encrypt(
                keys.privateKey,
                serverKey,
                Buffer.from(values.newPassword),
                nonce
            )
            const oldPasswordCipher = EcdhAesEncryption.encrypt(
                keys.privateKey,
                serverKey,
                Buffer.from(values.currentPassword),
                nonce
            )
            const model: NewPasswordModel = {
                currentPassword: oldPasswordCipher.base64Cipher ?? "",
                newPassword: newPasswordCipher.base64Cipher ?? ""
            }
            const headers: EncryptedHeader = {
                nonce: Buffer.from(nonce).toString('base64'),
                publicKey: Buffer.from(keys.publicKey).toString('base64'),
            }
            dispatch(UpdatePasswordAsync({model, headers})).then(e => {
                if (e.meta.requestStatus === "fulfilled") {
                    props.getData(values.currentPassword, values.newPassword);
                }
            })
        }

    }
    const formBuilder = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
        },
        //TODO: Fix regex
        validationSchema: Yup.object().shape({
            currentPassword: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Please enter a strong password").required("Old password is mandatory"),
            newPassword: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Please enter a strong password").required("New password is mandatory"),
        }),
        onSubmit: Submit,
    })


    /**
     * Template
     */
    return (
        <div>
            <div className="settings-header">
                <h4>Password</h4>
            </div>
            <Form onSubmit={formBuilder.handleSubmit}>
                <Form.Group>
                    <Form.Label>Enter old password</Form.Label>
                    <Form.Control     autoComplete="off"   
                        type="password"
                        name="currentPassword"
                        value={formBuilder.values.currentPassword}
                        onChange={formBuilder.handleChange}
                    />
                    {formBuilder.errors.currentPassword ?
                        <p className="errors">{formBuilder.errors.currentPassword}</p> : null}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Enter new password</Form.Label>
                    <Form.Control     autoComplete="off"   
                        type="password"
                        name="newPassword"
                        value={formBuilder.values.newPassword}
                        onChange={formBuilder.handleChange}
                    />
                    {formBuilder.errors.newPassword ? <p className="errors">{formBuilder.errors.newPassword}</p> : null}
                </Form.Group>
                <div className="dialog-footer">
                    <SButton type="button" label="Cancel" class="warning" onClick={()=>props.close()}/>
                    <SButton type="submit" label="Save Changes"/>
                </div>
                <div className="w-100 ">
                    {loading && <div className="progress">
                        <div className="progress-bar  indeterminate">
                        </div>
                    </div>}

                </div>
            </Form>
        </div>
    )
}