import { Skeleton,SkeletonShapeType } from 'primereact/skeleton';
interface IState{
    shape:SkeletonShapeType,
    size?: string;
    width?: string;
    height?: string;
    borderRadius?: string;
    className?:string
}
function SkeletonLoader(props:IState)  {
  return <Skeleton className={props.className}
    shape={props.shape}
    height={props.height}
    width={props.width}
    size={props.size}
    borderRadius={props.borderRadius || '35px'}
  />
}

export default SkeletonLoader