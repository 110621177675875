import { FormEvent, FunctionComponent, useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { UserModalNames } from "../../../Models/Enums/Users/UserModalNames";
import { DeleteUserProp } from "../../../Models/Props/Users/DeleteUserProp";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { usersActionLoading } from "../../../Store/Users/UsersSlice";
import { DisableUserAsync } from "../../../Api/Users/UsersApi";
import { UserActionsModel } from "../../../Models/Api/Users/UserActionsModel";
import { EcdhAesEncryption } from "../../../Security/Crypto/Ecc/EcdhAesEncryption";
import { EncryptedHeader } from "../../../Api/Login/encryptedHeader";
import { useOutsideAlerter } from "../../../Hooks/ClickOutside";
import SButton from "../../UI/Button/Button";


export const DisableUser: FunctionComponent<DeleteUserProp> = (props) => {
    const serverKey = process.env.REACT_APP_SERVER_KEY;
    const dispatch = useAppDispatch();
    /**
     * State
     */
    const actionLoading = useAppSelector(usersActionLoading);
    const [userId, setUserId] = useState<string>(props.id)
    const [password, setPassword] = useState<string>("")
    const [error, setError] = useState<boolean>(false)

    /**
     * form submit handler
     * @param e
     */
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (password === "") {
            setError(true);
            return;
        } else {
            setError(false);
        }
        const keys = EcdhAesEncryption.fetchKeys();
        const cipher = EcdhAesEncryption.encrypt(
            keys.privateKey,
            serverKey ?? "",
            Buffer.from(password),
        );
        const data: UserActionsModel = {
            id: props.id,
            fullName: props.title,
            password: cipher.base64Cipher ?? ""
        }
        const headers: EncryptedHeader = {
            publicKey: Buffer.from(keys.publicKey).toString('base64'),
            nonce: cipher.base64Nonce ?? "",
        }
        dispatch(DisableUserAsync({ user: data, headers: headers })).then(e => {
            if (e.meta.requestStatus === "fulfilled") {
                props.getData(userId);
            }
        })
    }

    const modalRef = useRef(null);
    const outside = useOutsideAlerter(modalRef)
    useEffect(() => {
        if (outside) {
            props.close(UserModalNames.DISABLE_USER)
        }
    }, [outside])


    return (
        <Modal ref={modalRef} title="user-disable-modal" show={props.show}>
            <Modal.Header className="dialog-header">
                <Modal.Title className="dailog-header_title">
                    Disable {props.title}
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body className="dailog-body">
                    <Form.Group className="dialog_warning-message_wrapper">
                        <Form.Label className="dialog_warning-message">Are you sure you want to disable this
                            user ?</Form.Label>
                        <Form.Control autoComplete="off" onChange={(e) =>
                            setUserId(e.target.value)}
                            type="hidden"
                            value={props.id}
                        >
                        </  Form.Control> </Form.Group>
                    <Form.Group className="dialog_warning-message_wrapper w-100">
                        <Form.Label>Password</Form.Label>
                        <Form.Control autoComplete="off" onChange={(e) =>
                            setPassword(e.target.value)}
                            type="password"
                            className="w-100"
                            value={password}
                        >
                        </  Form.Control> </Form.Group>
                    {error && <p className="errors">Password is manadatory</p>}
                </Modal.Body>
                <Modal.Footer className="dialog-footer">
                    <SButton label="Close" class="warning" onClick={() => props.close(UserModalNames.DISABLE_USER)} type={"button"} />
                    <SButton type="submit" label="Submit" />
                </Modal.Footer>
                <div className="w-100 ">
                    {actionLoading && <div className="progress">
                        <div className="progress-bar  indeterminate">
                        </div>
                    </div>}
                </div>
            </Form>
        </Modal>
    )
}