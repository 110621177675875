import {createAsyncThunk} from "@reduxjs/toolkit";
import {ServerResponse} from "../../Models/Api/ServerResponse";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {api} from "../api";
import {UsersRoutes} from "../ApiConstants";
import {UserModel} from "../../Models/Api/Users/UserModel";
import {UserFilterModel} from "../../Models/Api/Users/UserFilterModel";
import {EditUserModel} from "../../Models/Api/Users/EditUserModel";
import {RoleModel} from "../../Models/Api/Users/RoleModel";
import {UserActionsModel} from "../../Models/Api/Users/UserActionsModel";
import {UserActionNames} from "../../Models/Enums/Users/UserActionNames";
import {UsersListResponseModel} from "../../Models/Api/Users/UsersListResponseModel";
import {AddUserModel} from "../../Models/Api/Users/AddUserModel";
import {EncryptedHeader} from "../Login/encryptedHeader";


/**
 * Get Users list
 */
export const FetchUsersListAsync =
    createAsyncThunk<ServerResponse<UsersListResponseModel>, { filter: UserFilterModel }>(
        UserActionNames.ListUsersAction,
        async ({filter}, thunkAPI) => {
            const response: AxiosResponse<ServerResponse<UsersListResponseModel>>
                = await api.post(UsersRoutes.ListUsersUrl, filter, {
                signal: thunkAPI.signal,
            });
            return response.data;
        }
    )

export const AddUserAsync =
    createAsyncThunk<ServerResponse<string>, { user: AddUserModel }>(
        UserActionNames.AddUserAction,
        async ({user}) => {
            const response: AxiosResponse<ServerResponse<string>>
                = await api.post(UsersRoutes.AddUsersUrl, user);
            return response.data;
        }
    )

/**
 * Get User
 */
export const GetUserAsync =
    createAsyncThunk<ServerResponse<UserModel>, { id: string }>(
        UserActionNames.GetUserAction,
        async ({id}) => {
            const response: AxiosResponse<ServerResponse<UserModel>>
                = await api.get(`${UsersRoutes.GetUserUrl}?id=${id}`);
            return response.data;
        }
    )

/**
 * Edit User
 */
export const EditUserAsync =
    createAsyncThunk<ServerResponse<string>, { user: EditUserModel }>(
        UserActionNames.UpdateUserAction,
        async ({user}) => {
            const response: AxiosResponse<ServerResponse<string>>
                = await api.post(UsersRoutes.UpdateUserUrl, user);
            return response.data;
        }
    )

/**
 * Delete User
 */
export const DeleteUserAsync =
    createAsyncThunk<ServerResponse<string>, { user: UserActionsModel, headers: EncryptedHeader }>(
        UserActionNames.DeleteUserAction,
        async ({user, headers}) => {
            const config: AxiosRequestConfig =
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        nonce: headers.nonce,
                        publicKey: headers.publicKey,
                    }
                };
            const response: AxiosResponse<ServerResponse<string>>
                = await api.put(`${UsersRoutes.DeleteUserUrl}?id=${user.id}`, user, config);
            return response.data;
        }
    )

/**
 * Set role User
 */
export const SetRoleUserAsync =
    createAsyncThunk<ServerResponse<string>, { roles: RoleModel }>(
        UserActionNames.UpdateRoleAction,
        async ({roles}) => {
            const response: AxiosResponse<ServerResponse<string>>
                = await api.post(UsersRoutes.UpdateRoleUrl, roles);
            return response.data;
        }
    )

/**
 * Enable  User
 */
export const EnableUserAsync =
    createAsyncThunk<ServerResponse<string>, { user: UserActionsModel, headers: EncryptedHeader }>(
        UserActionNames.UnLockUserAction,
        async ({user, headers}) => {
            const config: AxiosRequestConfig =
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        nonce: headers.nonce,
                        publicKey: headers.publicKey,
                    }
                };
            const response: AxiosResponse<ServerResponse<string>>
                = await api.patch(UsersRoutes.UnLockUserUrl, user, config);
            return response.data;
        }
    )


/**
 * Disable  User
 */
export const DisableUserAsync =
    createAsyncThunk<ServerResponse<string>, { user: UserActionsModel, headers: EncryptedHeader }>(
        UserActionNames.LockUserAction,
        async ({user, headers}) => {
            const config: AxiosRequestConfig =
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        nonce: headers.nonce,
                        publicKey: headers.publicKey,
                    }
                };
            const response: AxiosResponse<ServerResponse<string>>
                = await api.patch(UsersRoutes.LockUserUrl, user, config);
            return response.data;
        }
    )


