import {DateRangePicker} from "react-date-range";
import {addDays} from "date-fns";
import {faCalendar, faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Moment from 'moment';
import React, {FunctionComponent, useEffect, useState} from "react";
import "./Datepicker.scss";
import {DatepickerProps} from "../../Models/Props/Datepicker/DatepickerProps";

export const Datepicker: FunctionComponent<DatepickerProps> = ({getDate}) => {
    /**
     * initial date state
     */
    const selectionRange = [{
        startDate: addDays(new Date(), -30),
        endDate: new Date(),
        key: 'selection'
    }];

    /**
     * Format date
     * @param date
     */
    const formatDate = (date: Date) => {
        return Moment(date).format("MMMM D, YYYY");
    }

    /**
     * States
     */
    const [startDate, setStartDate] = useState(formatDate(selectionRange[0].startDate))
    const [endDate, setEndDate] = useState(formatDate(selectionRange[0].endDate))
    const [date, setDate] = useState(selectionRange)
    const [show, setShow] = useState(false);

    /**
     * Show/hide datepicker selector
     */
    const openSelector = () => {
        setShow(prevState => {
            return !prevState;
        })
    }

    /**
     * Update selected date
     * @param ranges
     */
    const handleDateSelect = (ranges: any) => {
        setDate([ranges.selection])
        if (ranges.selection.startDate < ranges.selection.endDate) {
            setStartDate(formatDate(ranges.selection.startDate));
            setEndDate(formatDate(ranges.selection.endDate));
        }
    }

    useEffect(() => {
        /**
         * Event emitter for date
         */
        getDate({startDate: startDate, endDate: endDate});
    }, [date])


    /**
     * Template
     */
    return (
        <div className="datepicker">
            <div role="selector-container" onClick={() => openSelector()} className="datepicker_input">
                <FontAwesomeIcon icon={faCalendar}/>
                <span role="selected-date">{`${startDate} - ${endDate}`}</span>
                <FontAwesomeIcon icon={faCaretDown}/>
            </div>
            <DateRangePicker
                className={show ? "datepicker_selector_visible" : "datepicker_selector"}
                onChange={(e) => handleDateSelect(e)}
                moveRangeOnFirstSelection={false}
                months={2}
                direction="horizontal"
                ranges={date}
                dateDisplayFormat="MMMM d, yyyy"
            />
        </div>
    )
}