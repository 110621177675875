import {EcdhAesCipherModel} from "./EcdhAesCipherModel";
import secureRandom from 'secure-random';
import hkdf from 'futoin-hkdf';
import * as forge from 'node-forge';
import {EccKeys} from "./EccKeys";
import {SecureStore} from "../Storage/SecureStore";
import {StoreKeys} from "../Storage/StoreKeys";
const Ecc = require('ec-key-patch')

export class EcdhAesEncryption {

    /**
     * Encrypt data using AES-CBC-256
     * @param privateKey
     * @param publicKey
     * @param data
     * @param nonce
     * @param keyLength
     */
    static encrypt(
        privateKey: string,
        publicKey: string,
        data: Buffer,
        nonce: Uint8Array = new Uint8Array(),
        keyLength = 32,
    ): EcdhAesCipherModel {
        const shared = this.getAgreement(privateKey, publicKey);
        const random =
            nonce !== null && nonce.length !== 0
                ? nonce
                : this.generatePRNG(keyLength);
        const key = this.driveKey(shared, Buffer.from(random));
        const cipher = forge.cipher.createCipher(
            'AES-CBC',
            forge.util.createBuffer(key),
        );
        cipher.start({
            iv: forge.util.createBuffer(key.slice(0, keyLength / 2)),
        });
        cipher.update(forge.util.createBuffer(data));
        cipher.finish();
        const out = cipher.output.getBytes();
        const model = new EcdhAesCipherModel();
        model.cipher = Buffer.from(out, 'binary');
        model.nonce = Buffer.from(random);
        model.base64Cipher = model.cipher.toString('base64');
        model.base64Nonce = model.nonce.toString('base64');
        return model;
    }

    /**
     * Decrypt data using AES-CBC-256
     * @param privateKey
     * @param publicKey
     * @param data
     * @param nonce
     * @param keyLength
     */
    static decrypt(
        privateKey: string,
        publicKey: string,
        data: Buffer,
        nonce: Uint8Array,
        keyLength = 32,
    ): Buffer {
        const shared = this.getAgreement(privateKey, publicKey);
        const key = this.driveKey(shared, Buffer.from(nonce));
        const decipher = forge.cipher.createDecipher(
            'AES-CBC',
            forge.util.createBuffer(key),
        );
        decipher.start({
            iv: forge.util.createBuffer(key.slice(0, keyLength / 2)),
        });

        decipher.update(forge.util.createBuffer(data));
        decipher.finish();
        return Buffer.from(decipher.output.getBytes(), 'binary');
    }

    /**
     * Load or create Ecc keys
     */
    static fetchKeys() {
        const model = new EccKeys();
        if (
            SecureStore.isExists(StoreKeys.EC_PRIVATE_KEY) &&
            SecureStore.isExists(StoreKeys.EC_PUBLIC_KEY)
        ) {
            model.privateKey = SecureStore.getItem(StoreKeys.EC_PRIVATE_KEY, true) ?? "";
            model.publicKey = SecureStore.getItem(StoreKeys.EC_PUBLIC_KEY, true) ?? "";
            return model;
        }

        const key = Ecc.createECKey('P-256');
        model.privateKey = key.toString('pem');
        model.publicKey = key.asPublicECKey().toString('pem');
        SecureStore.setItem(StoreKeys.EC_PRIVATE_KEY, model.privateKey, true);
        SecureStore.setItem(StoreKeys.EC_PUBLIC_KEY, model.publicKey, true);
        return model;
    }

    /**
     * Get ECDH Agreement
     * @param privateKey
     * @param publicKey
     * @private
     */
    private static getAgreement(privateKey: string, publicKey: string): Buffer {
        const serverPublic = new Ecc(publicKey, 'pem');
        const key = new Ecc(privateKey, 'pem');
        return key.computeSecret(serverPublic);
    }

    /**
     * Derive key using HKDF
     * @param shared
     * @param nonce
     * @param KeyLength
     * @private
     */
    private static driveKey(
        shared: Buffer,
        nonce: Buffer,
        KeyLength: number = 32,
    ): Buffer {
        return hkdf(shared, KeyLength, {salt: nonce, info: '', hash: 'SHA-256'});
    }

    /**
     * Generate pseudo random number
     * @param keyLength
     */
    static generatePRNG(keyLength: number = 32): Uint8Array {
        return secureRandom(keyLength, {type: 'Uint8Array'})
    }


    /**
     *
     * @param data
     */
    static stringFromArray(data: any[]): string {
        const count = data.length;
        let str = '';
        for (let index = 0; index < count; index++) {
            str += data[index].toString('utf8');
        }
        return str;
    }


}