import {createAsyncThunk} from "@reduxjs/toolkit";
import {ServerResponse} from "../../Models/Api/ServerResponse";
import {CustomerInvoiceModel} from "../../Models/Api/CustomerInvoice/CustomerInvoiceModel";
import {InvoiceActionNames} from "../../Models/Enums/Invoice/InvoiceActionNames";
import {api} from "../api";
import {InvoiceRoutes} from "../ApiConstants";
import {AxiosResponse} from "axios";
import {SimpleServerResponse} from "../../Models/Api/SimpleServerResponse";


export const ListInvoiceAsync = createAsyncThunk<ServerResponse<CustomerInvoiceModel[]>, { start: string, perPage: string }>(
    InvoiceActionNames.ListInvoiceAction,
    async (data) => {
        const response: AxiosResponse<ServerResponse<CustomerInvoiceModel[]>>
            = await api.get(`${InvoiceRoutes.ListInvoiceUrl}?start=${data.start}&perPage=${data.perPage}`);
        return response.data;
    }
);


export const AddInvoiceAsync = createAsyncThunk<ServerResponse<string>, { model: CustomerInvoiceModel }>(
    InvoiceActionNames.AddInvoiceAction,
    async (data) => {
        const response: AxiosResponse<ServerResponse<string>>
            = await api.post(InvoiceRoutes.AddInvoiceUrl, data.model);
        return response.data;
    }
);

export const GetInvoiceAsync = createAsyncThunk<ServerResponse<CustomerInvoiceModel>, { id: string }>(
    InvoiceActionNames.GetInvoiceAction,
    async (data) => {
        const response: AxiosResponse<ServerResponse<CustomerInvoiceModel>>
            = await api.get(`${InvoiceRoutes.GetInvoiceUrl}?id=${data.id}`);
        return response.data;
    }
)

export const GetInvoicePdf = createAsyncThunk<File, { id: string }>(
    InvoiceActionNames.GetPdfInvoiceAction,
    async (data) => {
        const response: AxiosResponse<File>
            = await api.get(`${InvoiceRoutes.GetInvoicePdfUrl}?id=${data.id}`, {
            responseType: "blob"
        });
        return response.data;
    }
)

export const DeleteInvoiceAsync = createAsyncThunk<SimpleServerResponse, { id: string }>(
    InvoiceActionNames.DeleteInvoiceAction,
    async (data) => {
        const response: AxiosResponse<SimpleServerResponse>
            = await api.delete(`${InvoiceRoutes.DeleteInvoiceUrl}?id=${data.id}`);
        return response.data;
    }
)