import {FunctionComponent} from "react";
import ReactECharts from "echarts-for-react";
import {PieChartOptions} from "./options";
import "./PieChart.scss";
import {PieChartProp} from "../../../Models/Props/PieChartProp";

export const PieChart: FunctionComponent<PieChartProp> = (props) => {

    const options = props.data ? PieChartOptions(props.data) : PieChartOptions({
        data: [],
        labels: []
    });
    return (
        <div className="pie-chart">
            <div className="pie-chart_header">
                {props.title}
            </div>
            <div className="pie-chart_container">
                <ReactECharts option={options}/>
            </div>
        </div>
    )
}