import {  useFormik } from 'formik'
import * as Yup from 'yup';
import { Form } from 'react-bootstrap'
import { BusinessApiKeyModel } from '../../../../Models/Api/Settings/BusinessApiKeyModel'
import Modal from '../../../UI/Modal/Modal'
import './UpdateAPICallBackUrl.scss'
import SButton from '../../../UI/Button/Button';
import { useAppDispatch } from '../../../../Store/hooks';
import { UpdateApiCallBackUrl } from '../../../../Api/Settings/SettingsApi';
import { onReadMore } from '../../../../Models/Enums/AppEnviroment';
interface IState {
    data: BusinessApiKeyModel
    hideModal: () => void
}
interface FormState{
    callbackUrl:string
}
function UpdateAPICallBackUrl(props: IState) {
    const dispatch = useAppDispatch();
    const form = useFormik<FormState>({
        initialValues: {
            callbackUrl: props.data.callbackUrl ?? ""
        },
        onSubmit: handleFormSubmit,
        validationSchema: Yup.object({
            callbackUrl: Yup.string().url('Please provide a valid URL').matches(/^((https):\/\/)/, "URL must start with https://").required('Callback URL is mandatory')
        })
    });
    function handleFormSubmit(event: FormState) {
        props.hideModal();
        dispatch(UpdateApiCallBackUrl({model:{callbackUrl:event.callbackUrl,id:props.data.id!}}))
    }


    return (
        <Modal hideModal={props.hideModal}>
            <div className="header">{props.data.callbackUrl?'Update':'Add'} Callback URL</div>
            <Form className="content" onSubmit={form.handleSubmit}>
            <p className="callback-note">Due to security reasons, the callback can only be sent over the port 443/HTTPS !
 Also, the callback does not follow redirects.
                <div>
                    <a href={onReadMore()} target='_blank'>Read more about callback URL</a>
                </div>
            </p>
                <Form.Group>
                    <Form.Label className='required-dot'>Callback URL</Form.Label>
                    <Form.Control name='callbackUrl' value={form.values.callbackUrl} onChange={form.handleChange} />
                    {form.errors.callbackUrl ? <p className="errors">{form.errors.callbackUrl}</p> : null}
                </Form.Group>
                <div className="dialog-footer pt-1">

                    <SButton onClick={props.hideModal} label="Cancel" type="button" class="warning" />
                    <SButton type="submit" label="Update" />
                </div>
            </Form>

        </Modal>
    )
}

export default UpdateAPICallBackUrl