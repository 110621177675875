import {createAsyncThunk} from "@reduxjs/toolkit";
import {ServerResponse} from "../../Models/Api/ServerResponse";
import {DataTypeModel} from "../../Models/Api/Invoice/DataTypeModel";
import {FormActionNames} from "../../Models/Enums/Forms/FormActionNames";
import {api} from "../api";
import {FormRoutes} from "../ApiConstants";
import {AxiosResponse} from "axios";
import {InvoiceModel} from "../../Models/Api/Invoice/InvoiceModel";


export const ListDataTypeAsync = createAsyncThunk<ServerResponse<DataTypeModel[]>>(
    FormActionNames.ListDatatypeAction,
    async () => {
        const response: AxiosResponse<ServerResponse<DataTypeModel[]>> = await api.get(FormRoutes.ListDataTypesUrl);
        return response.data;
    }
)

export const GetCustomerInformation = createAsyncThunk<ServerResponse<InvoiceModel>>(
    FormActionNames.GetCustomerInformationAction,
    async () => {
        const response: AxiosResponse<ServerResponse<InvoiceModel>> = await api.get(FormRoutes.GetCustomerInformationUrl);
        return response.data;
    }
)

export const UpdateCustomerInformation = createAsyncThunk<ServerResponse<InvoiceModel>, { model: InvoiceModel }>(
    FormActionNames.UpdateCustomerInformationAction,
    async (data) => {
        const response: AxiosResponse<ServerResponse<InvoiceModel>>
            = await api.post(FormRoutes.UpdateCustomerInformationUrl, data.model);
        return response.data;
    }
)