import {UserModel} from "../Models/Api/Users/UserModel";

export const usersColumns = [
    "",
    "Name",
    "Role",
    "Email",
    "Phone",
    "Status",
    "Registered at"
]

export const usersList: UserModel[] = [
    {
        state: true,
        email: "talel@gmail.com",
        phone: "52741852",
        roles: ["manager", "accoutant"],
        fullname: "talel lamouchi",
        id: "1",
    },
    {
        state: false,
        email: "yassine@gmail.com",
        phone: "52741852",
        roles: ["manager"],
        fullname: "yassine ben taher",
        id: "2",
    }
];