import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    CaptchaVerify,
    LoginAsync,
    RegisterAsync,
    RequestPasswordResetAsync,
    ResetPasswordAsync,
    ResetPasswordVerifyAsync
} from "../../Api/Login/LoginApi";
import {RootState} from "../store";
import {ServerResponse} from "../../Models/Api/ServerResponse";
import {LoginResponse} from "../../Models/Api/Login/LoginResponse";
import {SecureStore} from "../../Security/Crypto/Storage/SecureStore";
import {StoreKeys} from "../../Security/Crypto/Storage/StoreKeys";
import {toast} from "react-toastify";
import {EcdhAesEncryption} from "../../Security/Crypto/Ecc/EcdhAesEncryption";
import {CaptchaResponseModel} from "../../Models/Api/Login/CaptchaResponseModel";

const errors = require("../../assets/I18n/serverErrors.json");
const initialState = {
    actionLoading: false,
    requestResetLoading: false,
    resetLoading: false,
    captchaSuccess: false,
    loggedIn: SecureStore.isExists(StoreKeys.TOKEN),
    email: "",
}

export const LoginSLice = createSlice({
    initialState,
    name: "Login",
    reducers: {
        captchaResetAction: (state) => {
            state.captchaSuccess = false;
        },
        logOutAction: (state) => {
            SecureStore.deleteItem(StoreKeys.TOKEN);
            SecureStore.deleteItem(StoreKeys.REFRESH_TOKEN);
            SecureStore.deleteItem(StoreKeys.ACCOUNT_STATUS);
            SecureStore.deleteItem(StoreKeys.SESSION_ID);
            state.loggedIn = false;
        },
        setResetPasswordEmailAction: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        }


    },
    extraReducers: (builder) => {
        /** Login */
        builder.addCase(LoginAsync.pending, (state) => {
            state.actionLoading = true
        });
        builder.addCase(LoginAsync.fulfilled, (state, payload: PayloadAction<ServerResponse<LoginResponse>>) => {
            SecureStore.setItem(StoreKeys.TOKEN, payload.payload.data?.accessToken, true)
            SecureStore.setItem(StoreKeys.REFRESH_TOKEN, payload.payload.data?.refreshToken, true)
            SecureStore.setItem(StoreKeys.SESSION_ID, payload.payload.data?.sessionId, false);
            SecureStore.setItem(StoreKeys.TOKEN_EXPIRED, "false");
            if (payload.payload.data && payload.payload.data?.status) SecureStore.setItem(StoreKeys.ACCOUNT_STATUS, payload.payload.data?.status, false);
            state.actionLoading = false;
            state.loggedIn = true;
        });
        builder.addCase(LoginAsync.rejected, (state) => {
            state.actionLoading = false;
            state.loggedIn = false;
            toast.error(errors.serverErrors["INVALID_CREDENTIALS"], {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
        });


        /** Request password reset*/
        builder.addCase(RequestPasswordResetAsync.pending, (state) => {
            state.requestResetLoading = true;
        });
        builder.addCase(RequestPasswordResetAsync.fulfilled, (state) => {
            state.requestResetLoading = false;
        });
        builder.addCase(RequestPasswordResetAsync.rejected, (state) => {
            state.requestResetLoading = false;
        });

        /** Password reset verify*/
        builder.addCase(ResetPasswordVerifyAsync.pending, (state) => {
            state.resetLoading = true;
        });
        builder.addCase(ResetPasswordVerifyAsync.fulfilled, (state, action) => {
            const nonce = Buffer.from(action.payload.headers['nonce'], 'base64');
            const keys = EcdhAesEncryption.fetchKeys();
            const resetToken = Buffer.from(
                EcdhAesEncryption.decrypt(
                    keys.privateKey,
                    process.env.REACT_APP_SERVER_KEY ?? "",
                    Buffer.from(action.payload.data?.data?.token, 'base64'),
                    nonce,
                ),
            ).toString('utf-8');
            SecureStore.setItem(StoreKeys.RESET_TOKEN, resetToken, true);
            state.resetLoading = false;
        });
        builder.addCase(ResetPasswordVerifyAsync.rejected, (state) => {
            state.resetLoading = false;
        });

        /** Password reset*/
        builder.addCase(ResetPasswordAsync.pending, (state) => {
            state.resetLoading = true;
        });
        builder.addCase(ResetPasswordAsync.fulfilled, (state) => {
            state.resetLoading = false;
        });
        builder.addCase(ResetPasswordAsync.rejected, (state) => {
            state.resetLoading = false;
        });


        /** Register*/
        builder.addCase(RegisterAsync.pending, (state) => {
            state.actionLoading = true;
        });
        builder.addCase(RegisterAsync.fulfilled, (state
            , payload: PayloadAction<ServerResponse<LoginResponse>>) => {
            SecureStore.setItem(StoreKeys.TOKEN, payload.payload.data?.accessToken, true)
            SecureStore.setItem(StoreKeys.REFRESH_TOKEN, payload.payload.data?.refreshToken, true)
            SecureStore.setItem(StoreKeys.SESSION_ID, payload.payload.data?.sessionId, false);
            SecureStore.setItem(StoreKeys.TOKEN_EXPIRED, "false");
            if (payload.payload.data && payload.payload.data?.status) SecureStore.setItem(StoreKeys.ACCOUNT_STATUS, payload.payload.data?.status, false);
            state.actionLoading = false;
            state.loggedIn = true;
            toast.success('Sign up was successful ', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
        });
        builder.addCase(RegisterAsync.rejected, (state) => {
            state.actionLoading = false;
        });

        /** Captcha verify */
        builder.addCase(CaptchaVerify.pending, (state) => {
            state.captchaSuccess = false;
        });
        builder.addCase(CaptchaVerify.fulfilled, (state, action: PayloadAction<ServerResponse<CaptchaResponseModel>>) => {
            state.captchaSuccess = action.payload.data.success;
            if(!state.captchaSuccess) {
                toast.error(errors.serverErrors["INVALID_CAPTCHA"], {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            }
        });
        builder.addCase(CaptchaVerify.rejected, (state) => {
            state.captchaSuccess = false;
            toast.error(errors.serverErrors["INVALID_CAPTCHA"], {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
        });
    }
})

/**
 * Actions
 */
export const {
    logOutAction,
    setResetPasswordEmailAction,
    captchaResetAction
} = LoginSLice.actions;

/**
 * Selectors
 */
export const loginActionLoading = (state: RootState) => state.login.actionLoading;
export const loginLoggedIn = (state: RootState) => state.login.loggedIn;
export const loginRequestResetLoading = (state: RootState) => state.login.requestResetLoading;
export const loginResetLoading = (state: RootState) => state.login.resetLoading;
export const loginResetEmail = (state: RootState) => state.login.email;
export const loginCaptchaSuccess = (state: RootState) => state.login.captchaSuccess;