import {FunctionComponent} from "react";
import { Button, Form} from "react-bootstrap";
import {useFormik} from "formik";
import {InvoiceCallBackModel} from "../../../Models/Api/Settings/InvoiceCallBackModel";
import {CallBackFormProp} from "../../../Models/Props/Settings/CallBackFormProp";
import * as Yup from "yup";
import {useAppDispatch} from "../../../Store/hooks";
import {UpdateCallBacksAsync} from "../../../Api/Settings/SettingsApi";
import SButton from "../../UI/Button/Button";

export const CallBacksForm: FunctionComponent<CallBackFormProp> = (props) => {
    const dispatch = useAppDispatch();
    /**
     * Submit form
     * @param values
     * @constructor
     */
    const Submit = (values: InvoiceCallBackModel) => {
        props.close();
        dispatch(UpdateCallBacksAsync({model: values})).then(e => {
            if (e.meta.requestStatus === "fulfilled") {
                props.getData(values);
            }
        })
    }


    /**
     * Form builder
     */
    const formBuilder = useFormik<InvoiceCallBackModel>({
        initialValues:  {
            callbackUrl: props.data?.callbackUrl??"",
        },
        validationSchema: Yup.object().shape({
            callbackUrl: Yup.string().url("Not a valid url").matches(/^((https):\/\/)/,'Url must start with https://').required("Callback URL is mandatory"),
        }),
        onSubmit: Submit
    })

    /**
     * Template
     */
    return (
        <Form onSubmit={formBuilder.handleSubmit}>
            <Form.Group>
                <Form.Label className="required-dot">Callback URL</Form.Label>
                <Form.Control     autoComplete="off"   
                    onChange={formBuilder.handleChange}
                    value={formBuilder.values.callbackUrl}
                    onBlur={formBuilder.handleBlur}
                    type="text" name="callbackUrl"/>
                {formBuilder.errors.callbackUrl && formBuilder.errors.callbackUrl &&
                    <p className="errors pt-2">{formBuilder.errors.callbackUrl}</p>}
            </Form.Group>
            
            <div className="dialog-footer pt-3">
                
                <Button ref={props.submitRef} type="submit"  style={{display:'none'}}/>
            </div>
        </Form>
    )
}