import {createAsyncThunk} from "@reduxjs/toolkit";
import {ServerResponse} from "../../Models/Api/ServerResponse";
import {PaymentModel} from "../../Models/Api/Payments/PaymentModel";
import {PaymentFilterModel} from "../../Models/Api/Payments/PaymentFilterModel";
import {PaymentActionNames} from "../../Models/Enums/Payments/PaymentActionNames";
import {api} from "../api";
import {PaymentsRoutes} from "../ApiConstants";
import {PaymentCreateModel} from "../../Models/Api/Payments/PaymentCreateModel";
import {PaymentListModel} from "../../Models/Api/Payments/PaymentListModel";


/**
 * List payments
 */
export const ListPaymentAsync = createAsyncThunk<ServerResponse<PaymentListModel>, { filter: PaymentFilterModel }>(
    PaymentActionNames.ListPaymentAction,
    async (data, thunkAPI) => {
        const response = await api.post(PaymentsRoutes.listPaymentsUrl, data.filter, {
            signal: thunkAPI.signal,
        });
        return response.data;
    }
)

/**
 * Get payment detail
 */
export const PaymentDetailAsync = createAsyncThunk<ServerResponse<PaymentModel>, { id: string }>(
    PaymentActionNames.PaymentDetailAction,
    async (data) => {
        const response = await api.get(`${PaymentsRoutes.paymentDetailUrl}?id=${data.id}`);
        return response.data;
    }
)

/**
 * Create payment
 */
export const CreatePaymentAsync = createAsyncThunk<ServerResponse<string>, { model: PaymentCreateModel }>(
    PaymentActionNames.CreatePaymentAction,
    async (data) => {
        const response = await api.post(PaymentsRoutes.createPaymentUrl, data.model);
        return response.data;
    }
)

/**
 * payment csv export
 */
export const CsvPaymentExportAsync = createAsyncThunk<File, { model: PaymentFilterModel }>(
    PaymentActionNames.ExportPaymentCsvAction,
    async (data) => {
        const response = await api.post(PaymentsRoutes.getCsvExportUrl, data.model, {
            responseType: "blob"
        });
        return response.data;
    }
)

/**
 * payment excel export
 */
export const ExcelPaymentExportAsync = createAsyncThunk<File, { model: PaymentFilterModel }>(
    PaymentActionNames.ExportPaymentExelAction,
    async (data) => {
        const response = await api.post(PaymentsRoutes.getExcelExport, data.model, {
            responseType: "blob"
        });
        return response.data;
    }
)

/**
 * payment pdf export
 */
export const PdfPaymentExportAsync = createAsyncThunk<File, { model: PaymentFilterModel }>(
    PaymentActionNames.ExportPaymentPdfAction,
    async (data) => {
        const response = await api.post(PaymentsRoutes.getPdfExportUrl, data.model, {
            responseType: "blob"
        });
        return response.data;
    }
)



