import {TotalSalesItemModel} from "../Models/Api/Dashboard/TotalSalesItemModel";
import {ChartData} from "../Models/Props/ChartData";
import {DailyPaymentsCountModel} from "../Models/Api/Dashboard/DailyPaymentsCountModel";
import Moment from "moment";


export const MapTotalSalesToChartData = (data: TotalSalesItemModel[]) => {
    let chartData: ChartData = {
        data: [],
        labels: [],
    };
    if (data) {
        data.forEach(x => {
            chartData.labels.push(Moment(x.date).format("MMM D, YYYY"));
            chartData.data.push(x.amount);
        });
    }
    return chartData;
}

export const MapTotalPaymentToChartData = (data: DailyPaymentsCountModel[]) => {
    let chartData: ChartData = {
        data: [],
        labels: [],
    };
    if (data) {
        data.forEach(x => {
            chartData.labels.push(Moment(x.date).format("MMM D, YYYY"));
            chartData.data.push(x.count);
        });
    }
    return chartData;
}