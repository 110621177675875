import {FunctionComponent, useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {DateObject} from "../../Models/Props/Datepicker/DateObject";
import Moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRedo} from "@fortawesome/free-solid-svg-icons";
import "./DatepickerByMonth.scss";

export interface MonthOption {
    month: string;
    disabled: boolean;
    value: number;
}

export interface DatepickerByMonthProps {
    getDate: (date: DateObject) => void;

}

export const monthsOptions: MonthOption[] = [
    {
        month: "January",
        disabled: false,
        value: 1,
    },
    {
        month: "February",
        disabled: false,
        value: 2,
    },
    {
        month: "March",
        disabled: false,
        value: 3,
    },
    {
        month: "April",
        disabled: false,
        value: 4,
    },
    {
        month: "May",
        disabled: false,
        value: 5,
    },
    {
        month: "June",
        disabled: false,
        value: 6,
    },
    {
        month: "July",
        disabled: false,
        value: 7,
    },
    {
        month: "August",
        disabled: false,
        value: 8,
    },
    {
        month: "September",
        disabled: false,
        value: 9,
    },
    {
        month: "October",
        disabled: false,
        value: 10,
    },
    {
        month: "November",
        disabled: false,
        value: 11,
    },
    {
        month: "December",
        disabled: false,
        value: 12,
    },
]


export const DatepickerByMonth: FunctionComponent<DatepickerByMonthProps> = (props) => {


    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const [date, setDate] = useState<DateObject>();
    const [firstMonths, setFirstMonths] = useState<MonthOption[]>(monthsOptions);
    const [secondMonths, setSecondMonths] = useState<MonthOption[]>(monthsOptions);
    const [firstDate, setFirstDate] = useState<string>();
    const [secondDate, setSecondDate] = useState<string>();


    const handelFirstMonthChange = (value: string) => {
        let monthDate = Moment([Moment().year(), parseInt(value) - 1]).format("MMMM D, YYYY");
        setFirstDate(monthDate);
        monthsOptions.forEach(month => {
            if (month.value <= parseInt(value))
                month.disabled = true;
        })
        setSecondMonths(monthsOptions);
    }


    const handelSecondMonthChange = (value: string) => {
        console.log(value);
        let monthDate = Moment([Moment().year(), parseInt(value) - 1]).format("MMMM D, YYYY");
        setSecondDate(monthDate);

    }

    const firstMonth = (months: MonthOption[]) => {
        return (<Form.Select onChange={(e) => handelFirstMonthChange(e.target.value)}>
            {months.map(m => (
                <option disabled={m.disabled} key={m.month} value={m.value}>{m.month}</option>
            ))}
        </Form.Select>)
    }

    const SecondMonth = (months: MonthOption[]) => {
        return (<Form.Select onChange={(e) => handelSecondMonthChange(e.target.value)}>
            {months.map(m => (
                <option disabled={m.disabled} key={m.month} value={m.value}>{m.month}</option>
            ))}
        </Form.Select>)
    }

    useEffect(() => {
        setDate({
            startDate: firstDate ?? "",
            endDate: secondDate ?? "",
        });
    }, [secondDate])

    useEffect(() => {
        if (date) props.getDate(date)
    }, [date])


    return (<div className="month-date-picker">
        <div>
            <FontAwesomeIcon icon={faRedo}/>
        </div>
        {firstMonth(firstMonths)}
        {SecondMonth(secondMonths)}
    </div>)
}