import { FunctionComponent, useEffect, useRef, useState } from "react";
import "../settings.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faInfoCircle, faPen } from "@fortawesome/free-solid-svg-icons";
import "./Callbacks.scss"
import { CallBacksForm } from "./CallBacksForm";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { GetCallBacksAsync } from "../../../Api/Settings/SettingsApi";
import { settingActionLoading, settingBusinessCallbacks } from "../../../Store/Settings/SettingsSlice";
import SButton from "../../UI/Button/Button";
import { BuildEnviroment } from "../../../BuildEnviroment";
import { AppEnviroment, onReadMore } from "../../../Models/Enums/AppEnviroment";
import { Dictionary } from "../../../Core/Types/Dictionary";

export const CallBacks: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    /**
     * State
     */
    const loading = useAppSelector(settingActionLoading);
    const [openForm, setOpenForm] = useState<boolean>(false);
    const callbackUrl = useAppSelector(settingBusinessCallbacks);
    const submitRef = useRef<HTMLButtonElement>();

    useEffect(() => {
        dispatch(GetCallBacksAsync())
    }, [dispatch])

    /**
     * Handle submit
     * @constructor
     */
    const SubmitHandler = () => {
        setOpenForm(false);
        dispatch(GetCallBacksAsync());
    }


    const edit = () => {
        setOpenForm(true);
    }

    const CloseForm = () => {
        setOpenForm(false);
    }
    
    /**
     * Template
     */
    const onSubmit = () => {
        submitRef.current?.click()
    }
    return (<div className="settings-container">
        <div className="settings-header">
            <div className="d-flex gap-2 header_title">

                <h4><FontAwesomeIcon icon={faCopy} className="icon" />Default Callback</h4>
                {callbackUrl?.callbackUrl &&
                    <span className="edit-callBack" onClick={edit} ><FontAwesomeIcon icon={faPen} /></span>
                }

            </div>
            {
                openForm &&
                <div className="callback-actions d-flex gap-2">
                    <SButton label="cancel" class="warning" onClick={CloseForm} />
                    <SButton label="Submit" class="primary" onClick={onSubmit} type='submit' />
                </div>
            }
        </div>
        <div className="w-100 ">
            {loading && <div className="progress">
                <div className="progress-bar  indeterminate">
                </div>
            </div>}
        </div>
        {!openForm && <div className="callback-body">
            <p className="callback-note">Due to security reasons, the callback can only be sent over the port 443/HTTPS !
                Also, the callback does not follow redirects.
                <div>
                    <a href={onReadMore()} target='_blank'>Read more about callback URL</a>
                </div>
            </p>
            <hr />
            <div className="content-callback">
                {!callbackUrl?.callbackUrl && <span onClick={edit} >Add callback URL</span>}
                {callbackUrl?.callbackUrl && <div>{callbackUrl.callbackUrl}</div>}
            </div>
        </div>}
        {
            openForm && <CallBacksForm submitRef={submitRef} data={callbackUrl!} getData={SubmitHandler} close={() => CloseForm()} />
        }
    </div>

    )
}