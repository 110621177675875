import { AppEnviroment } from "./Models/Enums/AppEnviroment";

export class BuildEnviroment {
    /**
     * Get build environment
     * @returns AppEnviroment
     */
    static GetEnv(): AppEnviroment {
        switch (process.env.REACT_APP_ENV) {
            case "DEV":
                return AppEnviroment.DEV;
            case "STAGING":
                return AppEnviroment.STAGING;
            case "PROD":
                return AppEnviroment.PROD;
            default:
                return AppEnviroment.LOCAL;
        }
    }
}