import * as Yup from "yup"
import {BusinessRegisterModel} from "../../Models/Api/Login/BusinessRegisterModel";
import {UserLanguage} from "../../Models/Api/Login/UserLanguage";
import {ValidationMessages} from "../../Models/Enums/ValiationMessages";

export const registerValidator = () => {
    const urlRegex = /^((http|https):\/\/)?([a-zA-Z0-9-]+)\.([A-z]{2,})?([^\s<>#%",{}\\|^\[\]`]+)?/;
    return Yup.object().shape({
        businessAccount: Yup.object().shape({ 
            registrationNumber: Yup.string() 
                .matches(/^[0-9_-]*$/, "Registration number is invalid")
                .required("Registration number is mandatory"),
            businessName: Yup.string()
                .min(2, "Business name must be minimum of 2 characters")
                .max(30, "Business name must be maximum of 30 characters")
                .required("Business name is mandatory"),
            sector: Yup.string().required("Sector is mandatory"),
            businessWebSite: Yup.string()
                .matches(urlRegex, "Please enter a valid url"),
            businessPhoneNumber: Yup.string(),
            contactEmail: Yup.string().email("Please enter a valid email").required("Contact email is mandatory"),
            commercialRegistration: Yup.mixed(),
        }),
        manager: Yup.object().shape({
            accountManagerName: Yup.string()
                .min(2, "Account manager must be minimum of 2 characters")
                .max(30, "Account manager must be maximum of 30 characters")
                .required("Account manager name is mandatory"),
            countryCode: Yup.string(),
            phone: Yup.string(),
            email: Yup.string().email("Please enter a valid email").required("Email is mandatory"),
            password: Yup.string()
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_'\"+={};:<>,.?/-])(?=.{8,})/,
                    ValidationMessages.passwordMessage)
                .required("Password is mandatory"),
            nationalId: Yup.mixed(),
            language: Yup.string(),
        })
    })
};

export const initialValuesRegisterModel: BusinessRegisterModel = {
    businessAccount: {
        registrationNumber: "",
        businessName: "",
        sector: undefined,
        businessWebSite: "",
        businessPhoneNumber: "",
        contactEmail: "",
        commercialRegistration: undefined,
    },
    manager: {
        accountManagerName: "",
        countryCode: "",
        language: UserLanguage.EN,
        email: "",
        phone: "",
        password: "",
        nationalId: undefined
    }
}

export const RegisterCategories = [
    "Bills",
    "Eating Out",
    "Entertainment",
    "Finances",
    "General",
    "Groceries",
    "Healthcare",
    "Personal Care",
    "Shopping",
    "Travel",
];

export const renderBusinessRegisterModel = (model: BusinessRegisterModel) => {
    const newModel: BusinessRegisterModel = {
        businessAccount: {
            registrationNumber: model.businessAccount.registrationNumber,
            sector: model.businessAccount.sector,
            businessName: model.businessAccount.businessName,
            commercialRegistration: model.businessAccount.commercialRegistration,
            businessPhoneNumber: model.businessAccount.businessPhoneNumber,
            contactEmail: model.businessAccount.contactEmail,
            businessWebSite: model.businessAccount.businessWebSite,
        },
        manager: {
            accountManagerName: model.manager.accountManagerName,
            password: model.manager.password,
            language: model.manager.language,
            phone: model.manager.phone,
            email: model.manager.email,
            nationalId: model.manager.nationalId,
            countryCode: model.manager.countryCode
        }

    };
    return newModel;
}

export const RegisterToFormData = (model: BusinessRegisterModel, httpHelper: string) => {
    const fd = new FormData();
    fd.append("businessAccount.registrationNumber", model.businessAccount.registrationNumber);
    fd.append("businessAccount.businessName", model.businessAccount.businessName);
    fd.append("businessAccount.contactEmail", model.businessAccount.contactEmail);
    if (model.businessAccount.sector) {
        fd.append("businessAccount.sector", model.businessAccount.sector);
    }
    fd.append("businessAccount.businessPhoneNumber", model.businessAccount.businessPhoneNumber);
    if (model.businessAccount.businessWebSite) {
        fd.append("businessAccount.businessWebSite",
            !model.businessAccount.businessWebSite.includes("http") ? `${httpHelper}${model.businessAccount.businessWebSite}`
        : model.businessAccount.businessWebSite);
    }
    if (model.businessAccount.commercialRegistration) {
        const commercialRegistrationFile = new Blob([model.businessAccount.commercialRegistration],
            {type: model.businessAccount.commercialRegistration?.type})
        fd.append("businessAccount.commercialRegistration",
            commercialRegistrationFile, model.businessAccount.commercialRegistration?.name);
    }

    fd.append("manager.accountManagerName", model.manager.accountManagerName);
    if (model.manager.countryCode) fd.append("manager.countryCode", model.manager.countryCode);
    fd.append("manager.email", model.manager.email);
    fd.append("manager.phone", model.manager.phone);
    fd.append("manager.password", model.manager.password);
    fd.append("manager.language", model.manager.language);
    if (model.manager.nationalId) {
        const nationalIdFile = new Blob([model.manager.nationalId], {type: model.manager.nationalId.type})
        fd.append("manager.nationalId", nationalIdFile, model.manager.nationalId.name);
    }
    return fd;
};