import {FunctionComponent, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {ProfileModel} from "../../../Models/Api/Settings/ProfileModel";
import {ProfileFormProp} from "../../../Models/Props/Settings/ProfileFormProp";
import {useFormik} from "formik";
import * as Yup from "yup";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2'
import {useAppDispatch} from "../../../Store/hooks";
import {UpdateProfileAsync} from "../../../Api/Settings/SettingsApi";

export const ProfileForm: FunctionComponent<ProfileFormProp> = (props) => {
    const dispatch = useAppDispatch();
    /**
     * State
     */
   
    const [phone,setPhone] = useState(props.data?.countryCode?.split('+')[1]+props.data?.phone!);
    const [countryCode,setCountryCode] = useState(props.data?.countryCode?.split('+')[1]);
    const [isTouchedPhone,setIsTouchedPhone] = useState(false);
    const [isErrorPhone,setIsErrorPhone] = useState(false);
    const [errorPhoneMessage,setErrorPhoneMessage] = useState('');
    const changePhoneHandler = (value:string,object:any)=>{
        setCountryCode(object.dialCode);
        setPhone(value);
        phoneValidator();      
    }
    const onPhoneBlurr = ()=>{
        phoneValidator();
    }   
    const phoneValidator = ()=>{
        setIsTouchedPhone(true);
        const phoneOnly = phone?.split(countryCode!)[1]??"";
        if(phoneOnly===""){
            setIsErrorPhone(true);
            setErrorPhoneMessage("Phone number is mandatory");
        }
        else{
            setIsErrorPhone(false);
            setErrorPhoneMessage("");
        }
    }
    /**
     * Form submit
     * @param values
     * @constructor
     */
    const Submit = (values: ProfileModel) => {
        
        /**555 555 555 */
        if(isErrorPhone){
            return;
        }
        values = {
            ...values,
            phone:phone?.split(countryCode!)[1],
            countryCode:"+"+countryCode,
        }
        dispatch(UpdateProfileAsync({model: values})).then(e => {
            if (e.meta.requestStatus === "fulfilled") {
                props.getData(values);
            }
        })

        
    }

    /**
     * form builder
     */
    const formBuilder = useFormik({
        initialValues: {
            accountManagerName: props.data?.accountManagerName??'',
            email:props.data?.email??''
        },
        validationSchema: Yup.object().shape({
            accountManagerName: Yup.string().required("Manager name is mandatory").max(30,'Manager name is too long.'),
            email : Yup.string().required('Email is mandatory').email('Email address is not valid')
        }),
        onSubmit: Submit
    })

    /**
     * Template
     */
    return (
        <Form onSubmit={formBuilder.handleSubmit} className="gap-4">
            <Form.Group>
                <Form.Label className="required-dot">Manager name</Form.Label>
                <Form.Control     autoComplete="off"   
                    name="accountManagerName"
                    value={formBuilder.values.accountManagerName}
                    onBlur={formBuilder.handleBlur}
                    onChange={formBuilder.handleChange}
                    type="text"/>
                {formBuilder.errors.accountManagerName && formBuilder.touched.accountManagerName &&
                    <p className="errors">{formBuilder.errors.accountManagerName}</p>}
            </Form.Group>
           <Form.Group>
            <Form.Label className="required-dot">Email address</Form.Label>
            <Form.Control
                name="email"
                value={formBuilder.values.email}
                onBlur={formBuilder.handleBlur}
                onChange={formBuilder.handleChange}
            />
            {formBuilder.errors.email && formBuilder.touched.email &&
                    <p className="errors">{formBuilder.errors.email}</p>}
           </Form.Group>
            <Form.Group>
                <Form.Label className="required-dot">Phone number</Form.Label>
                <PhoneInput
                    inputProps={{
                        name: 'phone',
                    }}
                    inputClass="form-control"
                    country={'kw'}
                    value={phone}
                    countryCodeEditable={false}
                    onChange={changePhoneHandler}
                    onBlur={onPhoneBlurr}
                />
                {isErrorPhone && isTouchedPhone && <p className="errors">{errorPhoneMessage}</p>}
                <div className="dialog-footer mt-5">
            </div>
            </Form.Group>
            <button type="submit" ref={props.submitRef} style={{display:'none'}}
                        className="spare_btn spare_btn_action">Submit</button>
        </Form>
    )
}