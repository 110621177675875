import React, {FunctionComponent} from "react";
import "./SettingsPage.scss";
import {SettingIndex} from "../../Components/Settings/SettingsIndex/SettingIndex";

export const SettingsPage: FunctionComponent = () => {
    return (
        <div className="settings">
            <SettingIndex/>
        </div>
    );
}