import {FunctionComponent} from "react";
import {Form} from "react-bootstrap";
import {FdRuleApplication} from "../../../Models/Enums/FraudDetection/FdRuleApplication";
import {FdRuleConditionType} from "../../../Models/Enums/FraudDetection/FdRuleConditionType";
import {RuleConditionOption} from "./RuleConditionOption";
import {RuleConditionProp} from "../../../Models/Props/Settings/Rules/RuleConditionProp";

export const RuleCondition: FunctionComponent<RuleConditionProp> = (props) => {
    return (
        <div className="rule-box_options">
            <Form.Select
                value={props.form.values.condition.appliedTo}
                onChange={props.form.handleChange}
                className="custom-select" name="condition.appliedTo" as="select">
                <option selected value={FdRuleApplication.AMOUNT}>Transaction amount</option>
                <option value={FdRuleApplication.VOLUME_OF_TRANSACTION_PER_DEBTOR}>Volume of transactions per debtor
                </option>
                <option value={FdRuleApplication.VOLUME_OF_TRANSACTION_PER_CREDITOR}>Volume of transactions per
                    creditor
                </option>
                <option value={FdRuleApplication.VOLUME_OF_INITIATED_TRANSACTION_PER_DEBTOR}>Volume of initiated
                    transactions per debtor
                </option>
                <option value={FdRuleApplication.VOLUME_OF_TRANSACTION_PER_DEBTOR_IP_ADDRESS}>Volume of transactions
                    per debtor ip address
                </option>
                <option value={FdRuleApplication.VOLUME_OF_INITIATED_TRANSACTION_PER_CREDITOR}>Volume of initiated
                    transaction per creditor
                </option>
                <option value={FdRuleApplication.VOLUME_OF_TRANSACTION_PER_DEBTOR_IDENTIFICATION}>Volume of
                    transactions per debtor identification
                </option>
                <option value={FdRuleApplication.VOLUME_OF_TRANSACTION_PER_CREDITOR_IDENTIFICATION}>Volume of
                    transactions per creditor identification
                </option>
                <option value={FdRuleApplication.VOLUME_OF_INITIATED_TRANSACTION_PER_DEBTOR_IP_ADDRESS}>Volume eof
                    initiated transaction per debtor ip address
                </option>
                <option value={FdRuleApplication.VOLUME_OF_INITIATED_TRANSACTION_PER_DEBTOR_IDENTIFICATION}>Volume of
                    initiated transaction per debtor identification
                </option>
                <option value={FdRuleApplication.VOLUME_OF_INITIATED_TRANSACTION_PER_CREDITOR_IDENTIFICATION}>Volumne
                    of initiated transaction per creditor identification
                </option>
            </Form.Select>
            <Form.Select
                value={props.form.values.condition.type}
                onChange={props.form.handleChange}
                name="condition.type" className="custom-select" as="select">
                <option selected value={FdRuleConditionType.LESS_THAN}>Is less than</option>
                <option value={FdRuleConditionType.GREATER_THAN}>Is greater than</option>
                <option value={FdRuleConditionType.LESS_THAN_OR_EQUAL}>Is less than or equal</option>
                <option value={FdRuleConditionType.GREATER_THAN_OR_EQUAL}>Is greater than or equal</option>
            </Form.Select>
            <Form.Control     autoComplete="off"   
                value={props.form.values.condition.value}
                onChange={props.form.handleChange}
                name="condition.value" type="number"/>
            <RuleConditionOption form={props.form} name="condition.option"/>
        </div>
    )
}