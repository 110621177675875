import {AverageOrderModel} from "../../Models/Api/Statistics/AverageOrderModel";
import {ChartData} from "../../Models/Props/ChartData";
import {PaymentStatusModel} from "../../Models/Api/Statistics/PaymentStatusModel";
import {PaymentsStatus} from "../../Models/Enums/Payments/PaymentsStatus";
import {PaymentsColorStatus} from "../../Models/Enums/Payments/PaymentsColorStatus";
import Moment from "moment";
import {PaymentByLocationModel} from "../../Models/Api/Statistics/PaymentByLocationModel";
import {PaymentByGrowthModel} from "../../Models/Api/Statistics/PyamentByGrowthModel";


export const mapAverageOrdersToCharData = (data: AverageOrderModel[]) => {
    let chartData: ChartData = {
        data: [],
        labels: [],
    };
    if (data) {
        data.forEach(item => {
            chartData.labels.push(Moment(item.date).format("MMM D, YYYY"));
            chartData.data.push(item.value);
        })
    }
    return chartData;
}

export const mapPaymentByStatusToChartDate = (data: PaymentStatusModel[]) => {
    let chartData: ChartData = {
        data: [],
        labels: [PaymentsStatus.PENDING, PaymentsStatus.CANCELLED, PaymentsStatus.PAID],
        colors: [PaymentsColorStatus.Pending, PaymentsColorStatus.Canceled, PaymentsColorStatus.Paid],
    };

    if (data) {
        chartData.data.push({
            value: ((data.find(x => x.status === PaymentsStatus.AWAITING_AUTHORIZATION)?.percentage ?? 0) * 100).toFixed(2),
            name: PaymentsStatus.AWAITING_AUTHORIZATION
        })

        chartData.data.push({
            value: ((data.find(x => x.status === PaymentsStatus.PENDING)?.percentage ?? 0) * 100).toFixed(2),
            name: PaymentsStatus.CANCELLED
        })

        chartData.data.push({
            value: ((data.find(x => x.status === PaymentsStatus.COMPLETED)?.percentage ?? 0) * 100).toFixed(2),
            name: PaymentsStatus.PAID
        })
    }
    return chartData;
}

export const mapPaymentByLocationToCharData = (data: PaymentByLocationModel[]) => {
    let chartData: ChartData = {
        data: [],
        labels: [],
    };
    if (data) {
        data.forEach(item => {
            chartData.labels.push(item.location);
            chartData.data.push(item.count);
        })
    }
    return chartData;
}

export const mapMonthlyGrowthToChartData = (data: PaymentByGrowthModel[]) => {
    let chartData: ChartData = {
        data: [],
        labels: [],
    };
    if (data) {
        data.forEach(item => {
            chartData.labels.push(Moment(item.date).format("MMM D, YYYY"));
            chartData.data.push(item.rate);
        })
    }
    return chartData;

}



