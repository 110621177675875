import {FunctionComponent, useEffect, useState} from "react";
import "./StatisticsPage.scss"
import {Datepicker} from "../../Components/Datepicker/Datepicker";
import {DateObject} from "../../Models/Props/Datepicker/DateObject";
import {LineChartType} from "../../Models/Props/LineChartType";
import {LineChart} from "../../Components/Charts/LineChart/LineChart";
import {BarChart} from "../../Components/Charts/BarChart/BarChart";
import {PieChart} from "../../Components/Charts/PieChart/PieChart";
import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import {
    getAverageOrders,
    getPaymentAnnualGrowth,
    getPaymentByGrowth,
    getPaymentByStatus
} from "../../Api/Statisstics/StatisticsApi";
import {
    statisticsAverageOrders,
    statisticsAverageOrdersLoading, statisticsPaymentAnnualGrowthChartData, statisticsPaymentByGrowthChartData,
    statisticsPaymentByStatusChartData,
    statisticsPaymentsByLocationChartData
} from "../../Store/Statistics/StatisticsSlice";
import {DatepickerByMonth} from "../../Components/Statistics/DatepickerByMonth";

export const StatisticsPage: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const [filter, setFilter] = useState<DateObject>();
    const averageOrders = useAppSelector(statisticsAverageOrders);
    const averageOrdersLoading = useAppSelector(statisticsAverageOrdersLoading);
    const paymentsByStatusChartData = useAppSelector(statisticsPaymentByStatusChartData);
    const paymentsByLocationChartData = useAppSelector(statisticsPaymentsByLocationChartData);
    const paymentByGrowthChartData = useAppSelector(statisticsPaymentByGrowthChartData);
    const paymentAnnualGrowthChartData = useAppSelector(statisticsPaymentAnnualGrowthChartData);

    const getRangeDate = (date: DateObject) => {
        setFilter(date);
    }

    useEffect(() => {
        if (filter) {
            dispatch(getAverageOrders({startDate: filter.startDate, endDate: filter?.endDate}));
            dispatch(getPaymentByStatus({startDate: filter.startDate, endDate: filter?.endDate}));
            dispatch(getPaymentByGrowth({startDate: filter.startDate, endDate: filter?.endDate}));
            dispatch(getPaymentAnnualGrowth({startDate: filter.startDate, endDate: filter?.endDate}));

        }
    }, [filter])

    const getGrowthDate = (date: DateObject) => {
        dispatch(getPaymentByGrowth({startDate: date.startDate, endDate: date?.endDate}));
    }
    
    return (
        <div className="statistics">
            <div className="statistics_filter">
                <Datepicker getDate={getRangeDate}/>
            </div>
            <div className="statistics_row">
                <LineChart title="Average over value" value={averageOrders.averageOrderValue}
                           description="average over time"
                           loading={averageOrdersLoading}
                           type={LineChartType.CURRENCY} data={averageOrders.charData}/>
                <BarChart title="Payments by location" data={paymentsByLocationChartData} withFilter={false}
                          orient={true}/>
                <PieChart title="Payments by status" data={paymentsByStatusChartData}/>
            </div>
            <div className="monthly-growth-container">
                <DatepickerByMonth getDate={getGrowthDate}/>
                <LineChart title="Monthly growth" data={paymentByGrowthChartData}/> 
            </div>
            <BarChart title="Annual growth" data={paymentAnnualGrowthChartData} withFilter={false} orient={false}/>
            <div className="pad"></div>
        </div>
    )
}