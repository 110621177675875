import {FunctionComponent} from "react";
import {Button, Form} from "react-bootstrap";
import {EmailFormProp} from "../../../Models/Props/Settings/EmailFormProp";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useAppDispatch, useAppSelector} from "../../../Store/hooks";
import {settingActionLoading} from "../../../Store/Settings/SettingsSlice";
import {UpdateEmailAsync} from "../../../Api/Settings/SettingsApi";
import {LoginModel} from "../../../Models/Api/Login/LoginModel";
import {EcdhAesEncryption} from "../../../Security/Crypto/Ecc/EcdhAesEncryption";
import {EncryptedHeader} from "../../../Api/Login/encryptedHeader";
import SButton from "../../UI/Button/Button";

export const EmailForm: FunctionComponent<EmailFormProp> = (props) => {
    const serverKey = process.env.REACT_APP_SERVER_KEY;
    const dispatch = useAppDispatch();
    const loading = useAppSelector(settingActionLoading);

    const Submit = (values: LoginModel) => {
        if (serverKey) {
            const keys = EcdhAesEncryption.fetchKeys();
            const cipher = EcdhAesEncryption.encrypt(
                keys.privateKey,
                serverKey,
                Buffer.from(values.password),
            )
            const model: LoginModel = {
                email: values.email,
                password: cipher.base64Cipher ?? ""
            }
            const headers: EncryptedHeader = {
                nonce: cipher.base64Nonce ?? "",
                publicKey: Buffer.from(keys.publicKey).toString('base64'),
            }
            dispatch(UpdateEmailAsync({model, headers})).then(e => {
                if (e.meta.requestStatus === "fulfilled") {
                    props.getData(values.email, values.password);
                }
            })
        }
    }
    const formBuilder = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email().required("Email is mandatory"),
            password: Yup.string().required("Password is mandatory"),
        }),
        onSubmit: Submit,
    })

    /**
     * Template
     */
    return (
        <div>
            <div className="settings-header">
                <h4>Email</h4>
            </div>
            <Form onSubmit={formBuilder.handleSubmit}>
                <Form.Group>
                    <Form.Label>Enter new email</Form.Label>
                    <Form.Control     autoComplete="off"   
                        name="email"
                        type="email"
                        onBlur={formBuilder.handleBlur}
                        value={formBuilder.values.email}
                        onChange={formBuilder.handleChange}
                    />
                    {formBuilder.errors.email && formBuilder.touched?.email &&
                        <p className="errors">{formBuilder.errors.email}</p>}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Enter your password</Form.Label>
                    <Form.Control     autoComplete="off"   
                        name="password"
                        type="password"
                        onBlur={formBuilder.handleBlur}
                        value={formBuilder.values.password}
                        onChange={formBuilder.handleChange}
                    />
                    {formBuilder.errors.password && formBuilder.touched?.password &&
                        <p className="errors">{formBuilder.errors.password}</p>}
                </Form.Group>
                <div className="dialog-footer">
                    
                    <SButton type="button" label="Cancel" class="warning" onClick={()=>props.close()}/>
                    <SButton type="submit" label="Save Changes"/>
                </div>
                <div className="w-100 ">
                    {loading && <div className="progress">
                        <div className="progress-bar  indeterminate">
                        </div>
                    </div>}

                </div>
            </Form>
        </div>
    )
}