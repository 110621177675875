import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {useFormik} from "formik";
import {BusinessRegisterModel} from "../../Models/Api/Login/BusinessRegisterModel";
import {
    initialValuesRegisterModel,
    RegisterCategories,
    RegisterToFormData,
    registerValidator, renderBusinessRegisterModel
} from "./RegisterValidator";
import {Button, Form, Tooltip} from "react-bootstrap";
import "./Register.scss";
import backImage from "../../assets/Images/back.svg";
import PhoneInput, {CountryData} from "react-phone-input-2";
import Dropzone from "react-dropzone";
import {EncryptedHeader} from "../../Api/Login/encryptedHeader";
import {EcdhAesEncryption} from "../../Security/Crypto/Ecc/EcdhAesEncryption";
import {SecureStore} from "../../Security/Crypto/Storage/SecureStore";
import {StoreKeys} from "../../Security/Crypto/Storage/StoreKeys";
import {v4 as uuidv4} from "uuid";
import {useAppDispatch} from "../../Store/hooks";
import {CaptchaVerify, RegisterAsync} from "../../Api/Login/LoginApi";
import {useNavigate} from "react-router-dom";
import Lottie from "lottie-web";
import animation from "../../assets/Images/lottie-loader.json";
import {useSelector} from "react-redux";
import {captchaResetAction, loginActionLoading, loginCaptchaSuccess} from "../../Store/Login/LoginSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ValidationMessages} from "../../Models/Enums/ValiationMessages";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {HttpHelpers} from "../../Models/Enums/HttpHelpers";
import {AppRoutes} from "../../Models/Enums/AppRoutes";
import Reaptcha from 'reaptcha';
import {CaptchaModel} from "../../Models/Api/Login/CaptchaModel";


export const Register: FunctionComponent = () => {
    const captchaKey = process.env.REACT_APP_CAPTCHA_KEY;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const serverKey = process.env.REACT_APP_SERVER_KEY;
    const filesTypes = {
        "image/png": [".png"],
        "image/jpg": [".jpg"],
        "image/jpeg": [".jpeg"],
        "application/pdf": [".pdf"],
    }
    let element = document?.getElementById('loader');

    useEffect(() => {
        dispatch(captchaResetAction());
        element = document?.getElementById('loader');
        if (element) {
            const anim = Lottie.loadAnimation({
                container: element,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                name: 'animBtn',
                animationData: animation,
            })
        }


    }, [element])

    /**
     * State
     */
    const [step, setStep] = useState<number>(1);
    const [managerPhone, setManagerPhone] = useState("");
    const [managerPhoneCD, setManagerPhoneCD] = useState<CountryData>();
    const [managerPhoneDirty, setManagerPhoneDirty] = useState<boolean>(false);
    const [businessPhone, setBusinessPhone] = useState("");
    const [businessPhoneDirty, setBusinessPhoneDirty] = useState(false);
    const [nationalId, setNationalId] = useState<File | undefined>(undefined);
    const [nationalIdDirty, setNationalIdDirty] = useState<boolean>(false);
    const [nationalIdTooLarge, setNationalIdTooLarge] = useState<boolean>(false);
    const [commercialReg, setCommercialReg] = useState<File | undefined>(undefined);
    const [commercialRegDirty, setCommercialRegDirty] = useState<boolean>(false);
    const [commercialRegLarge, setCommercialRegLarge] = useState<boolean>(false);
    const [httpHelper, setHttpHelper] = useState<string>("https://")
    const [submitted, SetSubmitted] = useState<boolean>(false);
    const actionLoading = useSelector(loginActionLoading);
    const captchaVerifyStatus = useSelector(loginCaptchaSuccess);

    const recaptchaRef = useRef<Reaptcha>(null);
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);

    const VerifyCaptcha = () => {
        if (recaptchaRef && recaptchaRef.current) {
            recaptchaRef.current.getResponse().then(res => {
                setCaptchaToken(res);
                const keys = EcdhAesEncryption.fetchKeys();
                let headers: EncryptedHeader = {
                    nonce: "",
                    publicKey: "",
                };
                if (serverKey) {
                    const cipher = EcdhAesEncryption.encrypt(
                        keys.privateKey,
                        serverKey,
                        Buffer.from(res)
                    )
                    headers.nonce = cipher.base64Nonce ?? "";
                    headers.publicKey = Buffer.from(keys.publicKey).toString('base64');

                    const data: CaptchaModel = {
                        response: cipher.base64Cipher ?? "",
                    };

                    dispatch(CaptchaVerify({data, headers}));

                }
            })
        }

    }

    useEffect( () => {
        if(!captchaVerifyStatus && captchaToken !== null)
        {
            recaptchaRef!.current!.reset();
        }
    }, [captchaVerifyStatus])

    /**
     * Submit form
     * @param values
     * @constructor
     */
    const Submit = (values: BusinessRegisterModel) => {
        if (!commercialReg || !nationalId || managerPhone === "" || businessPhone === "") {
            return;
        }
        values.businessAccount.commercialRegistration = commercialReg;
        values.businessAccount.businessPhoneNumber = businessPhone
        values.manager.nationalId = nationalId;
        values.manager.countryCode = `+${managerPhoneCD?.dialCode}`;
        values.manager.phone = managerPhone.split(managerPhoneCD?.dialCode ?? "")[1];
        let rvalues = renderBusinessRegisterModel(values);
        let headers: EncryptedHeader = {
            nonce: "",
            publicKey: "",
            uniqueId: "",
        };
        const keys = EcdhAesEncryption.fetchKeys();
        if (serverKey) {
            const cipher = EcdhAesEncryption.encrypt(
                keys.privateKey,
                serverKey,
                Buffer.from(rvalues.manager.password)
            )
            const uniqueId = SecureStore.getItem(StoreKeys.BROWSER_ID) ?
                SecureStore.getItem(StoreKeys.BROWSER_ID) : uuidv4();
            headers.uniqueId = uniqueId ?? "";
            headers.nonce = cipher.base64Nonce ?? "";
            rvalues.manager.password = cipher.base64Cipher ?? "";
            headers.publicKey = Buffer.from(keys.publicKey).toString('base64');
            const data = RegisterToFormData(rvalues, httpHelper);
            if (data.toString().length > 0) {
                dispatch(RegisterAsync({data, headers})).then((e) => {
                    if (e.meta.requestStatus === "fulfilled") {
                        navigate(AppRoutes.DASHBOARD);
                    }
                })
            }

        }
    }

    /**
     * Form builder
     */
    const formBuilder = useFormik<BusinessRegisterModel>({
        initialValues: initialValuesRegisterModel,
        validationSchema: registerValidator,
        isInitialValid: false,
        validateOnChange: true,
        initialTouched: {},
        onSubmit: Submit,
    })

    /**
     *
     */
    const goToManagerInformation = async () => {
        SetSubmitted(true);
        if (!checkValidation()) {
            SetSubmitted(false);
            setStep(2);
        }
    }

    /**
     *
     */
    const goToContactInformation = async () => {
        SetSubmitted(true);
        if (!checkValidation()) {
            SetSubmitted(false);
            setStep(3);
        }
    }

    /**
     * Navigate back
     */
    const goBack = () => {
        setStep(prevState => prevState - 1);
    }

    /**
     * check validation
     */
    const checkValidation = () => {
        switch (step) {
            case 1:
                return formBuilder.values.manager?.email === "" || formBuilder.values.manager?.password === ""
                    || formBuilder.errors?.manager?.email || formBuilder.errors?.manager?.password;
            case 2 :
                return formBuilder.values.manager?.accountManagerName === "" || managerPhone === "" || nationalId === undefined || nationalIdTooLarge;
        }
    }

    const GoToSignIn = () => {
        navigate("Login");
    }

    const renderTooltipPassword = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {ValidationMessages.passwordMessage}
        </Tooltip>
    );
    const renderTooltipFiles = (props: any) => (
        <Tooltip id="button-tooltip-2" {...props}>
            png, jpeg, jpg and pdf are accepted
        </Tooltip>
    );


    /**
     * Template
     */
    return (<div className="register-form">
        <form onSubmit={formBuilder.handleSubmit}>
            <div className="register-card">
                {step > 1 &&
                    <div onClick={() => goBack()} className="register-back"><img src={backImage} alt="nice"/></div>}
                <div className="register-title">
                    {step === 1 && <h4>Create An Account</h4>}
                    {step === 2 && <h4>Manager Information</h4>}
                    {step === 3 && <h4>Contact Information</h4>}
                </div>
                {step === 1 && <div className="register-step-1 step">
                    <Form.Group>
                        <Form.Label>EMAIL <span className="required">*</span></Form.Label>
                        <Form.Control
                            autoComplete="off"
                            value={formBuilder.values.manager.email}
                            onBlur={formBuilder.handleBlur}
                            onChange={formBuilder.handleChange}
                            type="email" name="manager.email"/>
                        {formBuilder.errors.manager?.email && formBuilder.touched.manager?.email && submitted
                            && <p className="errors">{formBuilder.errors.manager?.email}</p>}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>PASSWORD
                            <span className="required">*</span>
                            <OverlayTrigger
                                placement="top"
                                delay={{show: 250, hide: 400}}
                                overlay={renderTooltipPassword}
                            >
                                <FontAwesomeIcon className="info-icon" icon={faInfoCircle}/>
                            </OverlayTrigger>

                        </Form.Label>
                        <Form.Control
                            autoComplete="off"
                            value={formBuilder.values.manager.password}
                            onChange={formBuilder.handleChange}
                            onBlur={formBuilder.handleBlur}
                            type="password" name="manager.password"/>
                        {formBuilder.errors.manager?.password && formBuilder.touched.manager?.password && submitted
                            && <p className="errors">{formBuilder.errors.manager?.password}</p>}
                    </Form.Group>
                    <div className="dialog-footer">
                        <Button onClick={() => goToManagerInformation()} className="spare_btn spare_btn_login">
                            Sign
                            up</Button>
                    </div>
                    <div className="help-section">
                        <span onClick={() => GoToSignIn()}>Already have an account? <span className="text-highlight">Sign in</span> </span>
                    </div>
                </div>}

                {step === 2 &&
                    <div className="register-step-2 step">
                        <Form.Group>
                            <Form.Label>ACCOUNT MANAGER NAME <span className="required">*</span></Form.Label>
                            <Form.Control
                                autoComplete="off"
                                value={formBuilder.values.manager.accountManagerName}
                                onChange={formBuilder.handleChange}
                                onBlur={formBuilder.handleBlur}
                                type="text" name="manager.accountManagerName"/>
                            <p>{formBuilder.touched.manager?.accountManagerName} </p>
                            {formBuilder.errors.manager?.accountManagerName && formBuilder.touched.manager?.accountManagerName && submitted
                                && <p className="errors">{formBuilder.errors.manager?.accountManagerName}</p>}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>PHONE NUMBER <span className="required">*</span></Form.Label>
                            <PhoneInput
                                inputProps={{
                                    name: 'phone',
                                }}
                                inputClass="form-control"
                                country={'kw'}
                                value={managerPhone}
                                onChange={(phone, e: CountryData) => {
                                    setManagerPhone(phone);
                                    setManagerPhoneCD(e);
                                    setManagerPhoneDirty(true);
                                }}
                            />
                            {managerPhone === ""  && submitted &&
                                <p className="errors">Phone is mandatory</p>}
                        </Form.Group>

                        <Dropzone accept={filesTypes} maxSize={5242880} onDrop={(acceptedFiles, fileRejections) => {
                      
                            setNationalIdTooLarge(  fileRejections.length > 0);
                            setNationalId(acceptedFiles[0]);
                            setNationalIdDirty(true);
                        }} multiple={false}>
                            {({getRootProps, getInputProps}) => (

                                <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    {!nationalId ?
                                        <div> <span className="upload-text" style={{fontSize: ".8rem"}}>
                         Upload National Id
                       </span>
                                            <div className="upload-helper">
                                                <span className="upload-helper-text">Choose File</span>
                                                <span className="upload-helper-indic">No file chosen
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{show: 250, hide: 400}}
                                                        overlay={renderTooltipFiles}
                                                    >
                                                         <FontAwesomeIcon className="info-icon" icon={faInfoCircle}/>
                                                     </OverlayTrigger>
                                                </span>
                                            </div>
                                        </div>
                                        : <span className="upload-text" style={{fontSize: ".8rem"}}>
                       File: {nationalId.name}</span>
                                    }
                                </div>
                            )}
                        </Dropzone>
                        {nationalId === undefined  && submitted &&
                            <p className="errors">National id is mandatory</p>}
                        {nationalIdTooLarge && <p className="errors">National id file is too large</p>}
                        <div className="dialog-footer">
                            <Button onClick={() => goToContactInformation()}
                                    className="spare_btn spare_btn_login">Next</Button>
                        </div>
                    </div>
                }
                {step === 3 && <div className="register-step-3 step">
                    <Form.Group>
                        <Form.Label>BUSINESS NAME <span className="required">*</span></Form.Label>
                        <Form.Control
                            autoComplete="off"
                            value={formBuilder.values.businessAccount.businessName}
                            onChange={formBuilder.handleChange}
                            onBlur={formBuilder.handleBlur}
                            type="text" name="businessAccount.businessName"/>
                        {formBuilder.errors.businessAccount?.businessName && formBuilder.touched.businessAccount?.businessName && submitted
                            && <p className="errors">{formBuilder.errors.businessAccount?.businessName}</p>}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>EMAIL <span className="required">*</span></Form.Label>
                        <Form.Control
                            autoComplete="off"
                            value={formBuilder.values.businessAccount.contactEmail}
                            onChange={formBuilder.handleChange}
                            onBlur={formBuilder.handleBlur}
                            type="text" name="businessAccount.contactEmail"/>
                        {formBuilder.errors.businessAccount?.contactEmail && formBuilder.touched.businessAccount?.contactEmail && submitted
                            && <p className="errors">{formBuilder.errors.businessAccount?.contactEmail}</p>}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>SECTOR <span className="required">*</span></Form.Label>
                        <Form.Select
                            name="businessAccount.sector"
                            value={formBuilder.values.businessAccount.sector}
                            onBlur={formBuilder.handleBlur}
                            onChange={formBuilder.handleChange}
                        >
                            <option value="default" disabled >
                                Choose Sector
                            </option>
                            {RegisterCategories.map(category => (
                                <option key={category} value={category}>{category}</option>
                            ))}
                        </Form.Select>
                        {formBuilder.errors.businessAccount?.sector && formBuilder.touched.businessAccount?.sector && submitted
                            && <p className="errors">{formBuilder.errors.businessAccount?.sector}</p>}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>BUSINESS WEBSITE</Form.Label>
                        <div className="http-form-control">
                            <Form.Select
                                autoComplete="off"
                                onChange={(e) => setHttpHelper(e.target.value)}
                                value={httpHelper}
                            >
                                <option value={HttpHelpers.HTTP}>{HttpHelpers.HTTP}</option>
                                <option value={HttpHelpers.HTTPS}>{HttpHelpers.HTTPS}</option>
                            </Form.Select>

                            <Form.Control
                                autoComplete="off"
                                value={formBuilder.values.businessAccount.businessWebSite}
                                onChange={formBuilder.handleChange}
                                onBlur={formBuilder.handleBlur}
                                type="text" name="businessAccount.businessWebSite"/>
                        </div>
                        {formBuilder.errors.businessAccount?.businessWebSite && formBuilder.touched.businessAccount?.businessWebSite && submitted
                            && <p className="errors">{formBuilder.errors.businessAccount?.businessWebSite}</p>}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>BUSINESS PHONE NUMBER <span className="required">*</span></Form.Label>
                        <PhoneInput
                            inputProps={{
                                name: 'phone',
                            }}
                            inputClass="form-control"
                            country={'kw'}
                            value={businessPhone}
                            onChange={(phone) => {
                                setBusinessPhone(phone);
                                setBusinessPhoneDirty(true);
                            }}
                        />
                        {businessPhone === "" && submitted && <p className="errors">Phone is mandatory</p>}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>COMERCIAL REGISTRATION NUMBER <span className="required">*</span></Form.Label>
                        <Form.Control
                            autoComplete="off"
                            value={formBuilder.values.businessAccount.registrationNumber}
                            onChange={formBuilder.handleChange}
                            onBlur={formBuilder.handleBlur}
                            type="text" name="businessAccount.registrationNumber"/>
                        {formBuilder.errors.businessAccount?.registrationNumber && formBuilder.touched.businessAccount?.registrationNumber && submitted
                            && <p className="errors">{formBuilder.errors.businessAccount?.registrationNumber}</p>}
                    </Form.Group>
                    <Dropzone accept={filesTypes} maxSize={5242880} onDrop={(acceptedFiles, fileRejections) => {
                        setCommercialRegLarge(fileRejections.length > 0 );
                        setCommercialReg(acceptedFiles[0]);
                        setCommercialRegDirty(true);
                    }} multiple={false}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                {!commercialReg ? <div> <span className="upload-text" style={{fontSize: ".8rem"}}>
                         Upload Commercial Registration
                       </span>
                                        <div className="upload-helper">
                                            <span className="upload-helper-text">Choose File</span>
                                            <span className="upload-helper-indic">No File Chosen 
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{show: 250, hide: 400}}
                                                        overlay={renderTooltipFiles}
                                                    >
                                                         <FontAwesomeIcon className="info-icon" icon={faInfoCircle}/>
                                                     </OverlayTrigger>
                                            </span>
                                        </div>
                                    </div>
                                    : <span>File: {commercialReg.name}</span>}
                            </div>
                        )}
                    </Dropzone>
                    {commercialReg === undefined && submitted &&
                        <p className="errors">Commercial regestration is mandatory</p>}
                    {commercialRegLarge &&
                        <p className="errors">Commercial regestration file is too large</p>}
                    {captchaKey && <Reaptcha
                        ref={recaptchaRef}
                        sitekey={captchaKey}
                        onVerify={VerifyCaptcha}
                        onExpire={() => dispatch(captchaResetAction())}
                    />}
                    <div className="dialog-footer">
                        <Button disabled={!captchaVerifyStatus} onClick={() => SetSubmitted(true)} type="submit" className="spare_btn spare_btn_login">
                            {!actionLoading && <span>Sign up</span>}
                            <div className={actionLoading ? "showLoader" : ""} id="loader"></div>
                        </Button>
                    </div>
                </div>}
            </div>
        </form>
    </div>);
}