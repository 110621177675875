import {FunctionComponent} from "react";
import "./BarChart.scss";
import ReactECharts from "echarts-for-react";
import {BarChartOptions, HBarChartOptions} from "./options";
import {BarChartProps} from "../../../Models/Props/BarChartProps";
import {EChartsOption} from "echarts-for-react/lib/types";

export const BarChart: FunctionComponent<BarChartProps> = (props) => {
    let options: EChartsOption;
    if (props.data) {
        options = props.orient ? HBarChartOptions(props.data) : BarChartOptions(props.data);
    } else {
        options = BarChartOptions({
            labels: [],
            data: []
        })
    }


    return (
        <div className="bar-chart">
            <div className="bar-chart_header">
                <div className="bar-chart_header_title">
                    {props.title}
                </div>
                <div>
                    {props.withFilter && <div>
                    </div>}
                </div>
            </div>
            <div className="bar-chart-container">
                <ReactECharts option={options}/>
            </div>
        </div>
    )
}