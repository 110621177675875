import {FunctionComponent, useEffect, useState} from "react";
import "./DashboardPage.scss"
import {Datepicker} from "../../Components/Datepicker/Datepicker";
import {DateObject} from "../../Models/Props/Datepicker/DateObject";
import {LineChartType} from "../../Models/Props/LineChartType";
import {LastPayments} from "../../Components/LastPayments/LastPayments";
import {LineChart} from "../../Components/Charts/LineChart/LineChart";
import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import {
    dashboardLastPayment,
    dashboardPaymentsLoading, dashboardSalesLoading,
    dashboardTotalPayments,
    dashboardTotalSales
} from "../../Store/Dashboard/dashboardSlice";
import {FetchAsyncLastPayments, FetchAsyncTotalPayments, FetchAsyncTotalSales} from "../../Api/Dashboard/dashboardApi";

export const DashboardPage: FunctionComponent = () => {

    /**
     * State
     */
    const [date, setDate] = useState<DateObject>();
    const dispatch = useAppDispatch();
    const lastPayments = useAppSelector(dashboardLastPayment);
    const lastPaymentsLoading = useAppSelector(state=>state.dashboard.lastPaymentLoading);
    const TotalSales = useAppSelector(dashboardTotalSales);
    const TotalPayments = useAppSelector(dashboardTotalPayments);
    const TotalPaymentsLoading = useAppSelector(dashboardPaymentsLoading);
    const TotalSalesLoading = useAppSelector(dashboardSalesLoading);


    useEffect(() => {
        dispatch(FetchAsyncLastPayments());
        if (date && !TotalPaymentsLoading && !TotalSalesLoading) {
            dispatch(FetchAsyncTotalPayments({startDate: date?.startDate, endDate: date?.endDate}));
            dispatch(FetchAsyncTotalSales({startDate: date?.startDate, endDate: date?.endDate}));
        }

    }, [date]);


    const getDateRange = (date: DateObject) => {
        setDate(date);
    }

    return (
        <div className="dashboard">
            <Datepicker getDate={getDateRange}/>
            <div className="dashboard_charts">
                <LineChart title="Total sales" loading={TotalSalesLoading} value={TotalSales.totalSalesAmount}
                           description="Sales over time"
                           type={LineChartType.CURRENCY} data={TotalSales?.chartData}/>
                <LineChart title="Total payments" loading={TotalPaymentsLoading} value={TotalPayments.paymentCount}
                           description="payments over time"
                           type={LineChartType.COUNT} data={TotalPayments?.chartData}/>
            </div>
            <LastPayments payments={lastPayments} loading={lastPaymentsLoading}/>
        </div>
    )
}