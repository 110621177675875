import {FunctionComponent, useEffect, useState} from "react";
import illustration from "../../assets/Images/Forgot Password Illustration.svg";
import logo from "../../assets/Images/Spare Business Logo-01-b.svg"
import logoMobile from "../../assets/Images/Spare Business Logo-01.svg"
import "./ResetConfirmation.scss";
import useWindowDimensions from "../../Hooks/windowsDimensions";

export const ResetPasswordConfirmation: FunctionComponent = () => {
    const {width} = useWindowDimensions();

    /**
     * State
     */
    const [mobile, setMobile] = useState(width <= 1024);
    useEffect(() => {
        setMobile(width <= 1024)
    }, [mobile])

    /**
     * Template
     */
    return (
        <div className="wrapper-confirmation">
            <div className="content-container">
                <div className="form-container">
                    <div className="form-header">
                        <div className="logo">
                            {!mobile ? <img src={logo} alt="logo"/> : <img src={logoMobile} alt="logo"/>}
                        </div>
                        <h4 className="business-title">SPARE BUSINESS</h4>
                        <p className="page-info">
                            <span className="graphik-m-font">The link has been sent, please check your email to reset your password.</span>
                        </p>
                    </div>
                    <div className="form-wrapper">
                        <div className="illustration">
                            <img src={illustration} alt="illustration"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}