import {FunctionComponent, useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import "./Login.scss";
import logo from "../../assets/Images/Spare Business Logo-01-b.svg";
import logoMobile from "../../assets/Images/Spare Business Logo-01.svg";
import illustration from "../../assets/Images/Forgot Password Illustration.svg";
import "./ForgetPassword.scss"
import {useFormik} from "formik";
import * as Yup from "yup";
import {ForgetPasswordModel} from "../../Models/Api/Login/ForgetPasswordModel";
import {useNavigate} from "react-router-dom";
import {AppRoutes} from "../../Models/Enums/AppRoutes";
import useWindowDimensions from "../../Hooks/windowsDimensions";
import {useAppDispatch} from "../../Store/hooks";
import {RequestPasswordResetAsync} from "../../Api/Login/LoginApi";
import {useSelector} from "react-redux";
import {loginRequestResetLoading, setResetPasswordEmailAction} from "../../Store/Login/LoginSlice";
import Lottie from "lottie-web";
import animation from "../../assets/Images/lottie-loader.json";

export const ForgetPassword: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const {width} = useWindowDimensions();
    const navigate = useNavigate();

    /**
     * Manage layout and animations
     */
    useEffect(() => {
        setMobile(width <= 1024)
        const anim = Lottie.loadAnimation({
            container: document.getElementById('loader') ?? new Element(),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            name: 'animBtn',
            animationData: animation,
        })
    })

    /**
     * Submit form
     * @param values
     * @constructor
     */
    const Submit = (values: ForgetPasswordModel) => {
        const data = values;
        dispatch(setResetPasswordEmailAction(data.email));
        dispatch(RequestPasswordResetAsync({data})).then((e) => {
            if (e.meta.requestStatus === "fulfilled") {
                navigate(AppRoutes.CODE_PASSWORD);
            }
        });
    }

    /**
     * State
     */
    const [mobile, setMobile] = useState(width <= 1024);
    const actionLoading = useSelector(loginRequestResetLoading);


    /**
     * Form builder
     */
    const formBuilder = useFormik<ForgetPasswordModel>({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email("Emain is invalid").required("Email is mandatory"),
        }),
        onSubmit: Submit
    })

    /**
     * Template
     */
    return (
        <div className="wrapper-forget">
            {mobile && <img className="illustration-mobile" src={illustration} alt="illustration"/>}
            <div className="content-container">
                <div className="form-container">
                    <div className="form-header">
                        <div className="logo">
                            {!mobile ? <img src={logo} alt="logo"/> : <img src={logoMobile} alt="logo"/>}
                        </div>
                        <h4 className="business-title ">SPARE BUSINESS</h4>
                        <p className="page-info">
                            <span className=" graphik-m-font ">Reset password</span><br/>
                            <span className="graphik-r-font">Please enter your email</span>
                        </p>
                    </div>
                    <div style={{"zIndex": 99999}} className="form-wrapper">
                        {!mobile && <div className="illustration">
                            <img src={illustration} alt="illustration"/>
                        </div>}
                        <Form onSubmit={formBuilder.handleSubmit}>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control   
                                    autoComplete="off"
                                    onChange={formBuilder.handleChange}
                                    value={formBuilder.values.email}
                                    name="email"
                                    type="text"
                                    placeholder="Your email"/>
                            </Form.Group>
                            <Button type="submit" className="spare_btn spare_btn_login">
                                {!actionLoading && <span>Send</span>}
                                <div className={actionLoading ? "showLoader" : ""} id="loader"></div>
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>

    )
}