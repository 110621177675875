import {FunctionComponent, useState} from "react";
import "../settings.scss";
import "./Security.scss"
import {PasswordForm} from "./PasswordForm";
import {EmailForm} from "./EmailForm";

export const Security: FunctionComponent = () => {
    /**
     * State
     */
    const [openPasswordForm, setOpenPasswordForm] = useState<boolean>(false);
    const [openEmailForm, setOpenEmailForm] = useState<boolean>(false);

    /**
     * Close password form
     */
    const closePasswordForm = () => {
        setOpenPasswordForm(false);
    }

    /**
     * Get password form data
     */
    const getPasswordFormData = () => {
        setOpenPasswordForm(false);
    }

    /**
     * Close password form
     */
    const closeEmailForm = () => {
        setOpenEmailForm(false);
    }

    /**
     * Get email form data
     */
    const getEmailFormData = () => {
        setOpenEmailForm(false);
    }

    /**
     * Template
     */
    return (<div className="settings-container">
        <div className="settings-header">
            <h4>Security And Login</h4>
        </div>
        <div className="security-body">
            {!openPasswordForm
                ? <div className="security-body_item">
                    <p className="security-body_item_title">Change password</p>
                    <p onClick={() => setOpenPasswordForm(true)} className="security-body_item_action">Edit</p>
                </div>
                : <PasswordForm close={() => closePasswordForm()} getData={getPasswordFormData}/>
            }
            {!openEmailForm
                ? <div className="security-body_item">
                    <p className="security-body_item_title">Change email</p>
                    <p onClick={() => setOpenEmailForm(true)} className="security-body_item_action">Edit</p>
                </div>
                : <EmailForm getData={getEmailFormData} close={closeEmailForm}/>}

        </div>
    </div>)
}