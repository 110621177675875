import {PaymentState} from "../../Models/Store/Payments/PaymentState";
import {MovementSortEnum} from "../../Models/Enums/Payments/MovementSortEnum";
import {PaymentModel} from "../../Models/Api/Payments/PaymentModel";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    CreatePaymentAsync, CsvPaymentExportAsync, ExcelPaymentExportAsync,
    ListPaymentAsync,
    PaymentDetailAsync,
    PdfPaymentExportAsync
} from "../../Api/Payments/PaymentsApi";
import {ServerResponse} from "../../Models/Api/ServerResponse";
import {PaymentListModel} from "../../Models/Api/Payments/PaymentListModel";
import {RootState} from "../store";
import {saveAs} from 'file-saver';
import {toast} from "react-toastify";


const initialState: PaymentState = {
    actionLoading: true,
    paymentList: new Array<PaymentModel>(),
    total: 0,
    selectedPayment: null,
    paymentId: null,
    file: null,
    filter: {
        paymentStatus: null,
        fromDate: "",
        toDate: "",
        keyword: "",
        perPage: 30,
        start: 0,
        sortBy: MovementSortEnum.MOST_RECENT,
        sortType: false,
    }
}

export const PaymentSlice = createSlice({
    initialState,
    name: "payment",
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(ListPaymentAsync.pending, (state) => {
            state.actionLoading = true;
        });

        /**
         * List payments
         */
        builder.addCase(ListPaymentAsync.fulfilled, (state,
                                                     action: PayloadAction<ServerResponse<PaymentListModel>>) => {
            state.paymentList = action.payload.data.paymentList;
            state.total = action.payload.data.count;
            state.actionLoading = false;

        });
        builder.addCase(ListPaymentAsync.rejected, (state) => {
            state.actionLoading = false;
        });

        /**
         * Get payment detail
         */
        builder.addCase(PaymentDetailAsync.pending, (state) => {
            state.actionLoading = true;
        });
        builder.addCase(PaymentDetailAsync.fulfilled, (state,
                                                       action: PayloadAction<ServerResponse<PaymentModel>>) => {
            state.actionLoading = false;
            state.selectedPayment = action.payload.data;
        });
        builder.addCase(PaymentDetailAsync.rejected, (state) => {
            state.actionLoading = false;
        });

        /**
         * Create payment
         */
        builder.addCase(CreatePaymentAsync.pending, (state) => {
            state.actionLoading = true;
        });
        builder.addCase(CreatePaymentAsync.fulfilled, (state,
                                                       action: PayloadAction<ServerResponse<string>>) => {
            state.actionLoading = false;
            state.paymentId = action.payload.data;
            toast.success('Payment created Successfully ', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
        });
        builder.addCase(CreatePaymentAsync.rejected, (state) => {
            state.actionLoading = false;
        });

        /**
         * Get pdf file
         */
        builder.addCase(PdfPaymentExportAsync.pending, (state) => {
            state.actionLoading = false;
        });
        builder.addCase(PdfPaymentExportAsync.fulfilled, (state,
                                                          action: PayloadAction<File>) => {
            state.actionLoading = false;
            state.file = action.payload;
            downloadFile(action.payload);
        });
        builder.addCase(PdfPaymentExportAsync.rejected, (state) => {
            state.actionLoading = false;
        });

        /**
         * Get excel file
         */
        builder.addCase(ExcelPaymentExportAsync.pending, (state) => {
            state.actionLoading = false;
        });
        builder.addCase(ExcelPaymentExportAsync.fulfilled, (state,
                                                            action: PayloadAction<File>) => {
            console.log(action.payload);
            state.actionLoading = false;
            state.file = action.payload;
            downloadFile(action.payload);

        });
        builder.addCase(ExcelPaymentExportAsync.rejected, (state) => {
            state.actionLoading = false;
        });

        /**
         * Get csv file
         */
        builder.addCase(CsvPaymentExportAsync.pending, (state) => {
            state.actionLoading = false;
        });
        builder.addCase(CsvPaymentExportAsync.fulfilled, (state,
                                                          action: PayloadAction<File>) => {
            state.actionLoading = false;
            state.file = action.payload;
            downloadFile(action.payload);
        });
        builder.addCase(CsvPaymentExportAsync.rejected, (state) => {
            state.actionLoading = false;
        });
    }

})


/**
 * Selectors
 */
export const paymentFilter = (state: RootState) => state.payment.filter;
export const paymentList$ = (state: RootState) => state.payment.paymentList;
export const paymentSelected = (state: RootState) => state.payment.selectedPayment;
export const paymentId = (state: RootState) => state.payment.paymentId;
export const paymentActionLoading = (state: RootState) => state.payment.actionLoading;
export const paymentCount = (state: RootState) => state.payment.total;

 
export const downloadFile = (file: File) => {

    saveAs(new Blob([file], {type: file.type}), `payments-${new Date().toString()}`);
}