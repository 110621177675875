import {FunctionComponent, useCallback, useEffect, useRef, useState} from "react";
import {CropperProp} from "../../../Models/Props/Settings/CropperProp";
import logo from "../../../assets/Images/logo-placeholder.png";
import {Button, Modal} from "react-bootstrap";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import "./ImageCropper.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDropzone} from "react-dropzone";
import "cropperjs/dist/cropper.css";
import ReactCropper from "react-cropper";
import {useOutsideAlerter} from "../../../Hooks/ClickOutside";

export const ImageCropper: FunctionComponent<CropperProp> = (props) => {
    /**
     * States
     */
    const [openCropper, setOpenCropper] = useState<boolean>(false);
    const [image, setImage] = useState<string | undefined>(props.image);
    const [filename, setFilename] = useState<string | undefined>();
    const [type, SetType] = useState<string | undefined>();
    const [file, setFile] = useState<File>();
    const [croppedImage, setCroppedImage] = useState<string | undefined>();
    const cropperRef = useRef<HTMLImageElement>(null);
    
    const modalRef = useRef(null);
    const outside = useOutsideAlerter(modalRef)
    useEffect(() => {
        if (outside) {
            setOpenCropper(false);
        }
    }, [outside])

    /**
     * File reader handler
     */
    const onDrop = useCallback(async (acceptedFiles: any) => {
        let file = acceptedFiles[0];
        setFilename(file.name);
        SetType(file.type);
        const img = await readFile(file);
        setImage(img);
    }, []);

    const readFile = (file: File) => {
        return new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                if (event && typeof event?.target?.result === "string" && event?.target?.result) {
                    resolve(event?.target?.result)
                }
            }
            reader.readAsDataURL(file);
        });
    }

    const {getRootProps, getInputProps} = useDropzone({onDrop})

    /**
     * Open cropper
     */
    const showCropper = () => {
        setOpenCropper(prevState => !prevState);
    }

    /**
     * Clear image
     * @constructor
     */
    const Clear = () => {
        setImage(undefined);
    }

    /**
     * Crop image
     */
    const onCrop = () => {
        const imageElement: any = cropperRef?.current;
        const cropper: any = imageElement?.cropper;
        let image = cropper.getCroppedCanvas().toDataURL();
        cropper.getCroppedCanvas().toBlob((blob: Blob) => {
            let file = new File([blob], filename ?? "file", {type: type});
            setFile(file);
            setCroppedImage(image);
        });
    }

    /**
     * Submit image
     */
    const submitImage = () => {
        if (image) {
            props.getImage(image, file, filename);
            setOpenCropper(false);
        }
    }

    /**
     * Template
     */
    return (
        <div className="cropper-wrapper">
            <div className="cropper-image-container">
                <div onClick={() => showCropper()} className="image-container">
                    {props.image && !croppedImage ? <img src={props.image} alt="image"/>
                        : (croppedImage ? <img src={croppedImage} alt="image"/> : <img src={logo} alt="logo"/>)}
                    <FontAwesomeIcon className="image-container_icon" icon={faPencil}/>
                </div>
            </div>
            <Modal  ref={modalRef}  title="cropper-modal" show={openCropper}>
                <div className="body-modal">
                <Modal.Header className="dialog-header">
                    <Modal.Title className="dailog-header_title">
                        {props.title}
                    </Modal.Title>
                    {image && <Button onClick={() => Clear()} className="spare_btn spare_btn_action">
                        Clear
                    </Button>}
                </Modal.Header>
             
                <Modal.Body >
                    {!image || image.includes("http") ?
                        <div className="drag-container" {...getRootProps()}>
                            <input title="cropper-file" {...getInputProps()} />

                            <p>Drag 'n' drop some files here, or click to select files</p>
                        </div>
                        :
                        <ReactCropper
                            src={image}
                            style={{height: 400, width: "100%"}}
                            ref={cropperRef}
                            crop={onCrop}
                        />
                    }
                </Modal.Body>
                <Modal.Footer className="dialog-footer">
                    <Button onClick={() => setOpenCropper(false)} className="spare_btn spare_btn_cancle">
                        Close
                    </Button>
                    <Button title="cropper-submit" onClick={() => submitImage()} type="submit"
                            className="spare_btn spare_btn_primary">
                        Submit
                    </Button>
                </Modal.Footer>
                </div>
            </Modal>
        </div>
    )
}