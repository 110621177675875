import {UsersState} from "../../Models/Store/Users/UsersState";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    AddUserAsync, DeleteUserAsync,
    DisableUserAsync, EditUserAsync,
    EnableUserAsync,
    FetchUsersListAsync,
    GetUserAsync,
    SetRoleUserAsync
} from "../../Api/Users/UsersApi";
import {UserModel} from "../../Models/Api/Users/UserModel";
import {ServerResponse} from "../../Models/Api/ServerResponse";
import {RootState} from "../store";
import {UsersListResponseModel} from "../../Models/Api/Users/UsersListResponseModel";
import {EditUserModel} from "../../Models/Api/Users/EditUserModel";
import {showToaster} from "../toaster";


const initialState: UsersState = {
    usersList: new Array<UserModel>(),
    filter: {
        start: 0,
        perPage: 30,
        keyword: '',
    },
    actionLoading: false,
    selectedUser: undefined,
    selectedUserId: '',
    loading: false,
    total: 0,
}

export const usersSlice = createSlice({
    initialState,
    name: 'users',
    reducers: {
        setEditedUser: (state, action: PayloadAction<EditUserModel>) => {
            state.selectedUserId = action.payload.id;
            state.selectedUser = action.payload
        }
    },
    extraReducers: (builder) => {

        /**
         * List user action
         */
        builder.addCase(FetchUsersListAsync.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(FetchUsersListAsync.fulfilled,
            (state,
             action: PayloadAction<ServerResponse<UsersListResponseModel>>) => {

                state.usersList = action.payload.data.users;
                state.total = action.payload.data.count;
                state.loading = false;
            })
        builder.addCase(FetchUsersListAsync.rejected,
            (state) => {
                state.loading = false;
            })

        /**
         * Add user action
         */
        builder.addCase(AddUserAsync.pending, (state) => {
            state.actionLoading = true;
        })
        builder.addCase(AddUserAsync.fulfilled,
            (state,
             action: PayloadAction<ServerResponse<string>>) => {
                state.actionLoading = false;
                state.selectedUserId = action.payload.data;
                showToaster("User added successfully !");
            })
        builder.addCase(AddUserAsync.rejected,
            (state) => {
                state.actionLoading = false;
            })

        /**
         * Get user action
         */
        builder.addCase(GetUserAsync.pending, (state) => {
            state.actionLoading = true;
        })
        builder.addCase(GetUserAsync.fulfilled,
            (state,
             action: PayloadAction<ServerResponse<UserModel>>) => {
                state.actionLoading = false;
                state.selectedUser = getSelectedUser(action.payload.data) ?? undefined;
                state.selectedUserId = action.payload.data.id ?? '';
            })
        builder.addCase(GetUserAsync.rejected,
            (state) => {
                state.actionLoading = false;
            })

        /**
         * Edit user action
         */
        builder.addCase(EditUserAsync.pending, (state) => {
            state.actionLoading = true;
        })
        builder.addCase(EditUserAsync.fulfilled,
            (state,
             action: PayloadAction<ServerResponse<string>>) => {
                state.actionLoading = false;
                state.selectedUserId = action.payload.data ?? '';
                showToaster("User Edited successfully !");
            })
        builder.addCase(EditUserAsync.rejected,
            (state) => {
                state.actionLoading = false;
            })

        /**
         * Set role action
         */
        builder.addCase(SetRoleUserAsync.pending, (state) => {
            state.actionLoading = true;
        })
        builder.addCase(SetRoleUserAsync.fulfilled, (state) => {
            state.actionLoading = false;
            showToaster("User roles updated successfully !");
        })
        builder.addCase(SetRoleUserAsync.rejected, (state) => {
            state.actionLoading = false;
        })

        /**
         * Enable user action
         */
        builder.addCase(EnableUserAsync.pending, (state) => {
            state.actionLoading = true;
        })
        builder.addCase(EnableUserAsync.fulfilled, (state) => {
            state.actionLoading = false;
            showToaster("User enabled successfully !");
        })
        builder.addCase(EnableUserAsync.rejected, (state) => {
            state.actionLoading = false;
        })

        /**
         * Disable user action
         */
        builder.addCase(DisableUserAsync.pending, (state) => {
            state.actionLoading = true;
        })
        builder.addCase(DisableUserAsync.fulfilled, (state) => {
            state.actionLoading = false;
            showToaster("User disabled successfully !");
        })
        builder.addCase(DisableUserAsync.rejected, (state) => {
            state.actionLoading = false;
        })

        /**
         * Disable user action
         */
        builder.addCase(DeleteUserAsync.pending, (state) => {
            state.actionLoading = true;
        })
        builder.addCase(DeleteUserAsync.fulfilled, (state) => {
            state.actionLoading = false;
            showToaster("User deleted successfully !");
        })
        builder.addCase(DeleteUserAsync.rejected, (state) => {
            state.actionLoading = false;
        })
    }
})

/**
 * Actions
 */
export const {setEditedUser} = usersSlice.actions;

/**
 * Selectors
 */
export const usersActionLoading = (state: RootState) => state.users.actionLoading;
export const ListUsers = (state: RootState) => state.users.usersList;
export const ListUsersTotal = (state: RootState) => state.users.total;
export const usersFiler = (state: RootState) => state.users.filter;
export const selectedUser = (state: RootState) => state.users.selectedUser;
export const selectedUserId = (state: RootState) => state.users.selectedUserId;
export const usersLoading = (state: RootState) => state.users.loading;


const getSelectedUser = (user: UserModel): EditUserModel => {
    return {
        id: user.id ?? '',
        firstName: user.fullname.split(' ')[0],
        lastName: user.fullname.split(' ')[1],
        phone: user.phone,
        email: user.email,
        roles: user.roles,
    }
}
