import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SearchInput.scss';
interface IState{
    value:string
    callBack:(e:string)=>void
}
function SearchInput(props:IState) {
    const onInputChange = (event:any)=>{
        props.callBack(event.target.value);
    }
    
    return (
        <div className="filter-search">
            <label htmlFor="search-payment" className="filter-search_icon">
                <FontAwesomeIcon icon={faSearch} />
            </label>
            <input id="search-payment" value={props.value} onChange={onInputChange} type="text"
                className="filter-search_input" placeholder="search" />
        </div>
    )
}

export default SearchInput