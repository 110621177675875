import {FunctionComponent, useEffect} from "react";
import "./InvoiceIndex.scss"
import {faFileInvoiceDollar} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {InvoiceTable} from "../InvoiceTable/InvoiceTable";
import {invoiceColumns} from "../../../Mocks/InvoiceMock";
import {useNavigate} from "react-router-dom";
import {AppRoutes} from "../../../Models/Enums/AppRoutes";
import {InvoiceRoutes} from "../../../Models/Enums/Routes/InvoiceRoutes";
import {useAppDispatch, useAppSelector} from "../../../Store/hooks";
import {invoiceActionLoading, invoiceList} from "../../../Store/Invoice/InvoiceSlice";
import {DeleteInvoiceAsync, ListInvoiceAsync} from "../../../Api/InvoiceApi/InvoiceApi";
import {InvoiceMenuNames} from "../../../Models/Enums/Invoice/InvoiceMenuNames";
import SearchInput from "../../UI/SearchInput/SearchInput";
import SButton from "../../UI/Button/Button";

export const InvoiceIndex: FunctionComponent = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const list = useAppSelector(invoiceList);
    const loading = useAppSelector(invoiceActionLoading)

    useEffect(() => {
        if (!loading) {
            dispatch(ListInvoiceAsync({start: "0", perPage: "60"}))
        }
    }, [])


    const getMenuAction = (action: string, id?: string) => {
        if (action === InvoiceMenuNames.INVOICE_DELETE) {
            if (id) {
                dispatch(DeleteInvoiceAsync({id})).then(e => {
                    if (e.meta.requestStatus) {
                        dispatch(ListInvoiceAsync({start: "0", perPage: "60"}))
                    }
                })
            }

        }
    }

    const createInvoice = () => {
        navigate(`${AppRoutes.Invoice}/${InvoiceRoutes.CREATE_INVOICE}`);
    }

    /**
     * Template
     */
    return (
        <div className="Invoice">
            <div className="Invoice_card">
                <div className="Invoice_card_header">
                    <div className="card-header_title">
                        <FontAwesomeIcon icon={faFileInvoiceDollar}/>
                        <h4>Invoice</h4>
                    </div>
                    <div className="invoice-actions">
                    <SearchInput value="" callBack={e=>{}}/>
                    <SButton label="Create Invoice" onClick={createInvoice}/>
                    </div>
                    
                </div>
                <div className="Invoice_card_body">
                    <div className="Invoice_table">
                        <InvoiceTable loading={loading} columns={invoiceColumns} data={list} getAction={getMenuAction}/>
                    </div>
                </div>
            </div>
        </div>
    )
}