import {createAsyncThunk} from "@reduxjs/toolkit";
import {ServerResponse} from "../../Models/Api/ServerResponse";
import {FdRuleModel} from "../../Models/Api/FraudDetection/FdRuleModel";
import {FdActionNames} from "../../Models/Enums/FraudDetection/FdActionNames";
import {api} from "../api";
import {FraudMonitoringRoutes} from "../ApiConstants";
import {SimpleServerResponse} from "../../Models/Api/SimpleServerResponse";
import {BlackListModel} from "../../Models/Api/FraudDetection/BlackListModel";
import {AxiosResponse} from "axios";


export const ListRulesAsync = createAsyncThunk<ServerResponse<FdRuleModel[]>>(
    FdActionNames.ListRulesAction,
    async (arg, thunkAPI) => {
        const response: AxiosResponse<ServerResponse<FdRuleModel[]>> = await api.get(FraudMonitoringRoutes.ListRulesUrl, {
            signal: thunkAPI.signal,
        });
        return response.data;
    }
)

export const GeRulesAsync = createAsyncThunk<ServerResponse<FdRuleModel>, { id: string }>(
    FdActionNames.GetRuleAction,
    async (data) => {
        const response: AxiosResponse<ServerResponse<FdRuleModel>> = await api.get(`${FraudMonitoringRoutes.GetRuleUrl}?id=${data.id}`);
        return response.data;
    }
)

export const AddRuleAsync = createAsyncThunk<SimpleServerResponse, { model: FdRuleModel }>(
    FdActionNames.AddRuleAction,
    async (data) => {
        const response: AxiosResponse<SimpleServerResponse> = await api.post(FraudMonitoringRoutes.AddRuleUrl, data.model);
        return response.data;
    }
)


export const UpdateRuleAsync = createAsyncThunk<SimpleServerResponse, { model: FdRuleModel }>(
    FdActionNames.UpdateRulesAction,
    async (data) => {
        const response: AxiosResponse<SimpleServerResponse> = await api.post(`${FraudMonitoringRoutes.UpdateRuleUrl}?id=${data.model.id}`, data.model);
        return response.data;
    }
)

export const DeleteRulesAsync = createAsyncThunk<SimpleServerResponse, { id: string }>(
    FdActionNames.DeleteRulesAction,
    async (data) => {
        const response: AxiosResponse<SimpleServerResponse> = await api.delete(`${FraudMonitoringRoutes.DeleteRuleUrl}?id=${data.id}`);
        return response.data;
    }
)

export const BlackListAsync = createAsyncThunk<ServerResponse<BlackListModel[]>>(
    FdActionNames.ListBlackListAction,
    async (arg, thunkAPI) => {
        const response: AxiosResponse<ServerResponse<BlackListModel[]>> = await api.get(FraudMonitoringRoutes.BlackListUrl,
            {
                signal: thunkAPI.signal
            });
        return response.data;
    }
)

export const GetBlackListAsync = createAsyncThunk<ServerResponse<BlackListModel>>(
    FdActionNames.GetBlackListAction,
    async () => {
        const response: AxiosResponse<ServerResponse<BlackListModel>> = await api.get(FraudMonitoringRoutes.GetBlackListUrl);
        return response.data;
    }
)

export const AddBlackListAsync = createAsyncThunk<SimpleServerResponse, { model: BlackListModel }>(
    FdActionNames.AddBlackListAction,
    async (data) => {
        const response: AxiosResponse<SimpleServerResponse>
            = await api.post(FraudMonitoringRoutes.AddBlackListUrl, data.model);
        return response.data;
    }
)


export const UpdateBlackListAsync = createAsyncThunk<SimpleServerResponse, { model: BlackListModel, id: string }>(
    FdActionNames.UpdateBlackListAction,
    async (data) => {
        const response: AxiosResponse<SimpleServerResponse>
            = await api.post(`${FraudMonitoringRoutes.UpdateBlackListUrl}?id=${data.id}`, data.model);
        return response.data;
    }
)


export const DeleteBlackListAsync = createAsyncThunk<SimpleServerResponse, { id: string }>(
    FdActionNames.DeleteBlackListAction,
    async (data) => {
        const response: AxiosResponse<SimpleServerResponse>
            = await api.delete(`${FraudMonitoringRoutes.DeleteBlackListUrl}?id=${data.id}`);
        return response.data;
    }
)













