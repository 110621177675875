import {FunctionComponent, useEffect, useRef, useState} from "react";
import "../settings.scss";
import "./ApiKeys.scss"
import {ApiKeysTable} from "./ApiKeysTable";
import {ApikeyActions} from "../../../Models/Enums/Settings/ApikeyActions";
import {BusinessApiKeyModel} from "../../../Models/Api/Settings/BusinessApiKeyModel";
import {CreateKey} from "./CreateKey";
import {useAppDispatch, useAppSelector} from "../../../Store/hooks";
import {settingApiKeyList, settingApiKeyLoading} from "../../../Store/Settings/SettingsSlice";
import {ListApiKeysAsync} from "../../../Api/Settings/SettingsApi";
import SButton from "../../UI/Button/Button";
import { EcdhAesEncryption } from "../../../Security/Crypto/Ecc/EcdhAesEncryption";

export const ApiKeys: FunctionComponent = () => {

    const dispatch = useAppDispatch();
    /**
     * State
     */
    const list = useAppSelector(settingApiKeyList);
    const loading = useAppSelector(settingApiKeyLoading);
    const [openCreatKey, setOpenCreateKey] = useState<boolean>(false);
    const [key, setKey] = useState<BusinessApiKeyModel>();
    const [action, setAction] = useState<ApikeyActions>(ApikeyActions.INDEX);

    useEffect(() => {
        dispatch(ListApiKeysAsync());
    }, [])

    /**
     * Handle submitted key
     * @param values
     * @param action
     */
    const handleCreateKeySubmit = (values: BusinessApiKeyModel, action: ApikeyActions) => {
        switch (action) {
            case ApikeyActions.CREATE:
                setOpenCreateKey(false);
                break;
            case ApikeyActions.REGENERATE:
                setOpenCreateKey(false);
                break;
        }
        setAction(ApikeyActions.INDEX);
        dispatch(ListApiKeysAsync());
    }

    /**
     * Open create key form
     */
    const createKey = () => {
        setKey(undefined);
        setAction(ApikeyActions.CREATE);
        setOpenCreateKey(true);
    }

    const closeCreateKeyForm = () => {
        setOpenCreateKey(false);
        setAction(ApikeyActions.INDEX);
    }

    /**
     * Api keys action handler
     * @param action
     * @param payload
     */
    const getApikeyTableAction = (action: ApikeyActions, payload: BusinessApiKeyModel) => {
        switch (action) {
            case ApikeyActions.REGENERATE:
                setAction(ApikeyActions.REGENERATE)
                setKey(payload);
                setOpenCreateKey(true);
        }
        dispatch(ListApiKeysAsync());
    }
    const submitRef = useRef<HTMLButtonElement>()


    /**
     * Template
     */
    return (<div className="settings-container">
        <div className="settings-header">
            {action === ApikeyActions.INDEX && <h4>Api Keys</h4>}
            {action === ApikeyActions.CREATE && <h4>Create Api Key</h4>}
            {action === ApikeyActions.REGENERATE && <h4>Regenerate Api Keys</h4>}{
                openCreatKey &&
                <div className="apikey-actions d-flex gap-2">
                    <SButton label="Cancel" type="button" class="warning" onClick={closeCreateKeyForm}/>
                    <SButton label="Save" type="submit" class="primary" onClick={()=>submitRef.current?.click()}/>
                </div>
            }
            {!openCreatKey && <SButton label="Craete Key" type="button" onClick={()=>createKey()}/>}
        </div>
        <div className="w-100 ">
            {loading && <div className="progress">
                <div className="progress-bar  indeterminate">
                </div>
            </div>}

        </div>
        {!openCreatKey
            ? <div className="apiKeys-body">
                <div className="apiKeys-body_description">
                    <p>
                        An Api Key is a token that can identify you to our Spare API, you can generate a new api key by
                        pressing "Create key" button <br/>
                        Always keep your API keys a secret! If one of your keys is accidentally revealed, you can always
                        generate a new one at any time. You can also remove existing API keys if necessary.
                    </p>
                </div>
              
               { !loading && <div className="apiKeys-body_table">
                    <ApiKeysTable data={list} getAction={getApikeyTableAction}/>
                </div>}
            </div>
            : <CreateKey submitRef={submitRef} action={action} data={key} submit={handleCreateKeySubmit} close={() => closeCreateKeyForm()}/>
        }
    </div>)
}