import React, { FunctionComponent, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import logoEmpty from "../../../assets/Images/empty-state.svg";
import "./TablePayments.scss"
import { TablePaymentProp } from "../../../Models/Props/Table/TableProp";
import GetPaymentStatusColor from "../../../Hooks/getPaymentStatusColor";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";
import { AppRoutes } from "../../../Models/Enums/AppRoutes";
import { PaymentsRoutes } from "../../../Models/Enums/Routes/PaymentsRoutes";
import GetPaymentStatusText from "../../../Hooks/getPaymentStatusText";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../Hooks/windowsDimensions";
import loader from "../../../assets/Images/Double Ring-1s-200px.gif";
import LoadingTable from "../../UI/LoadingTable/LoadingTable";

export const TablePayments: FunctionComponent<TablePaymentProp> = (props) => {
    const navigate = useNavigate();
    const { width } = useWindowDimensions();


    /**
     * State
     */
    const [mobile, setMobile] = useState(width <= 1024);

    useEffect(() => {
        setMobile(width <= 1024)
    }, [mobile,width])

    /**
     * Payment detail
     * @param id
     */
    const paymentDetail = (id?: string) => {
        navigate(`${AppRoutes.PAYMENTS}/${PaymentsRoutes.PAYMENT_DETAIL}/${id}`);
    }

    /**
     * Template
     */
    return (
     
                <div className="table-payments">
                    <Table striped={!mobile} className="table-responsive">
                        <thead>
                            <tr>
                                {props.columns.map(column => (
                                    <th key={column}>{column}</th>
                                ))}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="table-body">

                            {props.data.length > 0 && !props.loading && props.data.map(item => (
                                <tr key={item.id}>
                                    <td onClick={() => paymentDetail(item.id)} className="payment-status-container">
                                        <span style={{ 'backgroundColor': GetPaymentStatusColor(item.status) }}
                                            className="payment-status-dot"></span>
                                        {item?.reference}</td>
                                    <td>{item?.createAt}</td>
                                    <td>{item?.debtor?.fullname ? <span>{item?.debtor?.fullname}</span> : <span>Guest</span>}</td>
                                    <td>{item?.amount} BHD</td>
                                    <td>{GetPaymentStatusText(item.status)}</td>
                                    {!mobile && <td>
                                        <Dropdown>
                                            <Dropdown.Toggle role="dropdown" id="dropdown-basic">
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item className="dropdown-item disabled"
                                                ><FontAwesomeIcon className="dropdown-item_icon"
                                                    icon={faHandHoldingDollar} /> Payment
                                                    refunds</Dropdown.Item>
                                                <Dropdown.Item title="payment-detail-dropdown-item"
                                                    onClick={() => paymentDetail(item?.id)}
                                                    className="dropdown-item"><FontAwesomeIcon
                                                        className="dropdown-item_icon" icon={faInfo} /> Payment
                                                    detail</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>}
                                    {mobile && <td className="mobile-menu-actions">
                                        <Dropdown.Item className="dropdown-item disabled"
                                        ><FontAwesomeIcon className="dropdown-item_icon"
                                            icon={faHandHoldingDollar} />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => paymentDetail(item.id)}
                                            className="dropdown-item"><FontAwesomeIcon
                                                className="dropdown-item_icon" icon={faInfo} />
                                        </Dropdown.Item>
                                    </td>
                                    }
                                </tr>
                            ))
                            }

                        </tbody>
                    </Table>
                </div>

    )
}