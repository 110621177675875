import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IdentificationsNames } from '../../../../Models/Enums/Settings/IdentificationsNames';
import { UpdateBankAccountModel } from '../../../../Models/Store/Settings/BankAccount';
import { BankProvider } from '../../../../Models/Store/Settings/BankProvider';
import SButton from '../../../UI/Button/Button';
import Modal from '../../../UI/Modal/Modal';
import './ManageBankAccount.scss'

function ManageBankAccount(props: any) {
    const form = useFormik<UpdateBankAccountModel>({
        initialValues: {
            providerId: props.account?props.account.provider.id:'',
            scheme: props.account? props.account.scheme:IdentificationsNames.IBAN,
            identification: props.account?props.account.identification:'',
            currency: props.account?props.account.currency:'BHD'
        },
        validationSchema: Yup.object({
            identification: Yup.string()
                // .min(20,'Identification code must contain at least 20 characters')
                // .max(34,'Identification code must not exceed less 34 characters')
                .required("IBAN/BBAN is required."),
            providerId: Yup.string().required("Provider is required."),
        }),
        onSubmit: handleForm
    });

    function handleForm(values: UpdateBankAccountModel) {
        props.onAction(values);
        props.hideModal();
    }
    const handleIdentification = (event:any)=>{
     const parsedIdentification =  event.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
     form.setValues({
        ...form.values,
        identification:parsedIdentification
     })
    }
    const providers = props.providers as BankProvider[]
    return (
        <Modal hideModal={props.hideModal}>
            <div className="header">{props.title}</div>
            <form className="manage_content" onSubmit={form.handleSubmit} >
                <div role={'group'} className="schema">
                    <div className="schema_iban">
                        <label htmlFor="iban">IBAN</label>
                        <label className='custom-radio-btn'>
                            <input type="radio" id='iban' name='scheme' value={IdentificationsNames.IBAN} checked={form.values.scheme === IdentificationsNames.IBAN} onChange={form.handleChange} />
                            <span className='checkmark'></span>
                        </label>
                    </div>

                    <div className="schema_bban">
                        <label htmlFor="bban">BBAN</label>
                        <label className='custom-radio-btn'>
                            <input type="radio" id='bban' name='scheme' value={IdentificationsNames.BBAN} checked={form.values.scheme === IdentificationsNames.BBAN} onChange={form.handleChange} />
                            <span className='checkmark'></span>
                        </label>
                    </div>

                </div>

                <div className="inputer">
                    <label htmlFor="schema_value">{form.values.scheme}</label>
                    <input className={
                        `${form.errors.identification && form.touched.identification? 'invalid':''}`
                    } type="text" id='schema_value' name='identification' value={form.values.identification} onChange={handleIdentification} autoComplete="off" />
                    {form.errors.identification && form.touched.identification ? <small className='error'>{form.errors.identification}</small> : null}
                </div>

                <div className="inputer">
                    <label htmlFor="providerId">Provider</label>
                    <select
                    className={
                        `${form.errors.providerId && form.touched.providerId? 'invalid':''}`}
                    name="providerId" id="providerId" value={form.values.providerId} onChange={form.handleChange}>
                        <option value={''} disabled defaultValue={''}>Select your provider</option>
                        {providers.map((e:BankProvider) => 
                            (
                                <option key={e.id}  value={e.id} >{e.provider_name}</option>
                            ) 
                        )}
                    </select>
                    {form.errors.providerId && form.touched.providerId ? <small className='error'>{form.errors.providerId}</small> : null}
                </div>

                <div className="inputer">
                    <label htmlFor="currency">Currency</label>
                    <input readOnly type="text" id='currency' name='currency' value={form.values.currency} onChange={form.handleChange} />
                </div>

                <div className="footer">
                    <SButton type='button' class='warning' onClick={props.hideModal} label='Cancel'/>
                    <SButton type='submit'  onClick={props.hideModal} label={props.actionTitle}/>
                </div>


            </form>

        </Modal>
    )
}

export default ManageBankAccount