export enum AppRoutes {
    DASHBOARD = "/",
    LOGIN = "*",
    FORGET_PASSWORD = "/forgetPassword",
    CODE_PASSWORD = "/Code",
    RESET_PASSWORD_CONFIRMATION = "/ConfirmReset",
    RESET_PASSWORD_SUCCESS = "/SuccessReset",
    RESET_PASSWORD = "/ResetPassword",
    REGISTER = "/Register",
    PAYMENTS = "/Payments",
    Statistics = "/Statistics",
    Invoice = "/Invoice",
    Users = "/Users",
    Settings = "/Settings",
    Waiting = "*"
}