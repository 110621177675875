import {FunctionComponent} from "react";
import {LastPaymentsProp} from "../../Models/Props/LastPayments/LastpaymentsProp";
import Table from 'react-bootstrap/Table';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faCoins} from "@fortawesome/free-solid-svg-icons";
import "./LastPayments.scss";
import GetPaymentStatusColor from "../../Hooks/getPaymentStatusColor";
import {NavLink} from "react-router-dom";
import {AppRoutes} from "../../Models/Enums/AppRoutes";
import NoData from "../UI/NoData/NoData";
import LoadingTable from "../UI/LoadingTable/LoadingTable";

export const LastPayments: FunctionComponent<LastPaymentsProp> = ({payments,loading}) => {

    /**
     * Template
     */
    return (
        <div className="last-payments">
            <div className="last-payments_header">
                <h4 className="last-payments_header_title">LAST PAYMENTS</h4>
                <NavLink to={AppRoutes.PAYMENTS}>
                    <span className="last-payments_header">View all</span>
                </NavLink>

            </div>
            {
                loading &&
                    <LoadingTable/>
            }
            {
               !loading && payments.length > 0 &&
                <div className="payments_table">
                    <Table className="table-responsive">
                        <thead>
                        <tr>
                            <th>
                                Reference
                            </th>
                            <th><FontAwesomeIcon icon={faCalendar}/> Date</th>
                            <th><FontAwesomeIcon icon={faCoins}/> Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            payments.map(p => (
                                <tr key={p.reference}>
                                    <td className="payment-status-container">
                                        <div style={{'backgroundColor': GetPaymentStatusColor(p.status)}}
                                             className="payment-status-dot"></div>
                                        {p.reference}
                                    </td>
                                    <td>
                                        {p.createAt}
                                    </td>
                                    <td>
                                        {p.amount}
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </div>
            }
            {
               !loading && payments.length === 0 &&
                <NoData/>
            }
        </div>
    )
}