import {FunctionComponent} from "react";
import {faFacebook, faTwitter, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import "./Social.scss";

export const Social: FunctionComponent = () => {
    const facebook = faFacebook as IconProp;
    const twitter = faTwitter as IconProp;
    const instagram = faInstagram as IconProp;
    return (
        <div className="social-container">
            <a href="https://www.facebook.com/tryspare" target="_blank">
                <FontAwesomeIcon icon={facebook}/>
            </a>
            <a href="https://twitter.com/tryspare?lang=en" target="_blank">
                <FontAwesomeIcon icon={twitter}/>
            </a>
            <a href="https://www.instagram.com/tryspare/?hl=en" target="_blank">
                <FontAwesomeIcon icon={instagram}/>
            </a>
            <a href="mailto:hello@tryspare.com">
                <FontAwesomeIcon icon={faEnvelope}/>
            </a>
        </div>
    )
}