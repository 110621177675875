import React, { FunctionComponent, useEffect, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars,
    faChartBar,
    faCoins,
    faDashboard,
    faFileInvoiceDollar,
    faUsers,
    faXmark
} from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import Avatar from 'react-avatar';
import "./Navabar.scss";
import logo from "../../assets/Images/safari-pinned-tab.svg";
import { AppRoutes } from "../../Models/Enums/AppRoutes";
import useWindowDimensions from "../../Hooks/windowsDimensions";
import { SettingsRoutes } from "../../Models/Enums/Routes/SettingsRoutes";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { logOutAction } from "../../Store/Login/LoginSlice";
import { GetProfileAsync } from "../../Api/Settings/SettingsApi";
import { settingBusinessProfile } from "../../Store/Settings/SettingsSlice";
import { formBlockNavigation } from "../../Store/Form/FormSlice";

export const Navbar: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const blockNavigation = useAppSelector(formBlockNavigation);
    const { width } = useWindowDimensions();

    useEffect(() => {
        setMobile(width <= 1024)
    }, [width])

    /**
     * State
     */
    const profile = useAppSelector(settingBusinessProfile);
    const [mobile, setMobile] = useState(width <= 1024);
    const [showMobileNavigation, setMobileNavigation] = useState(false);

    const logOut = () => {
        dispatch(logOutAction());
        navigate("login");
    }
    useEffect(() => {
        dispatch(GetProfileAsync())
    }, [])

    /**
     * Navigate to setting page
     */
    const goToSettingsPage = () => {
        navigate(`${AppRoutes.Settings}/${SettingsRoutes.INDEX}`);
    }

    const mobileNavigationHandler = () => {
        setMobileNavigation(prevState => {
            return !prevState;
        })
    }


    /**
     * Template
     */
    return (<div className={blockNavigation ? "Navbar block-navigation" : "Navbar"}>
        <div className="navbar_top-navbar">
            <NavLink to='' className="navbar_top-navbar_logo">
                <img alt="logo" src={logo} />
                <h4 className="navbar_top-navbar_logo_text">SPARE BUSINESS</h4>
            </NavLink>
            <div className='navbar_hamburgur'>
                <div className="navbar_top-navbar_settings">
                    <Dropdown>
                        <Dropdown.Toggle className="navbar_top-navbar_settings_toggle">
                            <Avatar  size="40" name={profile?.accountManagerName![0] + " " + profile?.accountManagerName![1]} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => goToSettingsPage()} className="navbar-item-title"
                            >Settings</Dropdown.Item>
                            <Dropdown.Item onClick={() => logOut()} className="navbar-item-title">Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                {mobile && <FontAwesomeIcon onClick={() => mobileNavigationHandler()} className="mobile-navigation-btn"
                    icon={faBars} />}
            </div>

        </div>
        {!mobile && <div role="bottom-navbar" className="navbar_bottom-navbar">
            <ul className="navbar_bottom-navbar_navigations-list">
                <NavLink to={AppRoutes.DASHBOARD}
                    className={({ isActive }) => (isActive ? "navbar-item_active" : "navbar-item")}>
                    <FontAwesomeIcon icon={faDashboard} />
                    <span className="navbar-item-title">Dashboard</span>
                </NavLink>
                <NavLink to={AppRoutes.PAYMENTS}
                    className={({ isActive }) => (isActive ? "navbar-item_active" : "navbar-item")}>
                    <FontAwesomeIcon icon={faCoins} />
                    <span className="navbar-item-title">Payments</span>
                </NavLink>
                <NavLink to={AppRoutes.Statistics}
                    className={({ isActive }) => (isActive ? "navbar-item_active" : "navbar-item")}>
                    <FontAwesomeIcon icon={faChartBar} />
                    <span className="navbar-item-title">Stat</span>
                </NavLink>
                <NavLink to={AppRoutes.Invoice}
                    className={({ isActive }) => (isActive ? "navbar-item_active" : "navbar-item")}>
                    <FontAwesomeIcon icon={faFileInvoiceDollar} />
                    <span className="navbar-item-title">Invoice</span>
                </NavLink>
                <NavLink to={AppRoutes.Users}
                    className={({ isActive }) => (isActive ? "navbar-item_active" : "navbar-item")}>
                    <FontAwesomeIcon icon={faUsers} />
                    <span className="navbar-item-title">Users</span>
                </NavLink>
            </ul>
        </div>
        }
        {mobile && showMobileNavigation && <div className="mobile-navigation">
            <FontAwesomeIcon className="mobile-navigation_close_btn" onClick={() => mobileNavigationHandler()}
                icon={faXmark} />
            <ul onClick={() => mobileNavigationHandler()} className="navbar_bottom-navbar_navigations-list_mobile">
                <NavLink to={AppRoutes.DASHBOARD}
                    className={({ isActive }) => (isActive ? "navbar-item_active" : "navbar-item")}>
                    <FontAwesomeIcon icon={faDashboard} />
                    <span className="navbar-item-title">Dashboard</span>
                </NavLink>
                <NavLink to={AppRoutes.PAYMENTS}
                    className={({ isActive }) => (isActive ? "navbar-item_active" : "navbar-item")}>
                    <FontAwesomeIcon icon={faCoins} />
                    <span className="navbar-item-title">Payments</span>
                </NavLink>
                <NavLink to={AppRoutes.Statistics}
                    className={({ isActive }) => (isActive ? "navbar-item_active" : "navbar-item")}>
                    <FontAwesomeIcon icon={faChartBar} />
                    <span className="navbar-item-title">Stat</span>
                </NavLink>
                <NavLink to={AppRoutes.Invoice}
                    className={({ isActive }) => (isActive ? "navbar-item_active" : "navbar-item")}>
                    <FontAwesomeIcon icon={faFileInvoiceDollar} />
                    <span className="navbar-item-title">Invoice</span>
                </NavLink>
                <NavLink to={AppRoutes.Users}
                    className={({ isActive }) => (isActive ? "navbar-item_active" : "navbar-item")}>
                    <FontAwesomeIcon icon={faUsers} />
                    <span className="navbar-item-title">Users</span>
                </NavLink>
            </ul>
        </div>
        }
    </div>)
}