import {SettingState} from "../../Models/Store/Settings/SettingState";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    ActivateKeyAsync, DeleteApiKeyAsync,
    GetBusinessAccountAsync, GetCallBacksAsync,
    GetProfileAsync, GreatKeyAsync, ListApiKeysAsync, RegenerateKeyAsync, RevokeKeyAsync,
    UpdateApiCallBackUrl,
    UpdateBusinessAccountAsync, UpdateCallBacksAsync, UpdateEmailAsync, UpdatePasswordAsync,
    UpdateProfileAsync, UploadBusinessLogo, UploadProfileLogo
} from "../../Api/Settings/SettingsApi";
import {ServerResponse} from "../../Models/Api/ServerResponse";
import {BusinessAccountModel} from "../../Models/Api/Settings/BusinesAccountModel";
import {ProfileModel} from "../../Models/Api/Settings/ProfileModel";
import {InvoiceCallBackModel} from "../../Models/Api/Settings/InvoiceCallBackModel";
import {BusinessApiKeyModel} from "../../Models/Api/Settings/BusinessApiKeyModel";
import {RootState} from "../store";
import {showErrorToaster, showToaster} from "../toaster";
import { EcdhAesEncryption } from "../../Security/Crypto/Ecc/EcdhAesEncryption";


const initialState: SettingState = {
    businessAccount: null,
    actionLoading: false,
    businessEmail: "",
    businessPassword: "",
    businessProfile: null,
    callBack: null,
    apiKeyLoading: false,
    listApiKeys: new Array<BusinessApiKeyModel>(),
    selectedApiKey: null,
    apiKeyId: "",
}
function decryptApiIDandKey(apiKey:string,apiId:string,stringNonce:string){
    const nonce = Buffer.from(stringNonce, 'base64');
    const keys = EcdhAesEncryption.fetchKeys();
    const decryptedKey = Buffer.from(
        EcdhAesEncryption.decrypt(
            keys.privateKey,
            process.env.REACT_APP_SERVER_KEY ?? "",
            Buffer.from(apiKey, 'base64'),
            nonce,
        ),
    ).toString('utf-8');
    
    const decryptedID = Buffer.from(
        EcdhAesEncryption.decrypt(
            keys.privateKey,
            process.env.REACT_APP_SERVER_KEY ?? "",
            Buffer.from(apiId, 'base64'),
            nonce,
        )
    ).toString('utf-8');
        
    return {
        appKey:decryptedKey,
        appId:decryptedID
    }
}

export const SettingsSlice = createSlice({
    initialState,
    name: "Settings",
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetBusinessAccountAsync.pending, (state) => {
            state.actionLoading = true
        });
        builder.addCase(GetBusinessAccountAsync.fulfilled, (state
            , action: PayloadAction<ServerResponse<BusinessAccountModel>>) => {
            state.businessAccount = action.payload.data;
            state.actionLoading = false

        });
        builder.addCase(GetBusinessAccountAsync.rejected, (state) => {
            state.actionLoading = false
        });


        builder.addCase(UpdateBusinessAccountAsync.pending, (state) => {
            state.actionLoading = true
        });
        builder.addCase(UpdateBusinessAccountAsync.fulfilled, (state
            , action: PayloadAction<ServerResponse<BusinessAccountModel>>) => {
            state.businessAccount = action.payload.data;
            state.actionLoading = false
            showToaster("Business account updated successfully !");

        });
        builder.addCase(UpdateBusinessAccountAsync.rejected, (state) => {
            state.actionLoading = false
        });


        builder.addCase(GetProfileAsync.pending, (state) => {
            state.actionLoading =true;
        });
        builder.addCase(GetProfileAsync.fulfilled, (state
            , action: PayloadAction<ServerResponse<ProfileModel>>) => {
            state.businessProfile = action.payload.data;
            state.actionLoading =false;

        });
        builder.addCase(GetProfileAsync.rejected, (state) => {
            state.actionLoading =false;

        });

        builder.addCase(UpdateProfileAsync.pending, (state) => {
            state.actionLoading = true
        });
        builder.addCase(UpdateProfileAsync.fulfilled, (state
            , action: PayloadAction<ServerResponse<ProfileModel>>) => {
            state.businessProfile = action.payload.data;
            state.actionLoading = false
            showToaster("Profile updated successfully !");
        });
        builder.addCase(UpdateProfileAsync.rejected, (state) => {
            state.actionLoading = false
        });

        builder.addCase(GetCallBacksAsync.pending, (state) => {
            state.actionLoading =true;
        });
        builder.addCase(GetCallBacksAsync.fulfilled, (state
            , action: PayloadAction<ServerResponse<InvoiceCallBackModel>>) => {
            state.callBack = action.payload.data;
            state.actionLoading =false;


        });
        builder.addCase(GetCallBacksAsync.rejected, (state) => {
            showErrorToaster("Could not get callback URL");
            state.actionLoading =false;
        });

        builder.addCase(UpdateCallBacksAsync.pending, (_) => {

        });
        builder.addCase(UpdateCallBacksAsync.fulfilled, (state
            , action: PayloadAction<ServerResponse<InvoiceCallBackModel>>) => {
            state.callBack = action.payload.data;

        });
        builder.addCase(UpdateCallBacksAsync.rejected, (_) => {

        });


        builder.addCase(ListApiKeysAsync.pending, (state) => {
            state.apiKeyLoading = true;
        });
        builder.addCase(ListApiKeysAsync.fulfilled,(state,action)=>{
            state.apiKeyLoading=false;
            const nonce = action.payload.headers['nonce'];
            const keyList = action.payload.data;
            keyList.forEach((el)=>{
                const decryptedValues = decryptApiIDandKey(el.apiKey!,el.appId!,nonce);
                el['apiKey'] = decryptedValues.appKey;
                el['appId'] = decryptedValues.appId;
            });
            
            
            state.listApiKeys = keyList;
            
            
            
        });
        builder.addCase(ListApiKeysAsync.rejected, (state) => {
            state.apiKeyLoading = false;
        });

        builder.addCase(GreatKeyAsync.pending, (state) => {
            state.apiKeyLoading = true;
        });
        builder.addCase(GreatKeyAsync.fulfilled, (state
            , action: PayloadAction<ServerResponse<string>>) => {
            state.apiKeyLoading = false;
            state.apiKeyId = action.payload.data;
            showToaster("Api key created successfully!")

        });
        builder.addCase(GreatKeyAsync.rejected, (state) => {
            state.apiKeyLoading = false;
        });

        builder.addCase(RevokeKeyAsync.pending, (state) => {
            state.apiKeyLoading = true;
        });
        builder.addCase(RevokeKeyAsync.fulfilled, (state,action) => {
            const index = state.listApiKeys.findIndex((e)=>e.id===action.payload.data.id);
            if(index!==-1){
                state.listApiKeys[index] = {
                    ...state.listApiKeys[index],
                    isRevoked:true
                }
            }
            state.apiKeyLoading = false;
            state.apiKeyId = "";
            showToaster("Api key revoked successfully!")

        });
        builder.addCase(RevokeKeyAsync.rejected, (state) => {
            state.apiKeyLoading = false;
        });

        builder.addCase(ActivateKeyAsync.pending, (state) => {
            state.apiKeyLoading = true;
        });
        builder.addCase(ActivateKeyAsync.fulfilled, (state,action) => {
            const index = state.listApiKeys.findIndex((e)=>e.id===action.payload.data.id);
            if(index!==-1){
                state.listApiKeys[index]={
                    ...state.listApiKeys[index],
                    isRevoked:false
                }
            }
            state.apiKeyLoading = false;
            state.apiKeyId = "";
            showToaster("Api key activated successfully!")

        });
        builder.addCase(ActivateKeyAsync.rejected, (state) => {
            state.apiKeyLoading = false;
        });


        builder.addCase(RegenerateKeyAsync.pending, (state) => {
            state.apiKeyLoading = true;
        });
        builder.addCase(RegenerateKeyAsync.fulfilled, (state) => {
            state.apiKeyLoading = false;
            state.apiKeyId = "";
            showToaster("Api key regenerated successfully!")

        });
        builder.addCase(RegenerateKeyAsync.rejected, (state) => {
            state.apiKeyLoading = false;
        });

        builder.addCase(DeleteApiKeyAsync.pending, (state) => {
            state.apiKeyLoading = true;
        });
        builder.addCase(DeleteApiKeyAsync.fulfilled, (state) => {
            state.apiKeyLoading = false;
            state.apiKeyId = "";
            showToaster("Api key deleted successfully!")

        });
        builder.addCase(DeleteApiKeyAsync.rejected, (state) => {
            state.apiKeyLoading = false;
        });
        builder.addCase(UpdateApiCallBackUrl.pending,(state)=>{
            state.apiKeyLoading = true;
        });
        builder.addCase(UpdateApiCallBackUrl.fulfilled,(state,action)=>{
            const index = state.listApiKeys.findIndex((el)=>el.id === action.payload.data.id)
            if(index !== -1){
                state.listApiKeys[index] = {
                    ...state.listApiKeys[index],
                    callbackUrl:action.payload.data.callbackUrl
                }
            }
            showToaster("API callback URL has been updated successfully!")
            state.apiKeyLoading =false;
        });
        builder.addCase(UpdateApiCallBackUrl.rejected,(state)=>{
            state.apiKeyLoading = false;
        })
        builder.addCase(UpdatePasswordAsync.pending, (state) => {
            state.actionLoading = true;
        });
        builder.addCase(UpdatePasswordAsync.fulfilled, (state) => {
            state.actionLoading = false;
            showToaster("Password updated successfully!");

        });
        builder.addCase(UpdatePasswordAsync.rejected, (state) => {
            state.actionLoading = false;
        });

        builder.addCase(UpdateEmailAsync.pending, (state) => {
            state.actionLoading = true;
        });
        builder.addCase(UpdateEmailAsync.fulfilled, (state) => {
            state.actionLoading = false;
            showToaster("Email updated successfully!");

        });
        builder.addCase(UpdateEmailAsync.rejected, (state) => {
            state.actionLoading = false;
        });

        builder.addCase(UploadBusinessLogo.pending, (state) => {
            state.actionLoading = true;
        });
        builder.addCase(UploadBusinessLogo.fulfilled, (state) => {
            state.actionLoading = false;
            showToaster("Logo update successfully !");
        });
        builder.addCase(UploadBusinessLogo.rejected, (state) => {
            state.actionLoading = false;
        });

        builder.addCase(UploadProfileLogo.pending, (state) => {
            state.actionLoading = true;
        });
        builder.addCase(UploadProfileLogo.fulfilled, (state) => {
            state.actionLoading = false;
            showToaster("Profile image update successfully !");
        });
        builder.addCase(UploadProfileLogo.rejected, (state) => {
            state.actionLoading = false;
        });
    }
})

/**
 * Selectors
 */
export const settingBusinessAccount = (state: RootState) => state.settings.businessAccount;
export const settingActionLoading = (state: RootState) => state.settings.actionLoading;
export const settingBusinessProfile = (state: RootState) => state.settings.businessProfile;
export const settingBusinessCallbacks = (state: RootState) => state.settings.callBack;
export const settingApiKeyList = (state: RootState) => state.settings.listApiKeys;
export const settingApiKeyId = (state: RootState) => state.settings.apiKeyId;
export const settingSelectedApiKey = (state: RootState) => state.settings.apiKeyId;
export const settingApiKeyLoading = (state: RootState) => state.settings.apiKeyLoading;