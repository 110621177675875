import { FunctionComponent } from "react";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CreateKeyProp } from "../../../Models/Props/Settings/CreateKeyProp";
import { RegenerateApiKeyModel } from "../../../Models/Api/Settings/BusinessApiKeyModel";
import { useAppDispatch } from "../../../Store/hooks";
import { GreatKeyAsync, RegenerateKeyAsync } from "../../../Api/Settings/SettingsApi";
import { ApikeyActions } from "../../../Models/Enums/Settings/ApikeyActions";
import SButton from "../../UI/Button/Button";
import { onReadMore } from "../../../Models/Enums/AppEnviroment";
interface FormState {
    publicKey: string
    callbackUrl: string
}
export const CreateKey: FunctionComponent<CreateKeyProp> = (props) => {
    const dispatch = useAppDispatch();

    /**
     * Form submit
     * @param values
     * @constructor
     */
    const Submit = (values: FormState) => {
        const publickey = values.publicKey;
        let callbackurl;
        if (values.callbackUrl.length > 0) {
            callbackurl = values.callbackUrl;
        }
        else {
            callbackurl = null
        }
        if (props.action === ApikeyActions.CREATE) {
            const model: RegenerateApiKeyModel = {
                publicKey: publickey,
                callbackUrl: callbackurl
            }
            dispatch(GreatKeyAsync({ model: model })).then(e => {
                if (e.meta.requestStatus === "fulfilled") {
                    props.submit(values, props.action);
                }
            })
        }
        if (props.action === ApikeyActions.REGENERATE) {
            const model: RegenerateApiKeyModel = {
                publicKey: publickey,
                callbackUrl: callbackurl,
                id: props.data?.id
            }
            if (props.action === ApikeyActions.REGENERATE) {
                dispatch(RegenerateKeyAsync({ model: model })).then(e => {
                    if (e.meta.requestStatus === "fulfilled") {
                        props.submit(values, props.action);
                    }
                })
            }

        }


    }

    /**
     * Form builder
     */
    const regex = /^((https):\/\/)/
    const formBuilder = useFormik<FormState>({
        initialValues: {
            publicKey: props?.data?.publicKey ? props.data.publicKey : "",
            callbackUrl: ""
        },
        validationSchema: Yup.object().shape({
            publicKey: Yup.string().required("Public key is mandatory"),
            callbackUrl: Yup.string().url('Not a valid Url format').matches(regex, "Url must be of type https")
        }),
        onSubmit: Submit,
    })

    /**
     * Template
     */
    return (
        <div>
            <Form onSubmit={formBuilder.handleSubmit} className="gap-4">
                <Form.Group >
                    <Form.Label className="required-dot">Please enter your public key</Form.Label>
                    <Form.Control autoComplete="off"
                        id="apiKeys_form_textarea"
                        name="publicKey"
                        value={formBuilder.values.publicKey}
                        onChange={formBuilder.handleChange}
                        as="textarea" />
                    {formBuilder.errors.publicKey && formBuilder.touched.publicKey ? <p className="errors">{formBuilder.errors.publicKey}</p> : null}
                </Form.Group>

                <Form.Group >
                    <Form.Label >CallBack URL</Form.Label>
                    <p className="callback-note">Due to security reasons, the callback can only be sent over the port 443/HTTPS !
                        Also, the callback does not follow redirects.
                        <div>
                            <a href={onReadMore()} target='_blank'>Read more about callback URL</a>
                        </div>
                    </p>
                    <Form.Control
                        id="apiCallBack"
                        name="callbackUrl"
                        value={formBuilder.values.callbackUrl}
                        onChange={formBuilder.handleChange}
                        autoComplete="off" />
                    {formBuilder.errors.callbackUrl && formBuilder.touched.callbackUrl ? <p className="errors">{formBuilder.errors.callbackUrl}</p> : null}

                </Form.Group>
                <div className="dialog-footer">

                    <button type="submit" style={{ display: 'none' }} ref={props.submitRef} />
                </div>
            </Form>
        </div>
    )
}