import {FunctionComponent, useEffect, useState} from "react";
import logo from "../../assets/Images/Spare Business Logo-01-b.svg";
import logoMobile from "../../assets/Images/Spare Business Logo-01.svg";
import illustration from "../../assets/Images/Forgot Password Illustration.svg";
import "./ResetConfirmation.scss"
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "../../Hooks/windowsDimensions";

export const ResetPasswordSuccess: FunctionComponent = () => {

    const {width} = useWindowDimensions();
    const navigate = useNavigate();
    /**
     * State
     */
    const [mobile, setMobile] = useState(width <= 1024);

    useEffect(() => {
        setMobile(width <= 1024);
    }, [mobile])

    const goToLogoIn = () => {
        navigate("login");
    }
    /**
     * Template
     */
    return (
        <div className="wrapper-confirmation">
            <div className="content-container">
                <div className="form-container">
                    <div className="form-header">
                        <div className="logo">
                            {!mobile ? <img src={logo} alt="logo"/> : <img src={logoMobile} alt="logo"/>}

                        </div>
                        <h4 className="business-title">SPARE BUSINESS</h4>
                        <p className="page-info">
                    <span className="graphik-m-font"> Your password has been reset.<br/> Please
                        <a className="login-link" onClick={() => goToLogoIn()}> click here to log in</a>.</span><br/>
                        </p>
                    </div>
                    <div style={{"zIndex": 99999, "backgroundColor": "transparent"}} className="form-wrapper">
                        <div className="illustration">
                            <img src={illustration} alt="illustration"/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}