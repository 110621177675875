import {FunctionComponent, useEffect, useRef, useState} from "react";
import { Form, Modal} from "react-bootstrap";
import {AddUserProp} from "../../../Models/Props/Users/AddUserProp";
import {AddUserModel} from "../../../Models/Api/Users/AddUserModel";
import {UserRoles} from "../../../Models/Enums/Users/UserRoles";
import {UserModalNames} from "../../../Models/Enums/Users/UserModalNames";
import {MultiSelect, Option} from "react-multi-select-component";
import {useFormik} from "formik";
import * as Yup from "yup";
import {AddUserAsync} from "../../../Api/Users/UsersApi";
import {useAppDispatch, useAppSelector} from "../../../Store/hooks";
import {usersActionLoading} from "../../../Store/Users/UsersSlice";
import animation from "../../../assets/Images/lottie-loader.json";
import Lottie from "lottie-web";
import {ValidationMessages} from "../../../Models/Enums/ValiationMessages";
import PhoneInput, {CountryData} from "react-phone-input-2";
import {useOutsideAlerter} from "../../../Hooks/ClickOutside";
import SButton from "../../UI/Button/Button";


export const AddUser: FunctionComponent<AddUserProp> = (props) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const element = document?.getElementById('loader');
        if (element) {
            const anim = Lottie.loadAnimation({
                container: element,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                name: 'animBtn',
                animationData: animation,
            })
        }

    }, [])

    /**
     * State
     */
    const actionLoading = useAppSelector(usersActionLoading);

    const [roles, setRoles] = useState<Option[]>([]);
    const [rolesDirty, setRolesDirty] = useState(false);
    const [phone, setPhone] = useState<string>("");
    const [phoneCd, setPhoneCd] = useState<CountryData>();
    const [phoneDirty, setPhoneDirty] = useState<boolean>(false);


    /**
     * Form submit handler
     * @param values
     */
    const handleSubmit = (values: AddUserModel) => {
        if (phone === "") {
            return
        }
        values.phone = `${phoneCd?.dialCode}${phone}`
        dispatch(AddUserAsync({user: values})).then(e => {
            if (e.meta.requestStatus === "fulfilled") {
                props.getData(values);
            }
        })
    }

    /**
     * handle roles selection
     * @param options
     */
    const handleRolesOnchange = (options: Option[]) => {
        setRoles(options);
        setRolesDirty(true);
        formBuilder.values.roles = options.map(r => r.value);
    }
    const modalRef = useRef(null);
    const outside = useOutsideAlerter(modalRef)
    useEffect(() => {
        if (outside) {
            props.close(UserModalNames.ADD_USER)
        }
    }, [outside])

    /**
     * FormBuilder
     */
    const formBuilder = useFormik<AddUserModel>({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            roles: [],
            password: '',
            phone: ''
        },
        //TODO: Fix regex
        validationSchema: Yup.object().shape({
            firstName: Yup.string().required("Firstname is mandatory"),
            lastName: Yup.string().required("Lastname is mandatory"),
            email: Yup.string().required("Email is mandatory"),
            roles: Yup.array().min(1, "Role is mandatory").required("Role is mandatory"),
            password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                ValidationMessages.passwordMessage)
                .required("Password name is mandatory"),
            phone: Yup.string()
        }),
        onSubmit: handleSubmit
    })

    /**
     * User roles select options
     */
    const rolesOptions: Option[] = [
        {label: "Manager", value: UserRoles.BUSINESS_MANAGER},
        {label: "Accountant", value: UserRoles.BUSINESS_ACCOUNTANT},
        {label: "Employee", value: UserRoles.BUSINESS_EMPLOYEE},
    ]


    /***
     * Template
     */
    return (<Modal ref={modalRef} data-id="user-add-modal" className="user-modal" show={props.show}>
        <Modal.Header className="dialog-header">
            <Modal.Title className="dailog-header_title">Add user</Modal.Title>
        </Modal.Header>
        <Form onSubmit={formBuilder.handleSubmit}>
            <Modal.Body className="dailog-body">
                <Form.Group>
                    <Form.Label>First name</Form.Label>
                    <Form.Control autoComplete="off"
                                  value={formBuilder.values.firstName}
                                  onChange={formBuilder.handleChange}
                                  onBlur={formBuilder.handleBlur}
                                  name="firstName"
                                  type="text"
                                  placeholder="Enter first name"
                    />
                    {formBuilder.errors.firstName && formBuilder.touched.firstName ?
                        <p role="errors" className="errors">{formBuilder.errors.firstName}</p> : null}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Last name</Form.Label>
                    <Form.Control autoComplete="off"
                                  value={formBuilder.values.lastName}
                                  onChange={formBuilder.handleChange}
                                  onBlur={formBuilder.handleBlur}
                                  name="lastName"
                                  type="text"
                                  placeholder="Enter last name"
                    />
                    {formBuilder.errors.lastName && formBuilder.touched.lastName ?
                        <p role="errors" className="errors">{formBuilder.errors.lastName}</p> : null}
                </Form.Group>

                <Form.Group className="form-multi-select">
                    <Form.Label>Role</Form.Label>
                    <MultiSelect
                        overrideStrings={{name: "roles", role: "role_select"}}
                        value={roles}
                        onChange={handleRolesOnchange}
                        className="form-multi-select_input"
                        options={rolesOptions}
                        labelledBy="Select"
                    />
                    {formBuilder.errors.roles && rolesDirty ?
                        <p role="errors" className="errors">{formBuilder.errors.roles}</p> : null}
                </Form.Group>

                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control autoComplete="off"
                                  value={formBuilder.values.email}
                                  onChange={formBuilder.handleChange}
                                  onBlur={formBuilder.handleBlur}
                                  name="email"
                                  type="email"
                                  placeholder="Enter email"
                    />
                    {formBuilder.errors.email && formBuilder.touched.email ?
                        <p role="errors" className="errors">{formBuilder.errors.email}</p> : null}
                </Form.Group>
                <Form.Group className="form-full-flex">
                    <Form.Label>Phone</Form.Label>
                    <PhoneInput
                        inputProps={{
                            name: 'phone',
                        }}
                        inputClass="form-control"
                        country={'kw'}
                        value={phone}
                        onChange={(phone: string, e: CountryData) => {
                            setPhone(phone);
                            setPhoneCd(e);
                            setPhoneDirty(true);
                        }}
                    />
                    {phone === "" && phoneDirty ?
                        <p role="errors" className="errors">{formBuilder.errors.phone}</p> : null}
                </Form.Group>

                <Form.Group className="form-full-flex">
                    <Form.Label>Password</Form.Label>
                    <Form.Control autoComplete="off"
                                  value={formBuilder.values.password}
                                  onChange={formBuilder.handleChange}
                                  onBlur={formBuilder.handleBlur}
                                  name="password"
                                  type="password"
                                  placeholder="Enter password"
                    />
                    {formBuilder.errors.password && formBuilder.touched.password ?
                        <p role="errors" className="errors">{formBuilder.errors.password}</p> : null}
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className="dialog-footer pt-4">
                <SButton label="Close" class="warning" onClick={()=> props.close(UserModalNames.ADD_USER)} type="button"/>
                <SButton disabled={actionLoading} label="Add" type="submit"/>
            </Modal.Footer>
            <div className="w-100 ">
                {actionLoading && <div className="progress">
                    <div className="progress-bar  indeterminate">
                    </div>
                </div>}


            </div>
        </Form>
    </Modal>)
}