import * as Yup from "yup";

export const getInvoiceFormValidators = () => {
    return Yup.object().shape({
        form: Yup.object().shape({
            fields: Yup.array().of(Yup.object().shape({
                dataTypeId: Yup.string(),
                name: Yup.string()
                    .max(15, "Field name must be maximum 15 characters")
                    .min(2, "Field name must be minimum 2 characters")
                    .required("Field name is mandatory"),
                isRequired: Yup.boolean(),
                identifier: Yup.string(),
                canHaveOptions: Yup.boolean(),
                options: Yup.array().of(Yup.object().shape({
                    value: Yup.string().required("Option is mandatory"),
                    identifier: Yup.string(),
                    id: Yup.string(),
                }))
            }))
        })
    })
}