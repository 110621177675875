import {PaymentsStatus} from "../Models/Enums/Payments/PaymentsStatus";
import {PaymentsColorStatus} from "../Models/Enums/Payments/PaymentsColorStatus";

/***
 * Get payment status color
 * @param status
 */
export default function GetPaymentStatusColor(status: PaymentsStatus = PaymentsStatus.PENDING) {
    switch (status) {
        case PaymentsStatus.AWAITING_AUTHORIZATION:
        case PaymentsStatus.PENDING:
            return PaymentsColorStatus.Pending;
        case PaymentsStatus.RECEIVED:
        case PaymentsStatus.COMPLETED:
            return PaymentsColorStatus.Paid;
        case PaymentsStatus.REJECTED:
        case PaymentsStatus.CANCELLED:
        case PaymentsStatus.ACCEPTED:
            return PaymentsColorStatus.Canceled;
        default:
            return PaymentsColorStatus.Pending;
    }
}