import {FunctionComponent, useEffect, useState} from "react";
import "./blackList.scss";
import {BlackListModel} from "../../../Models/Api/FraudDetection/BlackListModel";
import {BlackListType} from "../../../Models/Api/FraudDetection/BlackListType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile, faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
import {AddBlackItem} from "./AddBlackItem";
import {useAppDispatch, useAppSelector} from "../../../Store/hooks";
import {
    fdBlackList,
    fdBlackListLoading,
} from "../../../Store/FraudMonitoring/FraudMonitoringSlice";
import {BlackListAsync, DeleteBlackListAsync} from "../../../Api/Fraudmonitoring/FraudMonitoringApi";
import SButton from "../../UI/Button/Button";

export const BlackList: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    /**
     * State
     */
    const loading = useAppSelector(fdBlackListLoading)
    const list = useAppSelector(fdBlackList);
    const [openAddBL, setOpenAddBL] = useState<boolean>(false);
    const [selectedBL, setSelectedBL] = useState<BlackListModel>();
    const [editing, setEditing] = useState<boolean>(false);

    useEffect(() => {
        const promise = dispatch(BlackListAsync());
        return () => {
            promise.abort();
        }
    }, [])

    /**
     * Add item
     * @constructor
     */
    const AddedItemHandler = () => {
        if (editing) {
            setEditing(false);
            setSelectedBL(undefined);
        }
        setOpenAddBL(false);
        dispatch(BlackListAsync());
    }

    /**
     * Open add item form
     */
    const addBlackList = () => {
        setOpenAddBL(true);
    }

    /**
     * Get black list item type text
     * @param type
     */
    const getBlackListTypeText = (type: BlackListType) => {
        switch (type) {
            case BlackListType.DEBTOR_EMAIL:
                return "Email";
            case BlackListType.DEBTOR_PHONE:
                return "Phone";
            case BlackListType.DEBTOR_IP_ADDRESS:
                return "Ip address";
            case BlackListType.DEBTOR_ACCOUNT_IDENTIFICATION:
                return "Account identification";
            case BlackListType.DEBTOR_BANK_ACCOUNT:
                return "Bank account";
            case BlackListType.DEBTOR_REFERENCE:
                return "Reference";
        }
    }

    /**
     * Edit black list item
     * @constructor
     */
    const EditBlackList = (item: BlackListModel) => {
        setEditing(true);
        setOpenAddBL(true);
        setSelectedBL(item);
        dispatch(BlackListAsync());
    }

    /**
     * Close add black item form
     */
    const closeAddBlackItem = () => {
        setOpenAddBL(false);
        setEditing(false);
        setSelectedBL(undefined);
    }

    /**
     * Delete black list item
     * @param id
     * @constructor
     */
    const Delete = (id?: string) => {
        if (id) dispatch(DeleteBlackListAsync({id})).then(e => {
            if (e.meta.requestStatus === "fulfilled") {
                dispatch(BlackListAsync());
            }
        })
    }

    /**
     * Template
     */
    return (<div className="settings-container">
        <div className="settings-header">
            <h4>Black List</h4>
        </div>
        <div className="w-100 ">
            {loading && <div className="progress">
                <div className="progress-bar  indeterminate">
                </div>
            </div>}

        </div>
        <div className="blackList-body">
            <div className="blackList-body_header w-100 d-flex justify-content-end">
                
                <SButton label="Add" onClick={() => addBlackList()}/>
            </div>
            <div>
                {openAddBL &&
                    <AddBlackItem editing={editing} data={selectedBL} getData={AddedItemHandler}
                                  close={closeAddBlackItem}/>}
            </div>

            <div className="blackList-body_list">
                {
                    list && list.length > 0 &&
                    <div className="w-100">

                        {list.map(item => (
                            selectedBL?.id !== item.id &&
                            <div key={item.identifier} className="blackList-item">
                                <span>{getBlackListTypeText(item.type)}</span>
                                <span>{item.value}</span>
                                <div className="black-item-actions">
                                    <div title="edit-black-item" onClick={() => EditBlackList(item)}>
                                        <FontAwesomeIcon icon={faPencil}/>
                                    </div>
                                    <div title="delete-black-item" onClick={() => Delete(item?.id)}>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </div>
                                </div>
                            </div>

                        ))}
                    </div>
                }
                {!openAddBL && list.length <= 0 && !loading &&
                    < div className="rule-empty-data">
                        <FontAwesomeIcon icon={faFile}/>
                        <span>No black list items</span>
                    </div>
                }
            </div>
        </div>
    </div>)
}