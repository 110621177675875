import {FormEvent, FunctionComponent, useEffect, useRef, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {UserModalNames} from "../../../Models/Enums/Users/UserModalNames";
import {SetRoleProp} from "../../../Models/Props/Users/SetRoleProp";
import {MultiSelect, Option} from "react-multi-select-component";
import {UserRoles} from "../../../Models/Enums/Users/UserRoles";
import {useAppDispatch, useAppSelector} from "../../../Store/hooks";
import {selectedUser, usersActionLoading} from "../../../Store/Users/UsersSlice";
import {SetRoleUserAsync} from "../../../Api/Users/UsersApi";
import {RoleModel} from "../../../Models/Api/Users/RoleModel";
import {useOutsideAlerter} from "../../../Hooks/ClickOutside";


export const SetRole: FunctionComponent<SetRoleProp> = (props) => {
    const dispatch = useAppDispatch();
    /**
     * User roles select options
     */
    const rolesOptions: Option[] = [
        {label: "Manager", value: UserRoles.BUSINESS_MANAGER},
        {label: "Accountant", value: UserRoles.BUSINESS_ACCOUNTANT},
        {label: "Employee", value: UserRoles.BUSINESS_EMPLOYEE},
    ];

    /**
     * states
     */
    const user = useAppSelector(selectedUser);
    const actionLoading = useAppSelector(usersActionLoading);
    const [roles, setRoles] = useState<Option[]>(rolesOptions.filter(x => user?.roles?.includes(x.label)));
    const [userId, setUserId] = useState<string>(props.id);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        setRoles(rolesOptions.filter(x => user?.roles?.includes(x.label)))
    }, [user])
    /**
     * form submit handler
     * @param e
     */
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (roles.length <= 0) {
            setError(roles.length <= 0);
            return;
        }
        const data: RoleModel = {
            userId: props.id,
            roles: roles.map(r => r.value)
        }
        dispatch(SetRoleUserAsync({roles: data})).then(e => {
            if (e.meta.requestStatus === "fulfilled") {
                props.getData(roles.map(r => r.value), userId);
            }
        });

    }

    const handleOnChange = (options: Option[]) => {
        setRoles(options);
        setError(options.length <= 0);
    }

    let modalRef = useRef(null);
    let outside = useOutsideAlerter(modalRef)
    useEffect(() => {
        if (outside) {
            props.close(UserModalNames.EDIT_ROLE)
        }
    }, [outside])


    /**
     * Template
     */
    return (
        <Modal ref={modalRef} title="set-role-user-model" show={props.show}>
            <Modal.Header className="dialog-header">
                <Modal.Title className="dailog-header_title">
                    Set {props.title} roles
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Group>
                        <Form.Control autoComplete="off" onChange={(e) =>
                            setUserId(e.target.value)}
                                      type="hidden"
                                      value={props.id}
                        >
                        </  Form.Control> </Form.Group>
                    <Form.Group className="form-multi-select w-100">
                        <Form.Label>Role</Form.Label>
                        <MultiSelect
                            className="form-multi-select_input"
                            options={rolesOptions}
                            value={roles}
                            onChange={handleOnChange}
                            labelledBy="Select"
                        />
                        {error && <p className="errors">Role is mandatory</p>}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="dialog-footer">
                    <Button onClick={() => props.close(UserModalNames.EDIT_ROLE)}
                            className="spare_btn spare_btn_cancle role">
                        Close
                    </Button>
                    <Button type="submit" className="spare_btn spare_btn_primary">
                        Submit
                    </Button>
                </Modal.Footer>
                <div className="w-100 ">
                    {actionLoading && <div className="progress">
                        <div className="progress-bar  indeterminate">
                        </div>
                    </div>}
                </div>
            </Form>
        </Modal>
    )
}