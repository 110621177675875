import {PaymentsStatus} from "../Models/Enums/Payments/PaymentsStatus";
import {PaymentsColorStatus} from "../Models/Enums/Payments/PaymentsColorStatus";


/***
 * Get payment status color
 * @param status
 */
export default function GetPaymentStatusText(status: PaymentsStatus = PaymentsStatus.PENDING) {
    switch (status) {
        case PaymentsStatus.AWAITING_AUTHORIZATION:
        case PaymentsStatus.PENDING:
            return "Pending";
        case PaymentsStatus.RECEIVED:
        case PaymentsStatus.COMPLETED:
            return "Paid";
        case PaymentsStatus.REJECTED:
        case PaymentsStatus.CANCELLED:
        case PaymentsStatus.ACCEPTED:
            return "Cancelled";
        default:
            return PaymentsColorStatus.Pending;
    }
}