import React, {FunctionComponent} from "react";
import './PaymentsPage.scss';
import {Outlet} from "react-router-dom";

export const PaymentsPage: FunctionComponent = () => {

    /**
     * Template
     */
    return (
        <div className="payments-page">
            <Outlet/>
        </div>
    )
}