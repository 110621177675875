import './Modal.scss'


function Modal(props:any) {
  return (
    <>
    <div onClick={props.hideModal} className='backdrop'></div>
      <div className='theModal'>
            <div className='content'>{props.children}</div>
        </div>
    </>
  )
}

export default Modal