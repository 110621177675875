import {FunctionComponent, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {useFormik} from "formik";
import * as Yup from "yup";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2'
import {BusinessAccountModel} from "../../../Models/Api/Settings/BusinesAccountModel";
import {AccountFormProp} from "../../../Models/Props/Settings/AccountFormProp";
import {useAppDispatch} from "../../../Store/hooks";
import {UpdateBusinessAccountAsync} from "../../../Api/Settings/SettingsApi";


export const AccountForm: FunctionComponent<AccountFormProp> = (props) => {
    const dispatch = useAppDispatch();
    /**
     * State
     */
    const [phoneNumber, setPhoneNumber] = useState<string>(props.data?.businessPhoneNumber ?? "");
    const [phoneNumberDirty, setPhoneNumberDirty] = useState(false);

    /**
     * Form submit
     * @param values
     * @constructor
     */
    const Submit = (values: BusinessAccountModel) => {
        if (phoneNumber === "") {
            formBuilder.errors.businessPhoneNumber = "Phone number is mandatory";
            return;
        }
        values = {
            ...values,
            businessPhoneNumber: phoneNumber,
        }
        dispatch(UpdateBusinessAccountAsync({model: values})).then(e => {
            if (e.meta.requestStatus === "fulfilled") {
                props.getData(values);
            }
        })

    }


    /**
     * form builder
     */
    const formBuilder = useFormik({
        initialValues: props.data ? props.data : {
            registrationNumber: undefined,
            businessName: "",
            sector: "",
            businessWebSite: "",
            businessPhoneNumber: "",
            contactEmail: "",
        },
        validationSchema: Yup.object().shape({
            registrationNumber: Yup.string().required("Registration number name is mandatory"),
            businessName: Yup.string().required("Business name is mandatory"),
            sector: Yup.string().required("Sector is mandatory"),
            businessWebSite: Yup.string().required("Business website is mandatory"),
            businessPhoneNumber: Yup.string(),
            contactEmail: Yup.string().required("Contact email is mandatory"),
        }),
        onSubmit: Submit
    })

    /**
     * Template
     */
    return (
        <Form onSubmit={formBuilder.handleSubmit}>
            <Form.Group>
                <Form.Label>Registration number</Form.Label>
                <Form.Control     autoComplete="off"   
                    name="registrationNumber"
                    value={formBuilder.values.registrationNumber}
                    onChange={formBuilder.handleChange}
                    onBlur={formBuilder.handleBlur}
                    type="number"/>
                {formBuilder.errors.registrationNumber && formBuilder.touched.registrationNumber &&
                    <p className="errors">{formBuilder.errors.registrationNumber}</p>}
            </Form.Group>
            <Form.Group>
                <Form.Label>Business name</Form.Label>
                <Form.Control     autoComplete="off"   
                    name="businessName"
                    value={formBuilder.values.businessName}
                    onChange={formBuilder.handleChange}
                    onBlur={formBuilder.handleBlur}
                    type="text"/>
                {formBuilder.errors.businessName && formBuilder.touched.businessName &&
                    <p className="errors">{formBuilder.errors.businessName}</p>}
            </Form.Group>
            <Form.Group>
                <Form.Label>Sector</Form.Label>
                <Form.Select
                    name="sector"
                    value={formBuilder.values.sector}
                    onBlur={formBuilder.handleBlur}
                    onChange={formBuilder.handleChange}
                >
                    {props.categories.map(category => (
                        <option key={category.id} value={category.name}>{category.name}</option>
                    ))}
                </Form.Select>
                {formBuilder.errors.sector && formBuilder.touched.sector &&
                    <p className="errors">{formBuilder.errors.sector}</p>}
            </Form.Group>
            <Form.Group>
                <Form.Label>Website</Form.Label>
                <Form.Control     autoComplete="off"   
                    name="businessWebSite"
                    value={formBuilder.values.businessWebSite}
                    onChange={formBuilder.handleChange}
                    onBlur={formBuilder.handleBlur}
                    type="text"/>
                {formBuilder.errors.businessWebSite && formBuilder.touched.businessWebSite &&
                    <p className="errors">{formBuilder.errors.businessWebSite}</p>}
            </Form.Group>
            <Form.Group>
                <Form.Label>phone number</Form.Label>
                <PhoneInput
                    inputProps={{
                        name: 'phone',
                    }}
                    inputClass="form-control"
                    country={'bh'}
                    value={phoneNumber}
                    onChange={(phone) => {
                        setPhoneNumber(phone);
                        setPhoneNumberDirty(true);
                    }}
                />
                {phoneNumber === "" && phoneNumberDirty &&
                    <p className="errors">{formBuilder.errors.businessPhoneNumber}</p>}
            </Form.Group>
            <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control     autoComplete="off"   
                    name="contactEmail"
                    value={formBuilder.values.contactEmail}
                    onChange={formBuilder.handleChange}
                    onBlur={formBuilder.handleBlur}
                    type="text"/>
                {formBuilder.errors.contactEmail && formBuilder.touched.contactEmail &&
                    <p className="errors">{formBuilder.errors.contactEmail}</p>}
            </Form.Group>
            <div className="dialog-footer mt-5">
                <Button type="submit" ref={props.submitRef} style={{display: 'none'}}
                        className="spare_btn spare_btn_action">Submit</Button>
            </div>
        </Form>
    )
}