export enum FdActionNames {
    ListRulesAction = "fd/ListRules",
    GetRuleAction = "fd/GetRule",
    AddRuleAction = "fd/AddRule",
    UpdateRulesAction = "fd/UpdateRule",
    DeleteRulesAction = "fd/DeleteRule",
    ListBlackListAction = "fd/BlackList",
    AddBlackListAction = "fd/AddBlackList",
    GetBlackListAction = "fd/GetBlackList",
    UpdateBlackListAction = "fd/UpdateBlackList",
    DeleteBlackListAction = "fd/DeleteBlackList",
}