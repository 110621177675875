import {DashboardState} from "../../Models/Store/dashboardState";
import {LastPaymentsModel} from "../../Models/Api/Dashboard/LastPaymentsModel";
import {DailyPaymentsCountModel} from "../../Models/Api/Dashboard/DailyPaymentsCountModel";
import {TotalSalesItemModel} from "../../Models/Api/Dashboard/TotalSalesItemModel";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FetchAsyncLastPayments, FetchAsyncTotalPayments, FetchAsyncTotalSales} from "../../Api/Dashboard/dashboardApi";
import {ServerResponse} from "../../Models/Api/ServerResponse";
import {RootState} from "../store";
import {TotalPaymentsModel} from "../../Models/Api/Dashboard/TotalPaymentsModel";
import {TotalSalesModel} from "../../Models/Api/Dashboard/TotalSalesModel";
import {MapTotalPaymentToChartData, MapTotalSalesToChartData} from "../../Hooks/mapToChartData";

const initialState: DashboardState = {
    lastPayments: new Array<LastPaymentsModel>(),
    totalPayments: {
        dailyPayments: new Array<DailyPaymentsCountModel>(),
        paymentCount: 0,
    },
    totalSales: {
        totalSalesAmount: 0,
        totalSales: new Array<TotalSalesItemModel>(),

    },
    paymentsActionLoading: false,
    salesActionLoading: false,
    lastPaymentLoading: false,
    error: '',
}

/***
 * Dashboard store slice
 */
export const dashboardSlice = createSlice({
    initialState,
    name: 'dashboard',
    reducers: {},
    extraReducers: (builder) => {
        /***
         * Last payment pending action
         */
        builder.addCase(FetchAsyncLastPayments.pending, (state) => {
            state.lastPaymentLoading = true
        });

        /**
         * Last payment fulfilled action
         */
        builder.addCase(FetchAsyncLastPayments.fulfilled,
            (state,
             action: PayloadAction<ServerResponse<LastPaymentsModel[]>>) => {
                state.lastPayments = action.payload.data;
                state.lastPaymentLoading = false;
            });

        /**
         * Last payment fulfilled action
         */
        builder.addCase(FetchAsyncLastPayments.rejected,
            (state,
            ) => {
                state.error = "Failed to get last payments";
                state.lastPaymentLoading = false;
            });

        /**
         * Total payments actions
         */
        builder.addCase(FetchAsyncTotalPayments.pending, (state) => {
            state.paymentsActionLoading = true;
        })
        builder.addCase(FetchAsyncTotalPayments.fulfilled,
            (state, action: PayloadAction<ServerResponse<TotalPaymentsModel>>) => {
                state.paymentsActionLoading = false;
                state.totalPayments = action.payload.data;
                state.totalPayments.chartData = MapTotalPaymentToChartData(state.totalPayments.dailyPayments);
            });
        builder.addCase(FetchAsyncTotalPayments.rejected, (state) => {
            state.paymentsActionLoading = false;
        });

        /**
         * Total sales actions
         */
        builder.addCase(FetchAsyncTotalSales.pending, (state) => {
            state.salesActionLoading = true;
        })
        builder.addCase(FetchAsyncTotalSales.fulfilled,
            (state, action: PayloadAction<ServerResponse<TotalSalesModel>>) => {
                state.salesActionLoading = false;
                state.totalSales = action.payload.data;
                state.totalSales.chartData = MapTotalSalesToChartData(state.totalSales.totalSales);
            });
        builder.addCase(FetchAsyncTotalSales.rejected, (state) => {
            state.salesActionLoading = false;
        });


    }
})

/*
Selectors
 */
export const dashboardLastPayment = (state: RootState) => state.dashboard.lastPayments;
export const dashboardTotalPayments = (state: RootState) => state.dashboard.totalPayments;
export const dashboardTotalSales = (state: RootState) => state.dashboard.totalSales;
export const dashboardSalesLoading = (state: RootState) => state.dashboard.salesActionLoading;
export const dashboardPaymentsLoading = (state: RootState) => state.dashboard.paymentsActionLoading;