import {FunctionComponent} from "react";
import {Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {InvoiceItemProp} from "../../../Models/Props/Invoice/InvoiceItemProp";
import "./InvoiceItem.scss"

export const InvoiceItem: FunctionComponent<InvoiceItemProp> = (props) => {

    /**
     * Template
     */
    return (
        <tr className="item-row w-100">
            <td>
                <Form.Group className="add_body_item name_item">
                    <Form.Control     autoComplete="off"   
                        type="text"
                        name={`${props.name}.name`}
                        onChange={props.form.handleChange}
                        value={props.form.values.items[props.index].name}/>
                </Form.Group>
            </td>
            <td>
                <Form.Group className="add_body_item description_item">
                    <Form.Control     autoComplete="off"   
                        name={`${props.name}.description`}
                        onChange={props.form.handleChange}
                        value={props.form.values.items[props.index].description}
                        as="textarea"/>
                </Form.Group>
            </td>
            <td>
                <Form.Group className="add_body_item small-item unit_item">
                    <Form.Control     autoComplete="off"   
                        name={`${props.name}.unit`}
                        onChange={props.form.handleChange}
                        value={props.form.values.items[props.index].unit}
                        type="number"/>
                </Form.Group>
            </td>
            <td>
                <Form.Group className="add_body_item small-item price_item">
                    <Form.Control     autoComplete="off"   
                        name={`${props.name}.price`}
                        onChange={props.form.handleChange}
                        value={props.form.values.items[props.index].price}
                        type="number"/> <span>BHD</span>
                </Form.Group>
            </td>
            <td>
                <Form.Group className="add_body_item small-item tax_item">
                    <Form.Control     autoComplete="off"   
                        name={`${props.name}.tax`}
                        onChange={props.form.handleChange}
                        value={props.form.values.items[props.index].tax}
                        type="number"/> <span>%</span>
                </Form.Group>
            </td>
            <td>
                <div className="ml-auto" onClick={() => props.delete()}>
                    <FontAwesomeIcon icon={faTrash}/>
                </div>
            </td>
        </tr>
    )
}