import {FunctionComponent} from "react";
import "./CreatePayment.scss";
import {Button, Form} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {AppRoutes} from "../../../Models/Enums/AppRoutes";
import {useFormik} from "formik";
import {PaymentCreateModel} from "../../../Models/Api/Payments/PaymentCreateModel";
import * as Yup from "yup";
import {useAppDispatch} from "../../../Store/hooks";
import {CreatePaymentAsync} from "../../../Api/Payments/PaymentsApi";

export const CreatePayment: FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    /**
     * Cancel action
     * @constructor
     */
    const Cancel = () => {
        navigate(AppRoutes.PAYMENTS);
    }

    /**
     * Submit
     * @param values
     * @constructor
     */
    const Submit = (values: PaymentCreateModel) => {
        dispatch(CreatePaymentAsync({model: values})).then((e) => {
            if (e.meta.requestStatus === "fulfilled") {
                navigate(AppRoutes.PAYMENTS);
            }
        });
    }

    /**
     * Form builder
     */
    const formBuilder = useFormik<PaymentCreateModel>({
        initialValues: {
            amount: 0,
            description: "",
        },
        validationSchema: Yup.object().shape({
            amount: Yup.number().required("Amount is mandatory"),
            description: Yup.string().required("Description is mandatory"),
        }),
        onSubmit: Submit
    });

    /**
     * Template
     */
    return (
        <div className="create-payment">
            <div className="create-payment_card">
                <div className="create-payment_card_header">
                    <h1>CREATE PAYMENT</h1>
                </div>
                <div className="create-payment_card_body">
                    <Form onSubmit={formBuilder.handleSubmit}>
                        <Form.Group className="form-group">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control
                                autoComplete="off"
                                name="amount"
                                value={formBuilder.values.amount}
                                onChange={formBuilder.handleChange}
                                type="number"/>
                            <p className="errors">{formBuilder.errors.amount}</p>
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                autoComplete="off"
                                name="description"
                                value={formBuilder.values.description}
                                onChange={formBuilder.handleChange}
                                as="textarea"/>
                            <p className="errors">{formBuilder.errors.description}</p>
                        </Form.Group>
                        <div className="create-payment_card_footer">
                            <Button onClick={() => Cancel()} className="spare_btn spare_btn_cancle">
                                Cancel
                            </Button>
                            <Button type="submit" className="spare_btn spare_btn_primary">
                                Submit
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}