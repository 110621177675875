import React, { FunctionComponent, useEffect, useState } from "react";
import "./PaymentsIndex.scss";
import logoEmpty from "../../../assets/Images/empty-state.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins, faRedo} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import SButton from "../../UI/Button/Button";
import { TablePayments } from "../TablePayments/TablePayments";
import { DateObject } from "../../../Models/Props/Datepicker/DateObject";
import { Datepicker } from "../../Datepicker/Datepicker";
import { PaymentsColorStatus } from "../../../Models/Enums/Payments/PaymentsColorStatus";
import { paymentMenu, paymentsColumns } from "../../../Mocks/paymentsMock";
import { useNavigate } from "react-router-dom";
import { PaymentsRoutes } from "../../../Models/Enums/Routes/PaymentsRoutes";
import { AppRoutes } from "../../../Models/Enums/AppRoutes";
import { paymentActionLoading, paymentCount, paymentFilter, paymentList$ } from "../../../Store/Payment/PaymentSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
    CsvPaymentExportAsync,
    ExcelPaymentExportAsync,
    ListPaymentAsync,
    PdfPaymentExportAsync
} from "../../../Api/Payments/PaymentsApi";
import SearchInput from "../../UI/SearchInput/SearchInput";
import {PaymentsStatus} from "../../../Models/Enums/Payments/PaymentsStatus";
import {MovementSortEnum} from "../../../Models/Enums/Payments/MovementSortEnum";
import {PaginationComponent} from "../../Pagination/PaginationComponent";
import LoadingTable from "../../UI/LoadingTable/LoadingTable";


export const PaymentsIndex: FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    /**
     * State
     */
    const [filter, setFilter] = useState(useAppSelector(paymentFilter));
    const paymentList = useAppSelector(paymentList$);
    const loading = useAppSelector(paymentActionLoading);
    const total = useAppSelector(paymentCount);

    useEffect(() => {
        const promise = dispatch(ListPaymentAsync({ filter }));

        return () => {
            promise.abort();
        }
    }, [filter, dispatch])

    /**
     * Get date range
     * @param date
     */
    const getDateRange = (date: DateObject) => {
        setFilter({
            ...filter,
            fromDate: date.startDate,
            toDate: date.endDate
        });
    }

    const paymentsActionHandler = (action: string) => {
        return action;
    }

    const createPayment = () => {
        navigate(`${AppRoutes.PAYMENTS}/${PaymentsRoutes.CREATE_PAYMENT}`);
    }

    const setPaymentStatus = (status: PaymentsStatus | null) => {
        setFilter({
            ...filter,
            paymentStatus: status,
        });
    }

    const setPaymentSearch = (keyword: string) => {
        setFilter({
            ...filter,
            keyword: keyword
        });
    }

    const resetFilter = () => {
        setFilter({
            keyword: "",
            paymentStatus: null,
            toDate: "",
            fromDate: "",
            sortType: false,
            sortBy: MovementSortEnum.MOST_RECENT,
            start: 0,
            perPage: 30,
        })
    }

    const exportPdf = () => {
        let model = Object.assign({}, filter)
        model = {
            ...model,
            perPage: 30 * (filter.start === 0 ? 1 : filter.start),
        }
        dispatch(PdfPaymentExportAsync({ model }));
    }

    const exportCSV = () => {
        let model = Object.assign({}, filter)
        model = {
            ...model,
            perPage: total,
        }
        dispatch(CsvPaymentExportAsync({ model }));
    }

    const exportExcel = () => {
        let model = Object.assign({}, filter)
        model = {
            ...model,
            perPage: total,
        }
        dispatch(ExcelPaymentExportAsync({ model }));
    }

    const paginationHandler = (page: number, index: number) => {
        setFilter({
            ...filter,
            start: index * 30,
        });
    }


    /**
     * Template
     */
    return (
        <div className="payments">
            <div className="payments_payment-card">
                <div className="payments_header">
                    <div className="card-header_title">
                        <FontAwesomeIcon icon={faCoins} />
                        <h4 className="payments_header_title_text">Payments</h4>
                    </div>
                    <div className="payments_header_action">

                        <SearchInput value={filter.keyword} callBack={setPaymentSearch} />
                        <div className="d-flex gap-2 actions">
                            <Dropdown  className="spare-dropdown">
                                <Dropdown.Toggle 
                                >
                                    Export
                                </Dropdown.Toggle>
                                <Dropdown.Menu >
                                    <Dropdown.Item onClick={exportPdf} >PDF</Dropdown.Item>
                                    <Dropdown.Item onClick={exportCSV} >CSV</Dropdown.Item>
                                    <Dropdown.Item onClick={exportExcel}>EXCEL</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <SButton label="Create payment" onClick={createPayment} />
                          
                            
                        </div>

                    </div>
                </div>
                <div className="payments_body">
                    <div className="payments_body_filter">
                        <div className="payments_body_filter_date-picker">
                            <div className="cursor" onClick={resetFilter}>
                                <FontAwesomeIcon className="refresh_icn" icon={faRedo} />
                            </div>
                            <Datepicker getDate={getDateRange} />
                        </div>

                        <div className="payments_body_filter_status">
                            <div onClick={() => setPaymentStatus(null)}
                                className="payments_body_filter_status_item cursor">
                                <div className="payment-status-dot"
                                    style={{ 'backgroundColor': PaymentsColorStatus.All }}>
                                </div>
                                All
                            </div>
                            <div onClick={() => setPaymentStatus(PaymentsStatus.AWAITING_AUTHORIZATION)}
                                className="payments_body_filter_status_item cursor">
                                <div className="payment-status-dot"
                                    style={{ 'backgroundColor': PaymentsColorStatus.Pending }}>
                                </div>
                                Pending
                            </div>
                            <div onClick={() => setPaymentStatus(PaymentsStatus.COMPLETED)}
                                className="payments_body_filter_status_item cursor">
                                <div className="payment-status-dot"
                                    style={{ 'backgroundColor': PaymentsColorStatus.Paid }}>
                                </div>
                                Paid
                            </div>
                            <div onClick={() => setPaymentStatus(PaymentsStatus.REJECTED)}
                                className="payments_body_filter_status_item cursor">
                                <div className="payment-status-dot"
                                    style={{ 'backgroundColor': PaymentsColorStatus.Canceled }}>
                                </div>
                                Canceled
                            </div>
                        </div>
                    </div>
                    {!loading &&
                    <div className="payments_body_table">
                        <TablePayments loading={loading} columns={paymentsColumns} data={paymentList}
                            menu={paymentMenu}
                            getAction={paymentsActionHandler} />
                        <PaginationComponent onPageChange={paginationHandler} activePage={1} pageSize={30}
                                             totalCount={total}/>
                    </div>}

                    {
                        loading &&
                        <LoadingTable />
                    }

                    {
                        !loading && paymentList.length ===0  &&
                       
                            <div className="empty-state">
                                <img alt="empty-logo" src={logoEmpty} />
                            </div>
                    }

                </div>

            </div>

        </div>
    );

}