import {StoreKeys} from "./StoreKeys";
import * as CryptoJS from 'crypto-js';


export class SecureStore {
    private static masterKey = process.env.REACT_APP_MASTER_KEY;

    static getItem(key: StoreKeys, isEncrypted: boolean = false) {
        if (isEncrypted) {
            return this.fetchAndDecrypt(key);
        }
        return window.localStorage.getItem(key);
    }

    /**
     * set item
     * @param key
     * @param data
     * @param isEncrypted
     */
    static setItem(key: StoreKeys, data: string, isEncrypted: boolean = false) {
        if (data === null || data === '') {
            return;
        }
        if (!isEncrypted) {
            window.localStorage.setItem(key, data);
        }
        this.encryptAndPersist(key, data);
    }

    /**
     * Delete item
     * @param key
     */
    static deleteItem(key: StoreKeys) {
        window.localStorage.removeItem(key);
    }


    /**
     * check if item exists
     * @param key
     */
    static isExists(key: StoreKeys): boolean {
        return !!window.localStorage.getItem(key);
    }

    /**
     * encrypt and persist data
     * @param key
     * @param data
     * @private
     */
    private static encryptAndPersist(
        key: StoreKeys,
        data: string,
    ) {
        if (data !== null && data !== '' && this.masterKey) {
            try {
                const encrypted = CryptoJS.AES.encrypt(
                    CryptoJS.enc.Utf8.parse(data.toString()),
                    CryptoJS.enc.Utf8.parse(this.masterKey),
                    {
                        keySize: 256 / 8,
                        iv: CryptoJS.enc.Utf8.parse(this.masterKey),
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7,
                    },
                );
                window.localStorage.setItem(key, encrypted.toString());
            } catch (error) {
                this.ClearStorage();
            }
        }
    }

    /**
     * Fetch and decrypt data
     * @param key
     * @private
     */
    private static fetchAndDecrypt(key: StoreKeys) {
        const value = localStorage.getItem(key);

        if (value === null || value === '') {
            return '';
        }
        if (!this.masterKey) {
            return '';
        }
        try {
            const decrypted = CryptoJS.AES.decrypt(
                value,
                CryptoJS.enc.Utf8.parse(this.masterKey),
                {
                    keySize: 256 / 8,
                    iv: CryptoJS.enc.Utf8.parse(this.masterKey),
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7,
                },
            );
            return decrypted.toString(CryptoJS.enc.Utf8);
        } catch (error) {
            this.ClearStorage();
        }

    }

    /**
     * Clear Storage
     */
    private static async ClearStorage() {
        const keys = await window.caches.keys();
        await Promise.all(keys.map((key) => caches.delete(key)));
    }
}