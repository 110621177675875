import React, {FunctionComponent} from "react";
import "./SettingIndex.scss";
import {Navigation} from "../Navigation/Navigation";
import {Outlet} from "react-router-dom";

export const SettingIndex: FunctionComponent = () => {

    return (<div className="settings">
        <div className="settings_card">
            <Navigation/>
            <div>
                <Outlet/>
            </div>
        </div>
    </div>)
}