import {createAsyncThunk} from "@reduxjs/toolkit";
import {LoginResponse} from "../../Models/Api/Login/LoginResponse";
import {LoginModel} from "../../Models/Api/Login/LoginModel";
import {LoginActionNames} from "../../Models/Enums/Login/LoginActionNames";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {LoginRoutes} from "../ApiConstants";
import {ServerResponse} from "../../Models/Api/ServerResponse";
import {EncryptedHeader} from "./encryptedHeader";
import {api} from "../api";
import {SimpleServerResponse} from "../../Models/Api/SimpleServerResponse";
import {ResetPasswordModel} from "../../Models/Api/Login/ResetPasswordModel";
import {ResetPasswordHeadersModel} from "../../Models/Api/Login/ResetPasswordHeadersModel";
import {ForgetPasswordModel} from "../../Models/Api/Login/ForgetPasswordModel";
import {CodeModel} from "../../Models/Api/Login/CodeModel";
import {SecureStore} from "../../Security/Crypto/Storage/SecureStore";
import {StoreKeys} from "../../Security/Crypto/Storage/StoreKeys";
import {CaptchaResponseModel} from "../../Models/Api/Login/CaptchaResponseModel";
import {CaptchaModel} from "../../Models/Api/Login/CaptchaModel";

/**
 * Login
 */
export const LoginAsync =
    createAsyncThunk<ServerResponse<LoginResponse>, { model: LoginModel, headers: EncryptedHeader }>(
        LoginActionNames.SignInAction,
        async (model, {rejectWithValue}) => {
            const config: AxiosRequestConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    nonce: model.headers.nonce,
                    publicKey: model.headers.publicKey,
                    uniqueId: model.headers.uniqueId ?? "",
                }
            }
            try {
                const response: AxiosResponse<ServerResponse<LoginResponse>>
                    = await axios.post(LoginRoutes.SignIn, model.model, config);
                return response.data;
            } catch (err: any) {
                if (!err.response) {
                    throw err
                }
                return rejectWithValue(err.response.data)
            }
        }
    )

/**
 * Refresh
 */
export const RefreshAsync =
    async (data: EncryptedHeader) => {
        const config: AxiosRequestConfig =
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${SecureStore.getItem(StoreKeys.TOKEN, true)}`,
                    Accept: 'application/json',
                    refresh: data.refresh ?? "",
                    nonce: data.nonce,
                    publicKey: data.publicKey,
                    uniqueId: data.uniqueId ?? "",
                }
            };
        await api.get(LoginRoutes.Refresh, config).then((response: AxiosResponse<ServerResponse<LoginResponse>>) => {
            SecureStore.setItem(StoreKeys.TOKEN, response.data?.data.accessToken, true)
            SecureStore.setItem(StoreKeys.REFRESH_TOKEN, response.data?.data?.refreshToken, true)
            SecureStore.setItem(StoreKeys.SESSION_ID, response.data?.data.sessionId, false);
            SecureStore.setItem(StoreKeys.TOKEN_EXPIRED, "false");
            window.location.reload();
        }).catch(() => {
            SecureStore.deleteItem(StoreKeys.TOKEN);
            SecureStore.deleteItem(StoreKeys.REFRESH_TOKEN);
            window.location.reload();
        });
    };


/**
 * Request password reset
 */
export const RequestPasswordResetAsync = createAsyncThunk<SimpleServerResponse, { data: ForgetPasswordModel }>(
    LoginActionNames.requestPasswordResetAction,
    async (data) => {
        const response: AxiosResponse<SimpleServerResponse>
            = await api.put(LoginRoutes.RequestPasswordReset, data.data);
        return response.data;
    }
)

/**
 * Reset password verify
 */
export const ResetPasswordVerifyAsync = createAsyncThunk<AxiosResponse, { data: CodeModel, headers: EncryptedHeader }>(
    LoginActionNames.resetPasswordVerify,
    async (data) => {
        const config: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                nonce: data.headers.nonce,
                publicKey: data.headers.publicKey,
            }
        };
        const response: AxiosResponse
            = await api.post(LoginRoutes.ResetPasswordVerify, data.data, config)
        return response;
    }
)

/**
 * Reset password
 */
export const ResetPasswordAsync = createAsyncThunk<SimpleServerResponse, { data: ResetPasswordModel, headers: ResetPasswordHeadersModel }>(
    LoginActionNames.resetPassword,
    async (data) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                nonce: data.headers.nonce,
                publicKey: data.headers.publicKey,
                token: data.headers.token,
            }
        }
        const response: AxiosResponse =
            await api.patch(LoginRoutes.ResetPassword, data.data, config);
        return response.data;
    }
)


/**
 * Reset password
 */
export const RegisterAsync = createAsyncThunk<ServerResponse<LoginResponse>, { data: FormData, headers: EncryptedHeader }>(
    LoginActionNames.register,
    async (data, {rejectWithValue}) => {
        const config: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
                nonce: data.headers.nonce,
                publicKey: data.headers.publicKey,
                uniqueId: data.headers.uniqueId ?? "",
            }
        }
        try {
            const response: AxiosResponse =
                await api.post(LoginRoutes.Register, data?.data, config);
            if (response?.data) {
                return response?.data;
            }

        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response)
        }
    }
)


/**
 * Captcha verify
 */
export const CaptchaVerify = createAsyncThunk<ServerResponse<CaptchaResponseModel>, { data: CaptchaModel, headers: EncryptedHeader }>(
    LoginActionNames.captcha,
    async (data, {rejectWithValue}) => {
        const config: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                nonce: data.headers.nonce,
                publicKey: data.headers.publicKey,
            }
        }
        const response: AxiosResponse = await axios.post(LoginRoutes.Captcha, data.data, config);
        return response.data;
    }
)


