import {ChartData} from "../../../Models/Props/ChartData";

export const BarChartOptions = (data: ChartData) => {
    if (data) {
        return {
            color: ['#030732', '#4f5170'],
            toolbox: {
                feature: {
                    saveAsImage: {
                        show: true,
                        title: "",
                    }
                },
                left: 5,
                top: 5,
            },
            title: {
                show: data.data.length === 0,
                textStyle: {
                    color: "grey",
                    fontSize: 20
                },
                text: "No data",
                left: "center",
                top: "center"
            },
            tooltip: {
                trigger: 'axis',

                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    data: data.labels,
                    axisLabel: {
                        fontSize: data.fontSize,
                        interval: data.interval,
                    }
                },
            ],
            yAxis: [
                {
                    type: 'value'
                }
            ],
            series: [
                {
                    name: 'Growth',
                    type: 'bar',
                    smooth: false,
                    showSymbol: false,
                    data: data.data.map((item: any) => parseFloat(item).toFixed(3)),
                    lineStyle: {
                        normal: {
                            width: 3,
                            color: "#00a4a0",
                        },
                    },
                },
            ]
        };
    }

};

export const HBarChartOptions = (data?: ChartData) => {
    if (data) {
        return {
            color: ['#4f5170', '#030732'],
            toolbox: {
                feature: {
                    saveAsImage: {
                        show: true,
                        title: "",
                    }
                },
                left: 5,
                top: 5,
            },
            title: {
                show: data.data.length === 0,
                textStyle: {
                    color: "grey",
                    fontSize: 20
                },
                text: "No data",
                left: "center",
                top: "center"
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            xAxis: {
                max: 'dataMax',
                show: data.data.length,
            },
            yAxis: {
                type: 'category',
                data: data.labels,
                inverse: true,
                show: data.data.length,
            },
            series: [{
                name: 'Location',
                type: 'bar',
                data: data.data,
                label: {
                    show: true,
                    position: 'right',
                }
            }],
            legend: {
                show: true
            },
        };
    }

}