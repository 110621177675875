import React, {FunctionComponent, useEffect, useState} from "react";
import {TableInvoiceProp} from "../../../Models/Props/Invoice/TableInvoiceProp";
import useWindowDimensions from "../../../Hooks/windowsDimensions";
import {Table} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import "./InvoiceTable.scss";
import {InvoiceMenuNames} from "../../../Models/Enums/Invoice/InvoiceMenuNames";
import {useNavigate} from "react-router-dom";
import {AppRoutes} from "../../../Models/Enums/AppRoutes";
import {InvoiceRoutes} from "../../../Models/Enums/Routes/InvoiceRoutes";
import LoadingTable from "../../UI/LoadingTable/LoadingTable";
import NoData from "../../UI/NoData/NoData";

export const InvoiceTable: FunctionComponent<TableInvoiceProp> = (props) => {
    const {width} = useWindowDimensions();
    const navigate = useNavigate();
    /**
     * State
     */
    const [mobile, setMobile] = useState(width <= 1024);

    useEffect(() => {
        setMobile(width <= 1024);
    }, [width])

    const invoiceInfo = (id?: string) => {
        if (id) navigate(`${AppRoutes.Invoice}/${InvoiceRoutes.INVOICE_INFO}/${id}`)
    }

    return (
        <>
      { !props.loading &&  <div className="invoice_table">
            <Table striped={!mobile} className="table-responsive">
                <thead>
                <tr>
                    {props.columns.map(column => (
                        <th key={column}>{column}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {props.data.length > 0 && props.data.map(item => (
                    <tr role="row" key={item.id}>
                        <td onClick={() => invoiceInfo(item.id)}>
                            {item.reference}</td>
                        <td>{item.fullName}</td>
                        <td>{item.dueDate}</td>
                        <td>{item.createdAt} </td>
                        <td>{item.expiryDate}</td>
                        <td>{item.total}</td>
                        {!mobile && <td>
                            <Dropdown>
                                <Dropdown.Toggle role="dropdown" id="dropdown-basic">
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => props.getAction(InvoiceMenuNames.INVOICE_DELETE, item.id)}
                                        className="dropdown-item"><FontAwesomeIcon
                                        className="dropdown-item_icon" icon={faTrashCan}/> Delete invoice
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>}
                        {mobile && <td className="mobile-menu-actions">
                            <Dropdown.Item onClick={() => props.getAction(InvoiceMenuNames.INVOICE_DELETE, item.id)}
                                           className="dropdown-item"><FontAwesomeIcon
                                className="dropdown-item_icon" icon={faTrashCan}/>
                            </Dropdown.Item>
                        </td>}
                    </tr>
                ))
                }

                </tbody>

            </Table>
            {props.data.length <= 0 && !props.loading &&
                <NoData/>
            }
        </div>}
        {
            props.loading&&
            <LoadingTable/>
        }
        </>
    )
}