import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import './App.css';
import {Navbar} from "./Components/Navbar/Navbar";
import {DashboardPage} from "./Pages/Dashboard/DashboardPage";
import {PaymentsPage} from "./Pages/Payments/PaymentsPage";
import {StatisticsPage} from "./Pages/Statistics/StatisticsPage";
import {InvoicePage} from "./Pages/Invoice/InvoicePage";
import {UsersPage} from "./Pages/Users/UsersPage";
import {AppRoutes} from "./Models/Enums/AppRoutes";
import {SettingsPage} from "./Pages/Settings/SettingsPage";
import {PaymentsRoutes} from "./Models/Enums/Routes/PaymentsRoutes";
import {CreatePayment} from "./Components/Payments/CreatePayment/CreatePayment";
import {PaymentsIndex} from "./Components/Payments/PaymentsIndex/PaymentsIndex";
import {PaymentDetail} from "./Components/Payments/PaymentDetail/PaymentDetail";
import {UsersIndex} from "./Components/Users/UsersIndex/UsersIndex";
import {UsersRoutes} from "./Models/Enums/Routes/UsersRoutes";
import {InvoiceIndex} from "./Components/Invoice/InvoiceIndex/InvoiceIndex";
import {InvoiceRoutes} from "./Models/Enums/Routes/InvoiceRoutes";
import {AddInvoice} from "./Components/Invoice/AddInvoice/AddInvoice";
import {SettingsRoutes} from "./Models/Enums/Routes/SettingsRoutes";
import {Account} from "./Components/Settings/Account/Account";
import {Profile} from "./Components/Settings/Profile/Profile";
import {Security} from "./Components/Settings/Security/Security";
import {CustomerInformation} from "./Components/Settings/CustomerInformation/CustomerInformation";
import {CallBacks} from "./Components/Settings/CallBacks/CallBacks";
import {ApiKeys} from "./Components/Settings/ApiKeys/ApiKeys";
import {Rules} from "./Components/Settings/Rules/Rules";
import {BlackList} from "./Components/Settings/BlackList/BlackList";
import {Login} from "./Components/Login/Login";
import {ForgetPassword} from "./Components/Login/ForgetPassword";
import {ResetPasswordConfirmation} from "./Components/Login/ResetPasswordConfirmation";
import {ResetPasswordSuccess} from "./Components/Login/ResetPasswordSuccess";
import {ResetPassword} from "./Components/Login/ResetPassword";
import {InvoiceInfo} from "./Components/Invoice/InvoiceInfo/InvoiceInfo";
import {SecureStore} from "./Security/Crypto/Storage/SecureStore";
import {StoreKeys} from "./Security/Crypto/Storage/StoreKeys";
import {useSelector} from "react-redux";
import {loginLoggedIn} from "./Store/Login/LoginSlice";
import {ToastContainer} from "react-toastify";
import {Register} from "./Components/Login/Register";
import {BusinessAccountStatus} from "./Models/Enums/Login/BusinessAccountStatus";
import {Waiting} from "./Components/Waiting/Waiting";
import {CodePassword} from "./Components/Login/CodePassword";
import BankAccount from './Components/Settings/BankAccount/BankAccount';



function App() {
    const [token, setToken] = useState<string | undefined>(SecureStore.getItem(StoreKeys.TOKEN, true) ?? undefined);
    const [status, setStatus] = useState<string | undefined>(SecureStore.getItem(StoreKeys.ACCOUNT_STATUS, true) ?? undefined);
    const loggedIn = useSelector(loginLoggedIn);
    useEffect(() => {
        if (!loggedIn) {
            setToken(undefined);
        } else {
            setToken(SecureStore.getItem(StoreKeys.TOKEN, true) ?? undefined)
            setStatus(SecureStore.getItem(StoreKeys.ACCOUNT_STATUS, true) ?? undefined)
        }
    }, [token, loggedIn])

    if (!token) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path={AppRoutes.LOGIN} element={<Login getToken={setToken}/>}/>
                    <Route path={AppRoutes.FORGET_PASSWORD} element={<ForgetPassword/>}/>
                    <Route path={AppRoutes.RESET_PASSWORD_CONFIRMATION} element={<ResetPasswordConfirmation/>}/>
                    <Route path={AppRoutes.RESET_PASSWORD_SUCCESS} element={<ResetPasswordSuccess/>}/>
                    <Route path={AppRoutes.RESET_PASSWORD} element={<ResetPassword/>}/>
                    <Route path={AppRoutes.CODE_PASSWORD} element={<CodePassword/>}/>
                    <Route path={AppRoutes.REGISTER} element={<Register/>}/>
                </Routes>
                <ToastContainer/>
            </BrowserRouter>
        )
    }

    if (status === BusinessAccountStatus.UNVERIFIED || status === "undefined") {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path={AppRoutes.Waiting} element={<Waiting/>}/>
                </Routes>
            </BrowserRouter>
        )
    }

    /**
     * Template
     */
    return (
        <BrowserRouter>
            <div className="App">
                <Navbar/>
                <Routes>
                    <Route path={AppRoutes.DASHBOARD} element={<DashboardPage/>}/>
                    <Route path={AppRoutes.PAYMENTS} element={<PaymentsPage/>}>
                        <Route path={PaymentsRoutes.INDEX} element={<PaymentsIndex/>}/>
                        <Route path={PaymentsRoutes.CREATE_PAYMENT} element={<CreatePayment/>}/>
                        <Route path={`${PaymentsRoutes.PAYMENT_DETAIL}/:id`} element={<PaymentDetail/>}/>
                    </Route>
                    <Route path={AppRoutes.Statistics} element={<StatisticsPage/>}/>
                    <Route path={AppRoutes.Invoice} element={<InvoicePage/>}>
                        <Route path={InvoiceRoutes.INDEX} element={<InvoiceIndex/>}/>
                        <Route path={InvoiceRoutes.CREATE_INVOICE} element={<AddInvoice/>}/>
                        <Route path={`${InvoiceRoutes.INVOICE_INFO}/:id`} element={<InvoiceInfo/>}/>
                    </Route>
                    <Route path={AppRoutes.Users} element={<UsersPage/>}>
                        <Route path={UsersRoutes.INDEX} element={<UsersIndex/>}/>
                    </Route>
                    <Route path={AppRoutes.Settings} element={<SettingsPage/>}>
                        <Route path={SettingsRoutes.INDEX} element={<Account/>}/>
                        <Route path={SettingsRoutes.PROFILE} element={<Profile/>}/>
                        <Route path={SettingsRoutes.SECURITY} element={<Security/>}/>
                        <Route path={SettingsRoutes.KEYS} element={<ApiKeys/>}/>
                        <Route path={SettingsRoutes.CUSTOMER_INFORMATION} element={<CustomerInformation/>}/>
                        <Route path={SettingsRoutes.CALLBACKS} element={<CallBacks/>}/>
                        <Route path={SettingsRoutes.FM_RULES} element={<Rules/>}/>
                        <Route path={SettingsRoutes.BLACK_LIST} element={<BlackList/>}/>
                        <Route path={SettingsRoutes.BANK_ACCOUNT} element={<BankAccount/>}/>
                    </Route>
                </Routes>
                <ToastContainer/>
            </div>
        </BrowserRouter>

    );
}

export default App;
