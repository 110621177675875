import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    getAverageOrders, getPaymentAnnualGrowth,
    getPaymentByGrowth,
    getPaymentByLocation,
    getPaymentByStatus
} from "../../Api/Statisstics/StatisticsApi";
import {StatisticsSlice} from "../../Models/Store/Statistics/StatisticsSlice";
import {AverageOrderModel} from "../../Models/Api/Statistics/AverageOrderModel";
import {PaymentByLocationModel} from "../../Models/Api/Statistics/PaymentByLocationModel";
import {PaymentStatusModel} from "../../Models/Api/Statistics/PaymentStatusModel";
import {PaymentByGrowthModel} from "../../Models/Api/Statistics/PyamentByGrowthModel";
import {AverageOrderResponseModel} from "../../Models/Api/Statistics/AverageOrderResponseModel";
import {ServerResponse} from "../../Models/Api/ServerResponse";
import {
    mapAverageOrdersToCharData, mapMonthlyGrowthToChartData,
    mapPaymentByLocationToCharData,
    mapPaymentByStatusToChartDate
} from "./StatisticsMapChartData";
import {RootState} from "../store";


const initialState: StatisticsSlice = {
    averageOrders: {
        averageOrders: new Array<AverageOrderModel>(),
        averageOrderValue: 0,
    },
    averageOrdersLoading: false,
    paymentsByLocation: new Array<PaymentByLocationModel>(),
    paymentsByLocationChartData: null,
    paymentsByLocationLoading: false,
    paymentByStatus: new Array<PaymentStatusModel>(),
    paymentByStatusChartData: null,
    paymentByStatusLoading: false,
    paymentByGrowth: new Array<PaymentByGrowthModel>(),
    paymentByGrowthChartData: null,
    paymentByGrowthLoading: false,
    paymentAnnualGrowth: new Array<PaymentByGrowthModel>(),
    paymentAnnualGrowthChartData: null,
    paymentAnnualGrowthLoading: false,
}


export const statisticsSlice = createSlice({
    initialState,
    name: "statistics",
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAverageOrders.pending, (state) => {
            state.averageOrdersLoading = true;
        });
        builder.addCase(getAverageOrders.fulfilled, (state
            , action: PayloadAction<ServerResponse<AverageOrderResponseModel>>) => {
            state.averageOrdersLoading = false;
            state.averageOrders = action.payload.data;
            state.averageOrders.charData = mapAverageOrdersToCharData(action.payload.data.averageOrders);
        });
        builder.addCase(getAverageOrders.rejected, (state) => {
            state.averageOrdersLoading = false;
        });

        builder.addCase(getPaymentByLocation.pending, (state) => {
            state.paymentsByLocationLoading = true;
        });
        builder.addCase(getPaymentByLocation.fulfilled, (state, action: PayloadAction<ServerResponse<PaymentByLocationModel[]>>) => {
            state.paymentsByLocationLoading = true;
            state.paymentsByLocation = action.payload.data;
            state.paymentsByLocationChartData = mapPaymentByLocationToCharData(action.payload.data);

        });
        builder.addCase(getPaymentByLocation.rejected, (state) => {
            state.paymentsByLocationLoading = false;
        });

        builder.addCase(getPaymentByStatus.pending, (state) => {
            state.paymentByStatusLoading = true;
        });
        builder.addCase(getPaymentByStatus.fulfilled, (state, action: PayloadAction<ServerResponse<PaymentStatusModel[]>>) => {
            state.paymentByStatusLoading = false;
            state.paymentByStatus = action.payload.data;
            state.paymentByStatusChartData = mapPaymentByStatusToChartDate(action.payload.data);

        });
        builder.addCase(getPaymentByStatus.rejected, (state) => {
            state.paymentByStatusLoading = false;
        });

        builder.addCase(getPaymentByGrowth.pending, (state) => {
            state.paymentByGrowthLoading = true;
        });
        builder.addCase(getPaymentByGrowth.fulfilled, (state, action: PayloadAction<ServerResponse<PaymentByGrowthModel[]>>) => {
            state.paymentByGrowthLoading = false;
            state.paymentByGrowth = action.payload.data;
            state.paymentByGrowthChartData = mapMonthlyGrowthToChartData(action.payload.data);
        });
        builder.addCase(getPaymentByGrowth.rejected, (state) => {
            state.paymentByGrowthLoading = false;
        });

        builder.addCase(getPaymentAnnualGrowth.pending, (state) => {
            state.paymentAnnualGrowthLoading = true;
        });
        builder.addCase(getPaymentAnnualGrowth.fulfilled, (state, action: PayloadAction<ServerResponse<PaymentByGrowthModel[]>>) => {
            state.paymentAnnualGrowthLoading = false;
            state.paymentAnnualGrowth = action.payload.data;
            state.paymentAnnualGrowthChartData = mapMonthlyGrowthToChartData(action.payload.data);
        });
        builder.addCase(getPaymentAnnualGrowth.rejected, (state) => {
            state.paymentAnnualGrowthLoading = false;
        });


    }
})

/**
 * Selectors
 */
export const statisticsAverageOrders = (state: RootState) => state.statistics.averageOrders;
export const statisticsAverageOrdersLoading = (state: RootState) => state.statistics.averageOrdersLoading;
export const statisticsPaymentsByLocationChartData = (state: RootState) => state.statistics.paymentsByLocationChartData;
export const statisticsPaymentsByLocationLoading = (state: RootState) => state.statistics.paymentsByLocationLoading;
export const statisticsPaymentByStatusChartData = (state: RootState) => state.statistics.paymentByStatusChartData;
export const statisticsPaymentByStatusLoading = (state: RootState) => state.statistics.paymentByStatusLoading;
export const statisticsPaymentByGrowthChartData = (state: RootState) => state.statistics.paymentByGrowthChartData;
export const statisticsPaymentAnnualGrowthChartData = (state: RootState) => state.statistics.paymentAnnualGrowthChartData;