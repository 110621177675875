import {PaymentModel} from "../Models/Api/Payments/PaymentModel";
import {PaymentsStatus} from "../Models/Enums/Payments/PaymentsStatus";
import {faker} from '@faker-js/faker';

export const paymentsColumns = [
    "Reference",
    "Date",
    "Customer",
    "Amount",
    "Status"
];

export const paymentMenu = ["Payment refunds", "Payment detail"]

export const paymentsData: PaymentModel[] = [
    {
        status: PaymentsStatus.PENDING,
        amount: 4646,
        debtor: {
            fullname: faker.name.firstName(),
            email: faker.internet.email(),
            phone: faker.phone.number(),

        },
        createAt: "Aug 16 2002",
        reference: `#SP-${faker.random.alphaNumeric(5).toUpperCase()}`,
        businessAccount: faker.company.name(),
        currency: "BHD",
        issuer: {
            fullname: faker.name.firstName(),
            email: faker.internet.email(),
            phone: faker.phone.number(),
        },
        name: "payment"
    },
    {
        status: PaymentsStatus.PENDING,
        amount: 4646,
        debtor: {
            fullname: faker.name.firstName(),
            email: faker.internet.email(),
            phone: faker.phone.number(),

        },
        createAt: "Aug 16 2002",
        reference: `#SP-${faker.random.alphaNumeric(5).toUpperCase()}`,
        businessAccount: faker.company.name(),
        currency: "BHD",
        issuer: {
            fullname: faker.name.firstName(),
            email: faker.internet.email(),
            phone: faker.phone.number(),
        },
        name: "payment"
    }
]