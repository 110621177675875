import {FormState} from "../../Models/Store/Form/FormState";
import {DataTypeModel} from "../../Models/Api/Invoice/DataTypeModel";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GetCustomerInformation, ListDataTypeAsync, UpdateCustomerInformation} from "../../Api/Form/FormApi";
import {ServerResponse} from "../../Models/Api/ServerResponse";
import {InvoiceModel} from "../../Models/Api/Invoice/InvoiceModel";
import {RootState} from "../store";
import {showToaster} from "../toaster";


const initialState: FormState = {
    invoice: null,
    actionLoading: false,
    dataTypes: new Array<DataTypeModel>(),
    block: false,
}


export const FormSlice = createSlice({
    initialState,
    name: "form",
    reducers: {
        blockNavigation: (state) => {
            state.block = true;
        },
        unBlockNavigation: (state) => {
            state.block = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ListDataTypeAsync.pending, (_) => {

        });

        builder.addCase(ListDataTypeAsync.fulfilled,
            (state, action: PayloadAction<ServerResponse<DataTypeModel[]>>,) => {
                state.dataTypes = action.payload.data;

            });
        builder.addCase(ListDataTypeAsync.rejected,
            (_) => {
            });

        builder.addCase(GetCustomerInformation.pending, (state) => {
            state.actionLoading = true;
        });

        builder.addCase(GetCustomerInformation.fulfilled,
            (state, action: PayloadAction<ServerResponse<InvoiceModel>>,) => {
                state.invoice = action.payload.data;
                state.actionLoading = false;
            });
        builder.addCase(GetCustomerInformation.rejected,
            (state) => {
                state.actionLoading = false;
            });

        builder.addCase(UpdateCustomerInformation.pending, (state) => {
            state.actionLoading = true;
        });

        builder.addCase(UpdateCustomerInformation.fulfilled,
            (state, action: PayloadAction<ServerResponse<InvoiceModel>>,) => {
                state.invoice = action.payload.data;
                state.actionLoading = false;
                showToaster("Customer information updated successfully!")
            });
        builder.addCase(UpdateCustomerInformation.rejected,
            (state) => {
                state.actionLoading = false;
            });

    }
})

/**
 * Actions
 */
export const {
    blockNavigation,
    unBlockNavigation
} = FormSlice.actions;

/**
 * Selectors
 */
export const formInvoice = (state: RootState) => state.form.invoice;
export const formDataTypes = (state: RootState) => state.form.dataTypes;
export const formActionLoading = (state: RootState) => state.form.actionLoading;
export const formBlockNavigation = (state: RootState) => state.form.block;