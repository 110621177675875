import { faAdd, faGear, faTrash, faWallet, } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useState } from 'react'
import { EncryptedHeader } from '../../../Api/Login/encryptedHeader'
import { AddBankAccountAsync, DeleteBankAccountAsync, GetBankAccountAsync, GetBankProvidersAsync, UpdateBankAccountAsync } from '../../../Api/Settings/SettingsApi'
import { UpdateBankAccountModel } from '../../../Models/Store/Settings/BankAccount'
import { BankProvider } from '../../../Models/Store/Settings/BankProvider'
import { EcdhAesEncryption } from '../../../Security/Crypto/Ecc/EcdhAesEncryption'
import { SecureStore } from '../../../Security/Crypto/Storage/SecureStore'
import { StoreKeys } from '../../../Security/Crypto/Storage/StoreKeys'
import { useAppDispatch, useAppSelector } from '../../../Store/hooks'
import './BankAccount.scss'
import ManageBankAccount from './ManageBankAccount/ManageBankAccount'
import SkeletonLoader from '../../UI/SkeletonLoader/SkeletonLoader'
import Card from '../../UI/Card/Card'
import SButton from '../../UI/Button/Button'



function BankAccount() {

    const [isModal, setIsModal] = useState<boolean>(false);
    const [btnTitle, setBtnTitle] = useState<string>("");
    const [modalTitle, setModalTitle] = useState<string>("");


    const serverKey = process.env.REACT_APP_SERVER_KEY;
    const sessionId = SecureStore.getItem(StoreKeys.SESSION_ID,true);
    
    const dispatch = useAppDispatch();
    const initAccount = useCallback(async () => {
        await dispatch(GetBankAccountAsync());
    }, [dispatch]);
    const initProviders = useCallback(async () => {
        await dispatch(GetBankProvidersAsync());
    }, [dispatch])

    useEffect(() => {
        initAccount();
        initProviders();
    }, [initAccount, initProviders]);


    const account = useAppSelector(state => state.bankAccount.bankAccount);
    const isLoading = useAppSelector(state => state.bankAccount.loadingAccount);
    const providers: BankProvider[] = useAppSelector(state => state.bankAccount.providers);

    
    const showModalHandler = (type: string) => {
        setBtnTitle(type === 'ADD' ? 'Add' : 'Update');
        setModalTitle(type === 'ADD' ? 'Add new bank account' : 'Update your bank account')
        setIsModal(true);
    }
    

    const addAccountHandler = (values: UpdateBankAccountModel) => {
        const identification = values.identification;
        const keys = EcdhAesEncryption.fetchKeys();
        if(serverKey && sessionId){
            const cipher = EcdhAesEncryption.encrypt(
                keys.privateKey,
                serverKey,
                Buffer.from(identification)
            );
            const data:UpdateBankAccountModel = {
                scheme:values.scheme,
                identification: cipher.base64Cipher ?? "",
                providerId: values.providerId,
                currency: values.currency
            };
            const headers: EncryptedHeader = {
                nonce: cipher.base64Nonce ?? "",
                publicKey: Buffer.from(keys.publicKey).toString('base64'),
                sessionId:sessionId
            }
            dispatch(AddBankAccountAsync({data:data,headers:headers}))
            
        }
    }
    const updateAccountHandler = (values: UpdateBankAccountModel) => {
        const identification = values.identification;
        const keys = EcdhAesEncryption.fetchKeys();
        const accountId = account?.id;
        if (serverKey && sessionId ) {
            const cipher = EcdhAesEncryption.encrypt(
                keys.privateKey,
                serverKey,
                Buffer.from(identification)
            );
            const headers: EncryptedHeader = {
                nonce: cipher.base64Nonce ?? "",
                publicKey: Buffer.from(keys.publicKey).toString('base64'),
                sessionId:sessionId
            }
            const data:UpdateBankAccountModel = {
                scheme:values.scheme,
                identification: cipher.base64Cipher ?? "",
                providerId: values.providerId,
                currency: values.currency
            };
            
            dispatch(UpdateBankAccountAsync({data, headers,id: accountId!}))
        }

        


    }
    const deleteAccountHandler = ()=>{
        if(account)
        dispatch(DeleteBankAccountAsync({
            id:account!.id,
            sessionId:sessionId??''
        }))
    }

    const onFormCall = (values: any) => {
        if (btnTitle === 'Add') {
            addAccountHandler(values)
        }
        else {
            updateAccountHandler(values)
        }
    }

    const hideModalHandler = () => {
        setIsModal(false)
    }

    return (
        <>
            
            {isModal && providers &&
                <ManageBankAccount hideModal={hideModalHandler}
                    title={modalTitle}
                    actionTitle={btnTitle}
                    account={account}
                    onAction={onFormCall}
                    providers={providers}
                />
            }

            <div className='bank-account'>

                {
                    !isLoading && 
                    <div className="actions" >
                        
                        { account &&
                            <>
                        <SButton  label='Manage Bank Account' icon={faGear} class="secondary" onClick={showModalHandler.bind(null, "EDIT")}/>
                        <SButton  label='Delete Bank Account' icon={faTrash} class="danger" onClick={deleteAccountHandler}/>

                      
                        </>}
                        
                        
                        { !account &&
                            
                            <SButton label='Add Bank Account' icon={faAdd} onClick={showModalHandler.bind(null, "ADD")}/>
                        }
                        
                    </div>}

                {
                    !isLoading && account &&
                    <Card className='account_card'>
                        <div className='deco'></div>
                        <div className='bank-info'>
                            <img src={account.provider.provider_logo} alt="" />
                            <div className='bank-info_name'>{account.provider.provider_name}</div>
                        </div>

                        <hr />

                        <div className="account-info">
                            <div className='tile'>
                                <span className="title">{account.scheme.toUpperCase()}:</span>
                                <span className="content">{account.identification}</span>
                            </div>
                            <div className='tile'>
                                <span className="title">Currency:</span>
                                <span className="content">{account.currency}</span>
                            </div>
                        </div>

                    </Card>
                    
                   
                }
                {isLoading &&
                   <div className="loading">
                   <div className="loading_img_title">
                           <SkeletonLoader className={'img'} shape='rectangle' width='15rem' height='15rem'/>
                           <SkeletonLoader className={'title'} shape='rectangle' width='35%' height='1.2rem'/>
                   </div>
                   <div className="loading_info">
                   <SkeletonLoader className={'identifier'} shape='rectangle' width='40%' height='1rem'/>
                   <SkeletonLoader className={'currency'} shape='rectangle' width='60%' height='1rem'/>
                   </div>
               </div>
                }

                {!isLoading && !account &&
                    <div className='no-data'>
                        <FontAwesomeIcon className='no-data-icon' icon={faWallet} />
                        <div className='no-data-title'>Please add your bank account</div>
                    </div>
                }
            </div>

        </>

    )
}

export default BankAccount