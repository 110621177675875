import { FunctionComponent, useState } from "react";
import { Table } from "react-bootstrap";
import { ApiKeysTableProp } from "../../../Models/Props/Settings/ApiKeysTableProp";
import { faAdd, faBan, faCheckCircle, faEdit, faSyncAlt, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BusinessApiKeyModel } from "../../../Models/Api/Settings/BusinessApiKeyModel";
import { ApikeyActions } from "../../../Models/Enums/Settings/ApikeyActions";
import { useAppDispatch } from "../../../Store/hooks";
import { ActivateKeyAsync, ListApiKeysAsync, RevokeKeyAsync } from "../../../Api/Settings/SettingsApi";
import Moment from "moment";
import { ApiKeyDelete } from "./ApiKeyDelete";
import { toast } from "react-toastify";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import UpdateAPICallBackUrl from "./UpdateAPICallBackUrl/UpdateAPICallBackUrl";

export const ApiKeysTable: FunctionComponent<ApiKeysTableProp> = (props) => {
    const dispatch = useAppDispatch();
    const [showDelete, setShowDelete] = useState(false);
    const [id, setId] = useState<string>();
    /**
     * Emit selected action
     * @param action
     * @param payload
     * @constructor
     */
    const HandelAction = (action: ApikeyActions, payload: BusinessApiKeyModel) => {
        switch (action) {
            case ApikeyActions.DELETE:
                if (payload?.id) {
                    setShowDelete(true);
                    setId(payload?.id)
                }
                break;
            case ApikeyActions.REVOKE:
                if (payload?.id) dispatch(RevokeKeyAsync({ id: payload?.id }))
                props.getAction(ApikeyActions.REVOKE, payload);
                break;
            case ApikeyActions.ACTIVATE:
                if (payload?.id) dispatch(ActivateKeyAsync({ id: payload?.id }))
                props.getAction(ApikeyActions.ACTIVATE, payload);
                break;
            case ApikeyActions.REGENERATE:
                props.getAction(ApikeyActions.REGENERATE, payload);
                break;
        }
    }

    const deleteAction = () => {
        setShowDelete(false);
        setId(undefined);
        dispatch(ListApiKeysAsync());
    }

    const CloseDeleteModel = () => {
        setShowDelete(false);
    }

    const copyText = async (text?: string) => {
        if (text) {
            await navigator.clipboard.writeText(text);
            toast.success("Key copied", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
        }

    }
    const hideApiKey = () => {
        return "***********************************"
    }
    const hideAppId = (text?: string) => {
        if (text)
            return text.slice(0, Math.ceil(text.length / 2)) + "**************"
    }
    
    const [isModal,setIsModal] = useState<boolean>(false);
    const [passedData,setPassedData] = useState<BusinessApiKeyModel|undefined>()
    const showModalHandler = (value:BusinessApiKeyModel)=>{
        setPassedData(value);
        
        setIsModal(true);
    }
    const hideModalHandler = ()=>{
        setIsModal(false);
    }
    
    
    

    /**
     * Template
     */
    
    return (
        <>
        {isModal && props.data && <UpdateAPICallBackUrl data={passedData!} hideModal={hideModalHandler}/>}
        <Table className="table-responsive" striped>
            <thead>
                <tr>
                    <th>App id</th>
                    <th>App key</th>
                    <th>Callback URL</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {props.data.map(item => (
                    <tr key={item.id}>
                        <td >{hideAppId(item.appId)}
                            <span className="cursor td-padding" onClick={() => copyText(item.appId)}>
                                <FontAwesomeIcon icon={faClipboard} />
                            </span>
                        </td>
                        <td >{hideApiKey()}
                            <span className="cursor td-padding" onClick={() => copyText(item.apiKey)}>
                                <FontAwesomeIcon icon={faClipboard} />
                            </span>
                        </td>
                        <td>{
                            !item.callbackUrl? 
                            <div className="content-callback" onClick={()=>{showModalHandler(item)}}>
                                <span >Add url</span>
                            </div> :
                                <div className="callback-url-content">
                                    <span className="url-content" >{item.callbackUrl}</span>
                                    <span onClick={()=>{showModalHandler(item)}}><FontAwesomeIcon icon={faEdit} className='edit-callback'/></span>
                                </div>
                            }</td>
                     
                        <td>{item.isRevoked ? "Revoked" : "Active"}</td>
                        <td>{Moment(item.createAt).format("MMM D, YYYY")}</td>
                        <td className="apiKeys_table_action cursor">
                            <div onClick={() => HandelAction(ApikeyActions.REGENERATE, item)}><FontAwesomeIcon
                                icon={faSyncAlt} /></div>
                            {item.isRevoked ? <div onClick={() => HandelAction(ApikeyActions.ACTIVATE, item)}><FontAwesomeIcon
                                icon={faCheckCircle} /></div> : <div onClick={() => HandelAction(ApikeyActions.REVOKE, item)}><FontAwesomeIcon
                                    icon={faBan} /></div>

                            }
                            <div onClick={() => HandelAction(ApikeyActions.DELETE, item)}><FontAwesomeIcon
                                icon={faTrashCan} /></div>
                        </td>
                    </tr>
                ))}
            </tbody>
            <ApiKeyDelete show={showDelete} getData={() => deleteAction()} id={id} close={() => CloseDeleteModel()} />
        </Table>
        </>
    )
} 