import {FunctionComponent} from "react";
import {Button, Form} from "react-bootstrap";
import {BlackListType} from "../../../Models/Api/FraudDetection/BlackListType";
import {AddBlackItemProp} from "../../../Models/Props/Settings/BlackList/AddBlackItemProp";
import {useFormik} from "formik";
import {BlackListModel} from "../../../Models/Api/FraudDetection/BlackListModel";
import * as Yup from "yup";
import {FormikErrors} from "formik/dist/types";
import {useAppDispatch} from "../../../Store/hooks";
import {AddBlackListAsync, UpdateBlackListAsync} from "../../../Api/Fraudmonitoring/FraudMonitoringApi";
import SButton from "../../UI/Button/Button";

export const AddBlackItem: FunctionComponent<AddBlackItemProp> = (props) => {
    const dispatch = useAppDispatch();
    /**
     * Submit form
     * @param values
     * @constructor
     */
    const Submit = (values: BlackListModel) => {
        if (props.editing) {
            if (values && values.id) dispatch(UpdateBlackListAsync({model: values, id: values?.id})).then(e => {
                if (e.meta.requestStatus === "fulfilled") {
                    props.getData(values);
                }
            })
        } else {
            dispatch(AddBlackListAsync({model: values})).then(e => {
                if (e.meta.requestStatus === "fulfilled") {
                    props.getData(values);
                }
            })
        }
    }

    /**
     * Get form errors
     * @param errors
     */
    const getErrors = (errors: FormikErrors<BlackListModel>): string[] => {
        let errorMessages: string[] = [];
        if (errors.value) {
            errorMessages = [...errorMessages, errors.value]
        }
        return errorMessages;
    }

    /**
     * form builder
     */
    const formBuilder = useFormik({
        initialValues: props.data ? props.data :
            {
                id: "",
                value: "",
                type: BlackListType.DEBTOR_EMAIL,
                identifier: 0,
            },
        validationSchema: Yup.object().shape({
            value: Yup.string().required("Black list value is mandatory"),
            type: Yup.string().required("Black list type is mandatory")
        }),
        onSubmit: Submit,
    })

    /**
     * Template
     */
    return (
        <Form onSubmit={formBuilder.handleSubmit}>
            <div className="blackList-box-wrapper">
                <div className="blackList-box">
                    <Form.Control     autoComplete="off"    name="id" type="hidden"/>
                    <Form.Select
                        onChange={formBuilder.handleChange}
                        value={formBuilder.values.type}
                        name="type">
                        <option value={BlackListType.DEBTOR_EMAIL}>Email</option>
                        <option value={BlackListType.DEBTOR_IP_ADDRESS}>Ip address</option>
                        <option value={BlackListType.DEBTOR_BANK_ACCOUNT}>Debtor bank account</option>
                        <option value={BlackListType.DEBTOR_REFERENCE}>Debtor reference</option>
                        <option value={BlackListType.DEBTOR_ACCOUNT_IDENTIFICATION}>Debtor account identification
                        </option>
                    </Form.Select>
                    <Form.Control     autoComplete="off"   
                        onChange={formBuilder.handleChange}
                        value={formBuilder.values.value}
                        type="text"
                        name="value"
                    />
                </div>
                <div className="rule-box_footer">
                   
                    <SButton label="Cancel" onClick={() => props.close()} type="button" class="warning"/>
                    <SButton label="Save" type="submit"/>
                </div>
                {getErrors(formBuilder.errors).map(err => (
                    <p key={err} className="errors black-list-errors">{err}</p>))}
            </div>
        </Form>
    )

}