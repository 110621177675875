import {FunctionComponent} from "react";
import {InvoiceOptionProp} from "../../../Models/Props/Settings/InvoiceOptionProp";
import {Form} from "react-bootstrap";
import {ErrorMessage, Field} from "formik";

export const InvoiceOption: FunctionComponent<InvoiceOptionProp> = (props) => {
    return (
        <Form.Group className="option-input">
            <Field
                className="form-control"
                name={`${props.name}.value`}
                value={props.formValues.form.fields[props.fieldIndex].options[props.index].value}
                type="text"/>
            <span className="errors">
                      <ErrorMessage name={`${props.name}.value`}/>
                </span>
        </Form.Group>
    )
}