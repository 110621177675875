import SkeletonLoader from '../SkeletonLoader/SkeletonLoader'
import './LoadingTable.scss'
function LoadingTable() {
  return (
    <>
      {
        [...Array(4)].map((_,index) => (
          <div className='row loading' key={index}>
            <div className='col-4'>
              <SkeletonLoader shape='rectangle' height='0.8rem' />
            </div>
            <div className='col-4'>
              <SkeletonLoader key={"2"} shape='rectangle' height='0.8rem'/>
            </div>
            <div className='col-4'>
              <SkeletonLoader shape='rectangle'height='0.8rem' />
            </div>

          </div>
        ))
      }
    </>
  )
}

export default LoadingTable