import {FunctionComponent} from "react";
import {Form} from "react-bootstrap";
import {RuleOptionProp} from "../../../Models/Props/Settings/Rules/RuleOptionProp";

export const RuleConditionOption: FunctionComponent<RuleOptionProp> = (props) => {
    return (
        <div className="rule-box_options">
            <span>in</span>
            <Form.Control     autoComplete="off"   
                value={props.form.values.condition.option.appliedTo}
                onChange={props.form.handleChange}
                name="condition.option.type" className="hidden-select" type="hidden"/>
            <Form.Control     autoComplete="off"   
                value={props.form.values.condition.option.value}
                onChange={props.form.handleChange}
                name="condition.option.value" type="text"/>
            <Form.Select
                value={props.form.values.condition.option.period}
                onChange={props.form.handleChange}
                name="condition.option.period" as="select">
                <option value="m">Minutes</option>
                <option value="h">Hours</option>
                <option value="d">Days</option>
                <option value="M">Months</option>
            </Form.Select>
        </div>
    )
}