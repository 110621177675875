import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import "./CodePassword.scss"
import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import {useNavigate} from "react-router-dom";
import {ResetPasswordVerifyAsync} from "../../Api/Login/LoginApi";
import {EcdhAesEncryption} from "../../Security/Crypto/Ecc/EcdhAesEncryption";
import {EncryptedHeader} from "../../Api/Login/encryptedHeader";
import {CodeModel} from "../../Models/Api/Login/CodeModel";
import {AppRoutes} from "../../Models/Enums/AppRoutes";
import {loginResetEmail, loginResetLoading} from "../../Store/Login/LoginSlice";
import logo from "../../assets/Images/Spare Business Logo-01-b.svg";
import logoMobile from "../../assets/Images/Spare Business Logo-01.svg";
import useWindowDimensions from "../../Hooks/windowsDimensions";
import Lottie from "lottie-web";
import animation from "../../assets/Images/lottie-loader.json";

export const CodePassword: FunctionComponent = () => {
    const serverKey = process.env.REACT_APP_SERVER_KEY;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {width} = useWindowDimensions();

    /**
     * Manage layout and animations
     */
    useEffect(() => {
        setMobile(width <= 1024)
        const anim = Lottie.loadAnimation({
            container: document.getElementById('loader') ?? new Element(),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            name: 'animBtn',
            animationData: animation,
        })
    }, [width])

    /**
     * State
     */
    const [mobile, setMobile] = useState(width <= 1024);
    const userEmail = useAppSelector(loginResetEmail);
    const resetLoading = useAppSelector(loginResetLoading);
    const [code, setCode] = useState<string>("");

    if (!userEmail) {
        navigate(AppRoutes.FORGET_PASSWORD);
    }

    const Submit = (e: FormEvent) => {
        e.preventDefault();
        if (code !== "") {
            const keys = EcdhAesEncryption.fetchKeys();
            if (serverKey) {
                const cipher = EcdhAesEncryption.encrypt(
                    keys.privateKey,
                    serverKey,
                    Buffer.from(code)
                );
                const headers: EncryptedHeader = {
                    nonce: cipher.base64Nonce ?? "",
                    publicKey: Buffer.from(keys.publicKey).toString('base64'),
                }
                const data: CodeModel = {
                    code: cipher.base64Cipher ?? "",
                    email: userEmail,
                };
                dispatch(ResetPasswordVerifyAsync({data, headers})).then(e => {
                    if (e.meta.requestStatus === "fulfilled") {
                        navigate(AppRoutes.RESET_PASSWORD);
                    }
                })
            }
        }
    }
    return (
        <div className="code-wrapper">

            <div className="code-card">
                <div className="form-header">
                    <div className="logo">
                        {!mobile ? <img src={logo} alt="logo"/> : <img src={logoMobile} alt="logo"/>}
                    </div>
                    <h4 className="business-title ">SPARE BUSINESS</h4>
                    <p className="page-info">
                        <span className=" graphik-m-font ">CODE RESET</span><br/>
                        <span className="graphik-r-font">Please check your email for a message with
                                your code </span>
                    </p>
                </div>
                <div className="form-wrapper">
                    <Form onSubmit={(e) => Submit(e)}>
                        <Form.Group>
                            <Form.Control     
                                autoComplete="off"
                                name="code"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                type="text"/>
                        </Form.Group>
                        <div className="dialog-footer">
                            <button className="spare_btn spare_btn_login">
                                {!resetLoading && <span>Submit</span>}
                                <div className={resetLoading ? "showLoader" : ""} id="loader"></div>
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}