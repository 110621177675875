import { BankProvider } from "../../Models/Store/Settings/BankProvider";
import {BankAccountModel} from "../../Models/Store/Settings/BankAccount"
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AddBankAccountAsync, DeleteBankAccountAsync, GetBankAccountAsync, GetBankProvidersAsync, UpdateBankAccountAsync } from "../../Api/Settings/SettingsApi";
import { EcdhAesEncryption } from "../../Security/Crypto/Ecc/EcdhAesEncryption";

interface BankAccountState{
    providers: BankProvider[];
    loadingProviders:boolean,
    bankAccount: BankAccountModel | null;
    loadingAccount:boolean
}
const initialState:BankAccountState = {
    providers: [],
    loadingProviders:false,
    bankAccount:null,
    loadingAccount:false
}
const decryptIdentification = (cryptedValue: string, headerNonce: string) => {
    const nonce = Buffer.from(headerNonce, 'base64');
    const keys = EcdhAesEncryption.fetchKeys();
    const decryptedIdentification = Buffer.from(
        EcdhAesEncryption.decrypt(
            keys.privateKey,
            process.env.REACT_APP_SERVER_KEY ?? "",
            Buffer.from(cryptedValue, 'base64'),
            nonce,
        ),
    ).toString('utf-8');
    return decryptedIdentification;
}

const setBankAccount = (data:BankAccountModel, nonce:string)=>{
    const decryptedIdentification = decryptIdentification(data.identification,nonce)
    const decryptedData:BankAccountModel = {
        ...data,
        identification:decryptedIdentification
    }
    return decryptedData;
}
export const BankAccountSlice = createSlice({
    name:'BankAccount',
    initialState,
    reducers:{
        setProviders: (state,action:PayloadAction<BankProvider[]>)=>{
            state.providers = action.payload
        },
    },
    extraReducers:(builder)=>{
        builder.addCase(
            GetBankProvidersAsync.pending,
            (state)=>{
                state.providers = [];
                state.loadingProviders=true;
            }
        );
        builder.addCase(
            GetBankProvidersAsync.fulfilled,
            (state,action)=>{
                state.providers = action.payload.data;
                state.loadingProviders = false;
            }
        );
        builder.addCase(
            GetBankProvidersAsync.rejected,
            (state)=>{
                state.loadingProviders=false;
            }
        );
        builder.addCase(
            GetBankAccountAsync.pending,
            (state)=>{
                state.loadingAccount = true;
            }
        );
        builder.addCase(
            GetBankAccountAsync.fulfilled,
            (state,action)=>{
                const nonce = action.payload.headers['nonce'];
                const data = action.payload.data.data as BankAccountModel
                state.bankAccount = setBankAccount(data,nonce);
                state.loadingAccount = false;
            }
        );
        builder.addCase(
            GetBankAccountAsync.rejected,
            (state)=>{
                state.loadingAccount =false;
            }
        );
        builder.addCase(
            UpdateBankAccountAsync.pending,
            (state)=>{
                state.loadingAccount = true;
            }
        );
        builder.addCase(
            UpdateBankAccountAsync.fulfilled,
            (state,action)=>{
                const nonce = action.payload.headers['nonce'];
                const data = action.payload.data.data as BankAccountModel
                state.bankAccount = setBankAccount(data,nonce);
                state.loadingAccount = false;
            }
        );
        builder.addCase(
            UpdateBankAccountAsync.rejected,
            (state)=>{
                state.loadingAccount =false;
            }
        );
        builder.addCase(
            DeleteBankAccountAsync.pending,
            (state)=>{
                state.loadingAccount=true;
            }
        );
        builder.addCase(
            DeleteBankAccountAsync.fulfilled,
            (state)=>{
                state.bankAccount = null;
                state.loadingAccount=false;
            }
        );
        builder.addCase(
            DeleteBankAccountAsync.rejected,
            (state,action)=>{
                if(action.error.message?.includes('400')){
                    state.bankAccount = null;
                }
                state.loadingAccount=false;
            }
        );
        builder.addCase(
            AddBankAccountAsync.pending,
            (state)=>{
                state.loadingAccount =true;
            }
        );
        builder.addCase(
            AddBankAccountAsync.fulfilled,
            (state,action)=>{
                const nonce = action.payload.headers['nonce'];
                const data = action.payload.data.data as BankAccountModel
                state.bankAccount = setBankAccount(data,nonce);
                state.loadingAccount = false;
            }
        );
        builder.addCase(
            AddBankAccountAsync.rejected,
            (state)=>{
                state.loadingAccount =false;
            }
        );
    }
});

export default BankAccountSlice.reducer;
export const {setProviders} = BankAccountSlice.actions;
