import axios from 'axios'
import {BaseUrl} from "./ApiConstants";
import ErrorInterceptor from "./HttpInterceptors/ErrorInterceptor";
import authInterceptor from "./HttpInterceptors/AuthInterceptor";


export const api = axios.create({
    baseURL: BaseUrl,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})

ErrorInterceptor(api);
authInterceptor(api);

