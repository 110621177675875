import {FraudMonitoringState} from "../../Models/Store/FraudMonitoring/FraudMonitoringState";
import {BlackListModel} from "../../Models/Api/FraudDetection/BlackListModel";
import {FdRuleModel} from "../../Models/Api/FraudDetection/FdRuleModel";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    AddBlackListAsync,
    AddRuleAsync, BlackListAsync, DeleteBlackListAsync, DeleteRulesAsync,
    GeRulesAsync, GetBlackListAsync,
    ListRulesAsync, UpdateBlackListAsync,
    UpdateRuleAsync
} from "../../Api/Fraudmonitoring/FraudMonitoringApi";
import {ServerResponse} from "../../Models/Api/ServerResponse";
import {RootState} from "../store";
import {showErrorToaster, showToaster} from "../toaster";


const initialState: FraudMonitoringState = {
    blackList: new Array<BlackListModel>(),
    blackListLoading: false,
    ruleActionLoading: true,
    rulesList: new Array<FdRuleModel>(),
    selectedBlackList: null,
    selectedRule: null
}

export const fraudMonitoringSlice = createSlice({
    initialState,
    name: "fraudMonitoring",
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(ListRulesAsync.pending, (state) => {
            state.ruleActionLoading = true;
        })
        builder.addCase(ListRulesAsync.fulfilled, (state, action: PayloadAction<ServerResponse<FdRuleModel[]>>) => {
            state.rulesList = action.payload.data;
            state.ruleActionLoading = false;
        })
        builder.addCase(ListRulesAsync.rejected, (state) => {
            state.ruleActionLoading = false;
            showErrorToaster("Failed to retrieve data");
        })

        builder.addCase(GeRulesAsync.pending, (state) => {
            state.ruleActionLoading = true;
        })
        builder.addCase(GeRulesAsync.fulfilled, (state, action: PayloadAction<ServerResponse<FdRuleModel>>) => {
            state.ruleActionLoading = false;
            state.selectedRule = action.payload.data;
        })
        builder.addCase(GeRulesAsync.rejected, (state) => {
            state.ruleActionLoading = false;
            showErrorToaster("Failed to retrieve data");
        })

        builder.addCase(AddRuleAsync.pending, (state) => {
            state.ruleActionLoading = true;
        })
        builder.addCase(AddRuleAsync.fulfilled, (state) => {
            state.ruleActionLoading = false;
            state.selectedRule = null;
            showToaster("Rule added successfully!")
        })
        builder.addCase(AddRuleAsync.rejected, (state) => {
            state.ruleActionLoading = false;
            showErrorToaster("Failed to add rule");
        })

        builder.addCase(UpdateRuleAsync.pending, (state) => {
            state.ruleActionLoading = true;
        })
        builder.addCase(UpdateRuleAsync.fulfilled, (state) => {
            state.ruleActionLoading = false;
            state.selectedRule = null;
            showToaster("Rule updated successfully!")
        })
        builder.addCase(UpdateRuleAsync.rejected, (state) => {
            state.ruleActionLoading = false;
            showErrorToaster("Failed to update rule");
        })

        builder.addCase(DeleteRulesAsync.pending, (state) => {
            state.ruleActionLoading = true;
        })
        builder.addCase(DeleteRulesAsync.fulfilled, (state) => {
            state.ruleActionLoading = false;
            state.selectedRule = null;
            showToaster("Rule deleted successfully!")
        })
        builder.addCase(DeleteRulesAsync.rejected, (state) => {
            state.ruleActionLoading = false;
            showErrorToaster("Failed to delete rule");
        })


        builder.addCase(BlackListAsync.pending, (state) => {
            state.blackListLoading = true;
        })
        builder.addCase(BlackListAsync.fulfilled, (state, action: PayloadAction<ServerResponse<BlackListModel[]>>) => {
            state.blackList = action.payload.data;
            state.blackListLoading = false;

        })
        builder.addCase(BlackListAsync.rejected, (state) => {
            state.blackListLoading = false;
            showErrorToaster("Failed to retrieve data");
        })

        builder.addCase(GetBlackListAsync.pending, (state) => {
            state.blackListLoading = true;
        })
        builder.addCase(GetBlackListAsync.fulfilled, (state, action: PayloadAction<ServerResponse<BlackListModel>>) => {
            state.blackListLoading = false;
            state.selectedBlackList = action.payload.data;
        })
        builder.addCase(GetBlackListAsync.rejected, (state) => {
            state.blackListLoading = false;
            showErrorToaster("Failed to retrieve data");
        })

        builder.addCase(AddBlackListAsync.pending, (state) => {
            state.blackListLoading = true;
        })
        builder.addCase(AddBlackListAsync.fulfilled, (state) => {
            state.blackListLoading = false;
            state.selectedBlackList = null;
            showToaster("Black list item added successfully!")
        })
        builder.addCase(AddBlackListAsync.rejected, (state) => {
            state.blackListLoading = false;
            showErrorToaster("Failed to add to black list");
        })

        builder.addCase(UpdateBlackListAsync.pending, (state) => {
            state.blackListLoading = true;
        })
        builder.addCase(UpdateBlackListAsync.fulfilled, (state) => {
            state.blackListLoading = false;
            state.selectedBlackList = null;
            showToaster("Black list item updated successfully!")
        })
        builder.addCase(UpdateBlackListAsync.rejected, (state) => {
            state.blackListLoading = false;
            showErrorToaster("Failed to update black list");
        })

        builder.addCase(DeleteBlackListAsync.pending, (state) => {
            state.blackListLoading = true;
        })
        builder.addCase(DeleteBlackListAsync.fulfilled, (state) => {
            state.blackListLoading = false;
            state.selectedBlackList = null;
            showToaster("Black list item deleted successfully!")
        })
        builder.addCase(DeleteBlackListAsync.rejected, (state) => {
            state.blackListLoading = false;
            showErrorToaster("Failed to delete from black list");
        })
    }
})

/**
 * Selectors
 */
export const fdRulesList = (state: RootState) => state.fraudMonitoring.rulesList;
export const fdSelectedRule = (state: RootState) => state.fraudMonitoring.selectedRule;
export const fdBlackList = (state: RootState) => state.fraudMonitoring.blackList;
export const fdSelectedBlackList = (state: RootState) => state.fraudMonitoring.selectedBlackList;
export const fdRulesLoading = (state: RootState) => state.fraudMonitoring.ruleActionLoading;
export const fdBlackListLoading = (state: RootState) => state.fraudMonitoring.blackListLoading;
