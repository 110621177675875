import {useEffect, useState} from "react";


export function useOutsideAlerter(ref: any) {
    const [outside, setOutSide] = useState(false);
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (event.target.className.includes("modal show")) {
                setOutSide(true);
                setTimeout(() => {
                    setOutSide(false)
                }, 1200)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            setOutSide(false);
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return outside;
}