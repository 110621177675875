import {FunctionComponent, useState} from "react";
import {PaginationProps} from "../../Models/Props/Pagination/PaginationProps";
import {Pagination} from "react-bootstrap";

export const PaginationComponent: FunctionComponent<PaginationProps> = (props) => {

    let pages = Math.ceil(props.totalCount / props.pageSize);
    let items = [];
    const [active, setActive] = useState<number>(props.activePage);
    const setActivePage = (index: number) => {
        setActive(index);
        props.onPageChange(active * props.pageSize, index);
    }

    for (let i = 1; i < pages; i++) {
        items.push(<Pagination.Item onClick={() => setActivePage(i)} key={i} active={i === active}>
            {i}
        </Pagination.Item>);
    }
    return (<Pagination>
        {items}
    </Pagination>)
}