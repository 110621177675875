import React, {FunctionComponent, useEffect} from "react";
import "./InvoiceInfo.scss";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../Store/hooks";
import {GetInvoiceAsync, GetInvoicePdf} from "../../../Api/InvoiceApi/InvoiceApi";
import {invoiceActionLoading, invoiceSelected} from "../../../Store/Invoice/InvoiceSlice";
import loader from "../../../assets/Images/Double Ring-1s-200px.gif";


export const InvoiceInfo: FunctionComponent = () => {
    const {id} = useParams();
    const dispatch = useAppDispatch();

    /**
     * State
     */
    const invoice = useAppSelector(invoiceSelected)
    const loading = useAppSelector(invoiceActionLoading)

    useEffect(() => {
        if (id) {
            dispatch(GetInvoiceAsync({id}))
        }

    }, [id])

    const download = () => {
        if (id) dispatch(GetInvoicePdf({id}));
    }

    /**
     * Template
     */
    return (
        <div className="content-wrapper invoice-info">
            <div className="row" style={{"height": "100%"}}>
                <div className="col-lg-12 grid-margin ">
                    {invoice &&
                        <div className="card invoice-card">
                            <div className="dialog-header">
                                <button
                                    onClick={() => download()}
                                    data-toggle="tooltip" data-placement="top" title="Download as pdf"
                                    className="pdf-btn">
                                    <FontAwesomeIcon icon={faDownload}/>
                                </button>
                            </div>
                            <div className="invoice-header">
                                <div className="invoice_logo">
                                    <img src={invoice.logo} alt="logo"/>
                                </div>
                                <div className="invoice-header__titles">
                                    <h4 className="invoice__title">INVOICE</h4>
                                    <div className="pt-2 pb-2">
                                        <label>Invoice Number</label>
                                        <p>{invoice.reference}</p>
                                    </div>
                                    <div className=" pb-2">
                                        <label>Invoice Date</label>
                                        <p>
                                            {
                                                invoice.dueDate
                                            }

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive2 items-section mt-5">
                                <table className="table">
                                    <thead>
                                    <th>Description</th>
                                    <th>Unit</th>
                                    <th>Price</th>
                                    <th>Tax</th>
                                    <th className="align-center">Amount</th>
                                    </thead>
                                    <tbody>
                                    {invoice.items.map(item => (
                                        <tr key={item.reference}>
                                            <td>
                                                <div>
                                                    {item.name}
                                                </div>
                                                <div className="item-description">
                                                    {item.description}
                                                </div>
                                            </td>
                                            <td>{item.unit}</td>
                                            <td>{item.price} BHD</td>
                                            <td>{item.tax} %</td>
                                            <td className="align-center">{item.amount} BHD</td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className="invoice__total-section">
                                <div className="invoice__total-wrapper">
                                    <div className="invoice_sub-total ">
                                        <div className="invoice__sub-total-item">
                                            <p>Sub Total</p>
                                            <p className="bold-text">{invoice.total} BHD</p>
                                        </div>
                                        <div className="invoice__sub-total-item">
                                            <p>VAT</p>
                                            <p className="bold-text">0.00 %</p>
                                        </div>
                                    </div>
                                    <div className="invoice__total-value ">
                                        <h4 className="mt-4">Total {invoice.total} BHD</h4>
                                    </div>
                                </div>
                            </div>


                            <div className=" pt-3 pb-5">
                                <div className="col-md-6 col-sm-12 pl-0">
                                    <p className="pb-2">Invoice to</p>
                                    <p className="customer-name">{invoice.fullName}</p>
                                    <p className="pb-2">{invoice.address}</p>
                                    <p className="m-0">Phone Number | {invoice.phoneNumber}</p>
                                    <p className="m-0">Email Address | {invoice.address}</p>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                </div>
                            </div>
                            <div className="invoice-footer">
                                <div className="invoice-qr">
                                    <p> Pay with Spare</p>
                                    <img src={`data:image/png;base64,${invoice.qrCodeModel.qrCode}`}
                                         className="invoice-qr-code"
                                         alt=""/>
                                </div>
                                <div className="invoice-signature">
                                    <h4 className="invoice-signature-title">Authorized Signature</h4>
                                </div>
                            </div>
                        </div>
                    }
                    {loading &&
                        <div className="loader-wrapper">
                            <img src={loader} alt="loader"/>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}