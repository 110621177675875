import {configureStore} from '@reduxjs/toolkit';
import {dashboardSlice} from "./Dashboard/dashboardSlice";
import {LoginSLice} from "./Login/LoginSlice";
import {PaymentSlice} from "./Payment/PaymentSlice";
import {invoiceSlice} from "./Invoice/InvoiceSlice";
import {usersSlice} from "./Users/UsersSlice";
import {FormSlice} from "./Form/FormSlice";
import {fraudMonitoringSlice} from "./FraudMonitoring/FraudMonitoringSlice";
import {SettingsSlice} from "./Settings/SettingsSlice";
import {statisticsSlice} from "./Statistics/StatisticsSlice";
import bankAccountReducer from './Settings/BankAccountSlice'

export const store = configureStore({
    reducer: {
        dashboard: dashboardSlice.reducer,
        login: LoginSLice.reducer,
        payment: PaymentSlice.reducer,
        invoice: invoiceSlice.reducer,
        users: usersSlice.reducer,
        form: FormSlice.reducer,
        fraudMonitoring: fraudMonitoringSlice.reducer,
        settings: SettingsSlice.reducer,
        statistics: statisticsSlice.reducer,
        bankAccount: bankAccountReducer
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch