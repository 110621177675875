import {ChartData} from "../../../Models/Props/ChartData";

export const PieChartOptions = (data: ChartData) => {
    return {
        toolbox: {
            feature: {
                saveAsImage: {
                    show: true,
                    title: "",
                }
            },
            left: 5,
            top: 10,
        },
        title: {
            show: data.data.length === 0,
            textStyle: {
                color: "grey",
                fontSize: 20
            },
            text: "No data",
            left: "center",
            top: "center"
        },
        color: data.colors,
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} :({d}%)',
        },
        legend: {
            orient: "vertical",
            data: data.labels,
            right: 5,
            top: 2,
            bottom: 20,
        },
        series: [
            {
                name: 'Payments',
                type: 'pie',
                radius: '70%',
                center: ['50%', '50%'],
                data: data.data,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
}