import { createAsyncThunk } from "@reduxjs/toolkit";
import { ServerResponse } from "../../Models/Api/ServerResponse";
import { BusinessAccountModel } from "../../Models/Api/Settings/BusinesAccountModel";
import { SettingsActionNames } from "../../Models/Enums/Settings/SettingsActionNames";
import { api } from "../api";
import { BusinessAccountRoutes, SettingsRoutes } from "../ApiConstants";
import { AxiosRequestConfig, AxiosResponse, AxiosResponseHeaders } from "axios";
import { ProfileModel } from "../../Models/Api/Settings/ProfileModel";
import { InvoiceCallBackModel } from "../../Models/Api/Settings/InvoiceCallBackModel";
import { BusinessApiKeyModel, RegenerateApiKeyModel } from "../../Models/Api/Settings/BusinessApiKeyModel";
import { MessageServerResponse, SimpleServerResponse } from "../../Models/Api/SimpleServerResponse";
import { EncryptedHeader } from "../Login/encryptedHeader";
import { NewPasswordModel } from "../../Models/Api/Login/NewPasswordModel";
import { LoginModel } from "../../Models/Api/Login/LoginModel";
import { ImageUploadModel } from "../../Models/Api/Settings/ImageUploadModel";
import { BankProvider } from "../../Models/Store/Settings/BankProvider";
import { BankAccountModel, UpdateBankAccountModel } from "../../Models/Store/Settings/BankAccount";


export const GetBusinessAccountAsync = createAsyncThunk<ServerResponse<BusinessAccountModel>>(
    SettingsActionNames.GetBusinessAccountAction,
    async () => {
        const response: AxiosResponse<ServerResponse<BusinessAccountModel>> = await api.get(SettingsRoutes.GetBusinessAccountUrl);
        return response.data;
    }
)


export const UpdateBusinessAccountAsync = createAsyncThunk<ServerResponse<BusinessAccountModel>, { model: BusinessAccountModel }>(
    SettingsActionNames.UpdateBusinessAccountAction,
    async (data) => {
        const response: AxiosResponse<ServerResponse<BusinessAccountModel>>
            = await api.post(SettingsRoutes.UpdateBusinessAccountUrl, data.model);
        return response.data;
    }
)


export const GetProfileAsync = createAsyncThunk<ServerResponse<ProfileModel>>(
    SettingsActionNames.GetProfileAction,
    async () => {
        const response: AxiosResponse<ServerResponse<ProfileModel>> = await api.get(BusinessAccountRoutes.GetBusinessAccountProfile);
        return response.data;
    }
)


export const UpdateProfileAsync = createAsyncThunk<ServerResponse<ProfileModel>, { model: ProfileModel }>(
    SettingsActionNames.UpdateProfileAction,
    async (data) => {
        const response: AxiosResponse<ServerResponse<ProfileModel>> = await api.put(BusinessAccountRoutes.UpdateBusinessAccountProfile,{
            accountManagerName:data.model.accountManagerName,
            countryCode:data.model.countryCode,
            phone:data.model.phone,
            profilePictureUrl:data.model.profilePictureUrl
        });
        return response.data;
    }
)


export const GetCallBacksAsync = createAsyncThunk<ServerResponse<InvoiceCallBackModel>>(
    SettingsActionNames.GetInvoiceCallbackAction,
    async () => {
        const response: AxiosResponse<ServerResponse<InvoiceCallBackModel>> = await api.get(SettingsRoutes.InvoiceCallbacksUrl);
        return response.data;
    }
)

export const UpdateCallBacksAsync = createAsyncThunk<ServerResponse<InvoiceCallBackModel>, { model: InvoiceCallBackModel }>(
    SettingsActionNames.UpdateCallBackUrlAction,
    async (data) => {
        const response: AxiosResponse<ServerResponse<InvoiceCallBackModel>>
            = await api.patch(SettingsRoutes.UpdateInvoiceCallBacksUrl, {callbackUrl:data.model.callbackUrl});
        return response.data;
    }
)
/**
 * @Get API keys @Array
 * 
 */
/** @GET bank account info */
interface HeadersWithBusinessApiKeyModel{
    headers:AxiosResponseHeaders,
    data:BusinessApiKeyModel[],
    message:ServerResponse<String>
}

export const ListApiKeysAsync = createAsyncThunk<HeadersWithBusinessApiKeyModel>(
    SettingsActionNames.ListApiKeysAction,
    async () => {
        const response: AxiosResponse<HeadersWithBusinessApiKeyModel> = await api.get(SettingsRoutes.ListApiKeysUrl);
        const results:HeadersWithBusinessApiKeyModel={
            headers:response.headers,
            data:response.data.data,
            message:response.data.message
        }
        return results
    }
)

export const GreatKeyAsync = createAsyncThunk<ServerResponse<string>, { model: RegenerateApiKeyModel }>(
    SettingsActionNames.CreateKeyAction,
    async (data) => {
        const response: AxiosResponse<ServerResponse<string>> = await api.post(SettingsRoutes.CreateApiKeyUrl, data.model);
        return response.data;
    }
)

export const RevokeKeyAsync = createAsyncThunk<MessageServerResponse<BusinessApiKeyModel>, { id: string }>(
    SettingsActionNames.RevokeApiKeyAction,
    async (data) => {
        const response: AxiosResponse<MessageServerResponse<BusinessApiKeyModel>>
            = await api.patch(`${SettingsRoutes.RevokeApiKeyUrl}?id=${data.id}`);
        return response.data;
    }
)


export const ActivateKeyAsync = createAsyncThunk<MessageServerResponse<BusinessAccountModel>, { id: string }>(
    SettingsActionNames.ActivateApiKeyAction,
    async (data) => {
        const response: AxiosResponse<MessageServerResponse<BusinessAccountModel>>
            = await api.patch(`${SettingsRoutes.ActivateApiKeyUrl}?id=${data.id}`);
        return response.data;
    }
)


export const RegenerateKeyAsync = createAsyncThunk<SimpleServerResponse, { model:RegenerateApiKeyModel }>(
    SettingsActionNames.RegenerateApiKeyAction,
    async (data) => {
        const response: AxiosResponse<SimpleServerResponse>
            = await api.put(SettingsRoutes.RegenerateApiKeyUrl, data.model,);
        return response.data;
    }
)

export const DeleteApiKeyAsync = createAsyncThunk<SimpleServerResponse, { id: string }>(
    SettingsActionNames.DeleteApiKeyAction,
    async (data) => {
        const response: AxiosResponse<SimpleServerResponse>
            = await api.delete(`${SettingsRoutes.DeleteApiKeyUrl}?id=${data.id}`);
        return response.data;
    }
)
interface updateCallBackUrl{
    id:string,
    callbackUrl:string
}
export const UpdateApiCallBackUrl = createAsyncThunk<MessageServerResponse<BusinessApiKeyModel>,{model:updateCallBackUrl}>(
    SettingsActionNames.UpdateApiCallBackUrl,
    async (data)=>{
        const response:AxiosResponse<MessageServerResponse<BusinessApiKeyModel>>
        = await api.patch(`${SettingsRoutes.UpdateCallBackApiUrl}?id=${data.model.id}`,{callbackUrl:data.model.callbackUrl});
        return response.data;
    }
)

export const UpdatePasswordAsync = createAsyncThunk<SimpleServerResponse, { model: NewPasswordModel, headers: EncryptedHeader }>(
    SettingsActionNames.UpdatePasswordAction,
    async (data) => {
        const config: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                nonce: data.headers.nonce,
                publicKey: data.headers.publicKey,
                uniqueId: data.headers.uniqueId ?? "",
            }
        };
        const response: AxiosResponse<SimpleServerResponse>
            = await api.post(SettingsRoutes.UpdatePasswordUrl, data.model, config);
        return response.data;
    }
)


export const UpdateEmailAsync = createAsyncThunk<SimpleServerResponse, { model: LoginModel, headers: EncryptedHeader }>(
    SettingsActionNames.UpdateEmailAction,
    async (data) => {
        const config: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                nonce: data.headers.nonce,
                publicKey: data.headers.publicKey,
                uniqueId: data.headers.uniqueId ?? "",
            }
        };
        const response: AxiosResponse<SimpleServerResponse>
            = await api.post(SettingsRoutes.UpdateEmailUrl, data.model, config);
        return response.data;
    }
)


export const UploadBusinessLogo = createAsyncThunk<ServerResponse<string>, { model: ImageUploadModel }>(
    SettingsActionNames.UploadBusinessLogoAction,
    async (data) => {
        const config: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
            }
        }
        const formData = new FormData();
        formData.append("fileName", data.model.fileName);
        formData.append("image", data.model.Image);
        const response: AxiosResponse<ServerResponse<string>>
            = await api.post(SettingsRoutes.UploadBusinessLogoUrl, formData, config);
        return response.data;
    }
)


export const UploadProfileLogo = createAsyncThunk<ServerResponse<string>, { model: ImageUploadModel }>(
    SettingsActionNames.UploadProfileImageAction,
    async (data) => {
        const config: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
            }
        }
        const formData = new FormData();
        formData.append("fileName", data.model.fileName);
        formData.append("image", data.model.Image);
        const response: AxiosResponse<ServerResponse<string>>
            = await api.patch(BusinessAccountRoutes.UpdatePicture, formData, config);
        return response.data;
    }
)

/** @GET all bank providers */
export const GetBankProvidersAsync = createAsyncThunk<ServerResponse<BankProvider[]>>(
    SettingsActionNames.GetBankProviders,
    async () => {
        const response: AxiosResponse<ServerResponse<BankProvider[]>> = await api.get(SettingsRoutes.GetBankProviders + "?countryCode=bh");
        return response.data
    }
);

/** @GET bank account info */
interface BankAccountWithHeaders{
    headers:AxiosResponseHeaders,
    data:ServerResponse<BankAccountModel>,
}
export const GetBankAccountAsync = createAsyncThunk<BankAccountWithHeaders>(
    SettingsActionNames.GetBankAccount,
    async ()=>{
        
        const response : BankAccountWithHeaders = 
            await api.get(SettingsRoutes.GetBankAccount);
            const result = {
                headers:response.headers,
                data:response.data
            }
            return result
    }
)

/** 
 * @UPDATE use bank account in
 * @headers : Encrypted headers argument
 * @Data : bank account info to update argument
 * @id : bank account id argument
 */

export const UpdateBankAccountAsync = createAsyncThunk<BankAccountWithHeaders, {data: UpdateBankAccountModel, headers: EncryptedHeader,id:string}>(
    SettingsActionNames.UpdateBankAccount,
    async (req)=>{
        const config={
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                nonce: req.headers.nonce,
                publicKey: req.headers.publicKey,
                sessionId:req.headers.sessionId??""
            }
        }
       
        const response : BankAccountWithHeaders = 
            await api.patch(SettingsRoutes.UpdateBankAccount+`?id=${req.id}`,req.data,config);
            const result = {
                headers:response.headers,
                data:response.data
            }
            return result
    }
);

/** 
 * @DELETE bank account
 * @Id bank account id argument
 * @headers contain session id as argument
 */

export const DeleteBankAccountAsync = createAsyncThunk<AxiosResponse,{id:string,sessionId:string}>(
    SettingsActionNames.DeleteBankAccount,
    async (data)=>{
        const config={
            headers: {
                'Content-Type': 'application/json',
                sessionId:data.sessionId??""
            }
        };
        
        const response :AxiosResponse = 
            await api.delete(SettingsRoutes.DeleteBankAccount+`?id=${data.id}`,config);
            return response;
    }
);

/**
 * @ADD bank account
 * @sessionId
 * @nonce
 * @DATA user bank account info with crypted identification
 *  */

export const AddBankAccountAsync = createAsyncThunk<BankAccountWithHeaders, {data:UpdateBankAccountModel,headers:EncryptedHeader}>(
    SettingsActionNames.AddBankAccount,
    async (data)=>{
        const config = {
            headers:{
                'Content-Type': 'application/json',
                Accept: 'application/json',
                nonce: data.headers.nonce,
                sessionId:data.headers.sessionId??""
            }
        }
        const response:AxiosResponse = 
            await api.post(SettingsRoutes.AddBankAccount,data.data,config);
            const result:BankAccountWithHeaders = {
                data:response.data,
                headers:response.headers,
            }
            return result;
    }
);