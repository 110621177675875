import {FunctionComponent, useState} from "react";
import { Form} from "react-bootstrap";
import {InvoiceFieldProp} from "../../../Models/Props/Settings/InvoiceFieldProp";
import {ErrorMessage, Field, FieldArray} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faTimes} from "@fortawesome/free-solid-svg-icons";
import {InvoiceFieldModel} from "../../../Models/Api/Invoice/InvoiceFieldModel";
import {InvoiceFieldOptionModel} from "../../../Models/Api/Invoice/InvoiceFieldOptionModel";
import {InvoiceOption} from "./InvoiceOption";
import Toggle from "react-toggle";
import SButton from "../../UI/Button/Button";

export const InvoiceField: FunctionComponent<InvoiceFieldProp> = (props) => {
    /**
     * State
     */
    const [dataType, setDataType] = useState<string>("");
    const [canHaveOptions, setCanHaveOptions] = useState<boolean>(props.formValues.form.fields[props.index].canHaveOptions);
    const [initialOption, setInitialOption] = useState<InvoiceFieldOptionModel>({
        value: "",
        deletable: canHaveOptions,
    });


    const dataTypeOnchange = (option: string) => {
        setDataType(option);
        const type = props.dataTypes.find(t => t.id === option);
        if (type) {
            setCanHaveOptions(type.canHaveOptions);
            setInitialOption({
                ...initialOption,
                deletable: type.canHaveOptions,
            })
            props.form.values.form.fields[props.index] = {
                ...props.form.values.form.fields[props.index],
                dataTypeId: type.id,
                canHaveOptions: type.canHaveOptions,
            }
        }
    }

    /**
     * set field required/not required
     * @param required
     */
    const setRequired = (required: boolean) => {
        props.formValues.form.fields[props.index] = {
            ...props.formValues.form.fields[props.index],
            required: required,
        };
    }

    /**
     * Template
     */
    return (
        <div className="fields">

            <Field
                className="form-control"
                name={`${props.name}.id`}
                value={props.formValues.form.fields[props.index].id}
                type="hidden"/>
            <Field
                className="form-control"
                name={`${props.name}.identifier`}
                value={props.formValues.form.fields[props.index].identifier}
                type="hidden"/>
            <Form.Group>
                <Form.Label>Field name</Form.Label>
                <Field
                    className="form-control"
                    name={`${props.name}.name`}
                    value={props.formValues.form.fields[props.index].name}
                    type="text"/>
                <span className="errors">
                      <ErrorMessage name={`${props.name}.name`}/>
                </span>
            </Form.Group>
            <Form.Group>
                <Form.Select
                    onChange={(e) => dataTypeOnchange(e.target.value)}
                    name={`${props.name}.dataTypeId`}
                    value={dataType}>
                    {props.dataTypes && props.dataTypes.length > 0 &&
                        props.dataTypes.map(data => (
                            <option key={data.id} value={data.id}>{data.name}</option>
                        ))
                    }
                </Form.Select>
            </Form.Group>
            <FieldArray
                name={`${props.name}.options`}
                render={helper => (
                    <div className="options">
                        {props.formValues.form.fields[props.index].options && props.formValues.form.fields[props.index].options.length > 0 &&
                            (props.formValues.form.fields[props.index].options.map((field: InvoiceFieldModel, index: number) => (
                                <div className="option-wrapper" key={index}>
                                    <InvoiceOption
                                        name={`${props.name}.options.${index}`}
                                        formValues={props.formValues}
                                        index={index}
                                        fieldIndex={props.index}/>
                                    {initialOption.deletable && <div
                                        className="remove_btn_option"
                                        onClick={() => helper.remove(index)}>
                                        <FontAwesomeIcon icon={faTimes}/>
                                    </div>}
                                </div>
                            )))}
                        {canHaveOptions && <div className="add_option_btn">
                          
                           <div className="d-flex justify-content-center">
                           <SButton label="Add Option" icon={faAdd} class="secondary" onClick={() => helper.push(initialOption)}/> 
                           </div>
                        </div>
                        }
                    </div>
                )}
            />
            <div className="field-footer">
                <label className="informations_item">
                    <Toggle
                        name={`${props.name}.required`}
                        defaultChecked={props.formValues.form.fields[props.index].isRequired}
                        defaultValue={props.formValues.form.fields[props.index].isRequired}
                        className="custom-toggle"
                        icons={{
                            checked: null,
                            unchecked: null,
                        }}
                        onChange={() => setRequired(props.formValues.form.fields[props.index].required)}/>
                    <span className="informations_text">Required</span>
                </label>
            </div>
        </div>
    )
}