import {FunctionComponent, useEffect, useRef, useState} from "react";
import {Form, Modal} from "react-bootstrap";
import {EditUserModel} from "../../../Models/Api/Users/EditUserModel";
import {EditUserProp} from "../../../Models/Props/Users/EditUserProp";
import {UserModalNames} from "../../../Models/Enums/Users/UserModalNames";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useAppDispatch, useAppSelector} from "../../../Store/hooks";
import {EditUserAsync} from "../../../Api/Users/UsersApi";
import {selectedUser, usersActionLoading} from "../../../Store/Users/UsersSlice";
import PhoneInput, {CountryData} from "react-phone-input-2";
import {useOutsideAlerter} from "../../../Hooks/ClickOutside";
import SButton from "../../UI/Button/Button";

export const EditUser: FunctionComponent<EditUserProp> = (props) => {
    const dispatch = useAppDispatch();
    /**
     * State
     */
    const user = useAppSelector(selectedUser);
    const actionLoading = useAppSelector(usersActionLoading);
    const [phone, setPhone] = useState<string>("");
    const [phoneCd, setPhoneCd] = useState<CountryData>();
    const [phoneDirty, setPhoneDirty] = useState<boolean>(false);


    /**
     * Form submit
     * @param values
     */
    const handleSubmit = (values: EditUserModel) => {
        if (phone === "") {
            return;
        }
        values.phone = `${phoneCd?.dialCode}${phone}`;
        dispatch(EditUserAsync({user: values})).then(e => {
            if (e.meta.requestStatus === "fulfilled") {
                props.getData(values);
            }
        })
    }
    useEffect(() => {
        if (user) {
            formBuilder.setValues(user);
        }

    }, [props.show])

    const formBuilder = useFormik<EditUserModel>({
        initialValues: user ?? {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            roles: [],
            id: ""
        },
        validationSchema: Yup.object().shape({
            firstName: Yup.string().required("firstname is mandatory"),
            lastName: Yup.string().required("Lastname is mandatory"),
            email: Yup.string().email("Please enter a valid email").required("Email is mandatory"),
            phone: Yup.string()
        }),
        onSubmit: handleSubmit,
    })

    const modalRef = useRef(null);
    const outside = useOutsideAlerter(modalRef)
    useEffect(() => {
        if (outside) {
            props.close(UserModalNames.EDIT_USER)
        }
    }, [outside])


    /**
     * Template
     */
    return (
        <Modal ref={modalRef} title="edit-user-model" show={props.show}>
            <Modal.Header className="dailog-header">
                <Modal.Title className="dailog-header_title">Edit user</Modal.Title>
            </Modal.Header>
            <Form onSubmit={formBuilder.handleSubmit}>
                <Modal.Body className="dailog-body">
                    <Form.Group>
                        <Form.Label>First name</Form.Label>
                        <Form.Control     autoComplete="off"   
                            value={formBuilder.values.firstName}
                            onChange={formBuilder.handleChange}
                            onBlur={formBuilder.handleBlur}
                            name="firstName"
                            type="text"
                            placeholder="Enter first name"
                        />
                        {formBuilder.errors.firstName && formBuilder.touched.firstName &&
                            <p className="errors">{formBuilder.errors.firstName}</p>}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Last name</Form.Label>
                        <Form.Control     autoComplete="off"   
                            value={formBuilder.values.lastName}
                            onChange={formBuilder.handleChange}
                            onBlur={formBuilder.handleBlur}
                            name="lastName"
                            type="text"
                            placeholder="Enter last name"
                        />
                        {formBuilder.errors.lastName && formBuilder.touched.lastName &&
                            <p className="errors">{formBuilder.errors.lastName}</p>}
                    </Form.Group>
                    <Form.Group className="form-full-flex">
                        <Form.Label>Email</Form.Label>
                        <Form.Control     autoComplete="off"   
                            value={formBuilder.values.email}
                            onChange={formBuilder.handleChange}
                            name="email"
                            type="email"
                            placeholder="Enter email"

                        />
                        {formBuilder.errors.email && formBuilder.touched.email &&
                            <p className="errors">{formBuilder.errors.email}</p>}
                    </Form.Group>
                    <Form.Group className="form-full-flex">
                        <Form.Label>Phone</Form.Label>
                        <PhoneInput
                            inputProps={{
                                name: 'phone',
                            }}
                            inputClass="form-control"
                            country={'kw'}
                            value={phone}
                            onChange={(phone: string, e: CountryData) => {
                                setPhone(phone);
                                setPhoneCd(e);
                                setPhoneDirty(true);
                            }}
                        />
                        {phone === "" && phoneDirty ?
                            <p role="errors" className="errors">{formBuilder.errors.phone}</p> : null}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="dialog-footer">
                    <SButton label="Close" class="warning" onClick={() => props.close(UserModalNames.EDIT_USER)} type={"button"} />
                    <SButton type="submit" label="Submit" />
                </Modal.Footer>
                <div className="w-100 ">
                    {actionLoading && <div className="progress">
                        <div className="progress-bar  indeterminate">
                        </div>
                    </div>}
                </div>
            </Form>
        </Modal>
    );
}