import {FunctionComponent} from "react";
import {DeleteApiKeyProps} from "../../../Models/Props/Settings/DeleteApiKeyProps";
import { Form, Modal} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "../../../Store/hooks";
import {DeleteApiKeyAsync} from "../../../Api/Settings/SettingsApi";
import {settingApiKeyLoading} from "../../../Store/Settings/SettingsSlice";
import SButton from "../../UI/Button/Button";

export const ApiKeyDelete: FunctionComponent<DeleteApiKeyProps> = (props) => {
    const dispatch = useAppDispatch();
    const actionLoading = useAppSelector(settingApiKeyLoading);
    const Delete = () => {
        if (props.id) {
            dispatch(DeleteApiKeyAsync({id: props.id})).then(e => {
                if (e.meta.requestStatus === "fulfilled") {
                    if (props.id) props.getData(props.id);
                }
            })
        }

    }
    return (
        <Modal title="user-delete-modal" show={props.show}>
            <Modal.Header className="dialog-header">
                <Modal.Title className="dailog-header_title">
                    Delete api key
                </Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body className="dailog-body">
                    <Form.Group className="dialog_warning-message_wrapper">
                        <Form.Label className="dialog_warning-message">Are you sure you want to delete this
                            api key ?</Form.Label>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="dialog-footer">
                    
                    <SButton type="button" label="Close" class="warning" onClick={()=>{props.close()}}/>
                    <SButton type="submit" label="Submit" onClick={()=>{Delete()}}/>
                   
                </Modal.Footer>
                <div className="w-100 ">
                    {actionLoading && <div className="progress">
                        <div className="progress-bar  indeterminate">
                        </div>
                    </div>}
                </div>
            </Form>
        </Modal>
    )
}