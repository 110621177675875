import {FunctionComponent, useState} from "react";
import "./Addinvoice.scss";
import {Form, Table} from "react-bootstrap";
import {InvoiceItem} from "../InvoiceItem/InvoiceItem";
import {CustomerInvoiceModel} from "../../../Models/Api/CustomerInvoice/CustomerInvoiceModel";
import {InvoiceItemModel} from "../../../Models/Api/CustomerInvoice/InvoiceItemModel";
import {FieldArray, Formik} from "formik";
import {invoiceValidators} from "./InvoiceValidators";
import {useAppDispatch} from "../../../Store/hooks";
import {AddInvoiceAsync} from "../../../Api/InvoiceApi/InvoiceApi";
import {useNavigate} from "react-router-dom";
import {AppRoutes} from "../../../Models/Enums/AppRoutes";
import SButton from "../../UI/Button/Button";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

export const AddInvoice: FunctionComponent = () => {
    const initialInvoice: CustomerInvoiceModel = {
        items: [
            {
                name: "",
                reference: "",
                amount: 0,
                description: "",
                price: 0,
                tax: 0,
                unit: 0,
            }
        ],
        createdAt: "",
        email: "",
        vat: 0,
        reference: "",
        qrCodeModel: {
            qrCode: "",
        },
        logo: "",
        address: "",
        phoneNumber: "",
        fullName: "",
        dueDate: "",
        total: 0,
        expiryDate: "",
    }

    const initialItem: InvoiceItemModel = {
        id: "",
        name: "",
        amount: 0,
        description: "",
        price: 0,
        tax: 0,
        unit: 0,
        reference: "",
    }
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    /**
     * States
     */
    const [items, setItems] = useState<InvoiceItemModel[]>([initialItem]);

    const Submit = (values: CustomerInvoiceModel) => {
        dispatch(AddInvoiceAsync({model: values})).then(e => {
            if (e.meta.requestStatus === "fulfilled") {
                navigate(AppRoutes.Invoice);
            }
        })
    }


    /**
     * Update invoice item
     * @param data
     * @constructor
     */
    const UpdateItemData = (data: InvoiceItemModel) => {
        setItems(items.map(i => {
            if (i.reference === data.reference) {
                return data;
            } else {
                return i
            }
        }));
    }


    /**
     * Template
     */
    return (
        <div className="Invoice">
            <div className="invoice_add_card">
                <Formik
                    initialValues={initialInvoice}
                    validationSchema={invoiceValidators}
                    onSubmit={Submit}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                            <div className="invoice_add_header">
                                <h4>Customer Information</h4>
                                
                                <SButton type="submit" label="Save"/>
                            </div>
                            <div className="invoice_add_body">
                                <div className="invoice_add_body_row">
                                    <Form.Group className="invoice_add_body_item">
                                        <Form.Label>Full name</Form.Label>
                                        <Form.Control   
                                           autoComplete="off"
                                            value={props.values.fullName}
                                            onChange={props.handleChange}
                                            name="fullName"
                                            type="text"/>
                                        <p className="errors">{props.errors.fullName}</p>
                                    </Form.Group>

                                    <Form.Group className="invoice_add_body_item">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control  
                                           autoComplete="off"
                                            value={props.values.email}
                                            onChange={props.handleChange}
                                            name="email"
                                            type="email"/>
                                        <p className="errors">{props.errors.email}</p>
                                    </Form.Group>
                                    <Form.Group className="invoice_add_body_item">
                                        <Form.Label>Invoice Number</Form.Label>
                                        <Form.Control      
                                           autoComplete="off"
                                            value={props.values.reference}
                                            onChange={props.handleChange}
                                            name="reference"
                                            type="text"/>
                                        <p className="errors">{props.errors.reference}</p>
                                    </Form.Group>
                                    <Form.Group className="invoice_add_body_item">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control   
                                            value={props.values.phoneNumber}
                                            onChange={props.handleChange}
                                            name="phoneNumber"
                                            type="tel"/>
                                        <p className="errors">{props.errors.phoneNumber}</p>
                                    </Form.Group>
                                    <Form.Group className="invoice_add_body_item">
                                        <Form.Label>Due Date</Form.Label>
                                        <Form.Control     autoComplete="off"   
                                            value={props.values.dueDate}
                                            onChange={props.handleChange}
                                            name="dueDate"
                                            type="date"/>
                                        <p className="errors">{props.errors.dueDate}</p>
                                    </Form.Group>
                                    <Form.Group className="invoice_add_body_item">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control     autoComplete="off"   
                                            value={props.values.address}
                                            onChange={props.handleChange}
                                            name="address"
                                            type="text"/>
                                        <p className="errors">{props.errors.address}</p>
                                    </Form.Group>
                                </div>
                                <div className="invoice_add_header">
                                    <h4>Customer items</h4>
                                </div>
                                <div className="items">
                                    <Table>
                                        <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>unit</th>
                                            <th>Price</th>
                                            <th>Tax</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <FieldArray
                                            name="items"
                                            validateOnChange={false}
                                            render={helper => (
                                                <div className="invoice-item-wrapper">
                                                    {props.values.items && props.values.items.length > 0 &&
                                                        (props.values.items.map((field, index) => (
                                                            <InvoiceItem
                                                                name={`items.${index}`}
                                                                index={index}
                                                                form={props}
                                                                data={field} getData={UpdateItemData}
                                                                delete={() => helper.remove(index)}/>
                                                        )))
                                                    }
                                                    
                                                    <div className="d-flex justify-content-center p-3">
                                                    <SButton label="Add Item" icon={faAdd} class="secondary" onClick={() => helper.push({
                                                        id: "",
                                                        name: "",
                                                        reference: "",
                                                        amount: 0,
                                                        description: "",
                                                        price: 0,
                                                        tax: 0,
                                                        unit: 0,
                                                    })}/>
                                                    </div>
                                                    
                                                </div>
                                            )}
                                        />
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}