import {FdRuleModel} from "../../../Models/Api/FraudDetection/FdRuleModel";
import {FdRuleApplication} from "../../../Models/Enums/FraudDetection/FdRuleApplication";
import {FdRuleConditionType} from "../../../Models/Enums/FraudDetection/FdRuleConditionType";
import {FdRuleOptionPeriodType} from "../../../Models/Enums/FraudDetection/FdRuleOptionPeriodType";

export const GetHumanReadableRule = (rule: FdRuleModel) => {
    if (!rule) {
        return;
    }
    let HumanReadableRule = "If ";
    switch (rule.condition.appliedTo) {
        case FdRuleApplication.AMOUNT:
            HumanReadableRule += "transaction amount ";
            break;
        case FdRuleApplication.VOLUME_OF_TRANSACTION_PER_DEBTOR:
            HumanReadableRule += "volume of transactions per debtor ";
            break;
        case FdRuleApplication.VOLUME_OF_TRANSACTION_PER_CREDITOR:
            HumanReadableRule += "volume of transactions per creditor ";
            break;
        case FdRuleApplication.VOLUME_OF_INITIATED_TRANSACTION_PER_DEBTOR:
            HumanReadableRule += "volume of initiated transactions per debtor ";
            break;
        case FdRuleApplication.VOLUME_OF_TRANSACTION_PER_DEBTOR_IP_ADDRESS:
            HumanReadableRule += "volume of transactions per debtor ip address ";
            break;
        case FdRuleApplication.VOLUME_OF_INITIATED_TRANSACTION_PER_CREDITOR:
            HumanReadableRule += "volume of initiated transaction per creditor ";
            break;
        case FdRuleApplication.VOLUME_OF_TRANSACTION_PER_DEBTOR_IDENTIFICATION:
            HumanReadableRule += "volume of transactions per debtor identification ";
            break;
        case FdRuleApplication.VOLUME_OF_TRANSACTION_PER_CREDITOR_IDENTIFICATION:
            HumanReadableRule += "volume of transactions per creditor identification ";
            break;
        case FdRuleApplication.VOLUME_OF_INITIATED_TRANSACTION_PER_DEBTOR_IDENTIFICATION:
            HumanReadableRule += "volume of initiated transactions per debtor identification ";
            break;
        case FdRuleApplication.VOLUME_OF_INITIATED_TRANSACTION_PER_CREDITOR_IDENTIFICATION:
            HumanReadableRule += "volume of initiated transactions per creditor identification ";
            break;
        case FdRuleApplication.VOLUME_OF_INITIATED_TRANSACTION_PER_DEBTOR_IP_ADDRESS:
            HumanReadableRule += "volume of initiated transactions per debtor ip address ";
            break;
        case null:
            break;
        default:
    }
    switch (rule.condition.type) {
        case FdRuleConditionType.LESS_THAN:
            HumanReadableRule += "less than ";
            break;
        case FdRuleConditionType.GREATER_THAN:
            HumanReadableRule += "greater than ";
            break;
        case FdRuleConditionType.LESS_THAN_OR_EQUAL:
            HumanReadableRule += "less than or equal than ";
            break;
        case FdRuleConditionType.GREATER_THAN_OR_EQUAL:
            HumanReadableRule += "greater than or equal than ";
            break;
        case null:
            break;
    }

    HumanReadableRule += rule.condition.value;
    let optionPeriod = rule.condition.option.value.match(/[a-zA-Z]+|[0-9]+/g)
    if (optionPeriod) HumanReadableRule += " in " + optionPeriod[0] + parseRuleOptionValue(optionPeriod[1]);
    return HumanReadableRule;

}

const parseRuleOptionValue = (value: string) => {
    switch (value) {
        case FdRuleOptionPeriodType.m:
            return " minutes";
        case FdRuleOptionPeriodType.h:
            return " hours";
        case FdRuleOptionPeriodType.d:
            return " days";
        case FdRuleOptionPeriodType.M:
            return " months";
    }
}