import {FunctionComponent, useEffect} from "react";
import {Button, Form} from "react-bootstrap";
import logo from "../../assets/Images/Spare Business Logo-01-b.svg"
import illustration from "../../assets/Images/Forgot Password Illustration.svg"
import {useFormik} from "formik";
import {ResetPasswordModel} from "../../Models/Api/Login/ResetPasswordModel";
import * as Yup from "yup";
import "./ResetConfirmation.scss";
import {useNavigate} from "react-router-dom";
import {AppRoutes} from "../../Models/Enums/AppRoutes";
import {SecureStore} from "../../Security/Crypto/Storage/SecureStore";
import {StoreKeys} from "../../Security/Crypto/Storage/StoreKeys";
import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import {ResetPasswordAsync} from "../../Api/Login/LoginApi";
import {EcdhAesEncryption} from "../../Security/Crypto/Ecc/EcdhAesEncryption";
import {ResetPasswordHeadersModel} from "../../Models/Api/Login/ResetPasswordHeadersModel";
import {loginResetEmail, loginResetLoading} from "../../Store/Login/LoginSlice";
import Lottie from "lottie-web";
import animation from "../../assets/Images/lottie-loader.json";

export const ResetPassword: FunctionComponent = () => {
    const serverKey = process.env.REACT_APP_SERVER_KEY;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const resetToken = SecureStore.getItem(StoreKeys.RESET_TOKEN, true) ?? "";

    const email = useAppSelector(loginResetEmail);
    if (email === "") {
        navigate(AppRoutes.FORGET_PASSWORD);
    }
    const resetLoading = useAppSelector(loginResetLoading);

    useEffect(() => {
        const anim = Lottie.loadAnimation({
            container: document.getElementById('loader') ?? new Element(),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            name: 'animBtn',
            animationData: animation,
        })
    }, [])

    const Submit = (values: ResetPasswordModel) => {
        const data = Object.assign({}, values);
        if (serverKey) {
            const keys = EcdhAesEncryption.fetchKeys();
            const nonce = EcdhAesEncryption.generatePRNG();
            const passwordCipher = EcdhAesEncryption.encrypt(
                keys.privateKey,
                serverKey,
                Buffer.from(data.password),
                nonce
            );
            const cipherToken = EcdhAesEncryption.encrypt(
                keys.privateKey,
                serverKey,
                Buffer.from(resetToken),
                nonce,
            );
            data.password = passwordCipher.base64Cipher ?? "";
            const headers: ResetPasswordHeadersModel = {
                publicKey: Buffer.from(keys.publicKey).toString('base64'),
                nonce: Buffer.from(nonce).toString('base64'),
                token: cipherToken.base64Cipher ?? "",
            }
            dispatch(ResetPasswordAsync({data, headers})).then((e) => {
                if (e.meta.requestStatus === "fulfilled") {
                    navigate(AppRoutes.RESET_PASSWORD_SUCCESS);
                }
            })
        }

    }

    /**
     * Form builder
     */
    const formBuilder = useFormik<ResetPasswordModel>({
        initialValues: {
            email: email,
            password: "",
            passwordConfirm: "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required("Email is required"),
            password: Yup.string().required("Password is mandatory"),
            passwordConfirm: Yup.string()
                .oneOf([Yup.ref("password"), null], "Passwords must match")
                .required("Confirm your password"),
        }),
        onSubmit: Submit
    })
    /**
     * Template
     */
    return (
        <div className="wrapper-confirmation">
            <div className="content-container">
                <div className="form-container">
                    <div className="form-header">
                        <div className="logo">
                            <img src={logo} alt="logo"/>
                        </div>
                        <h4 className="business-title">SPARE BUSINESS</h4>
                        <p className="page-info">
                            <span className="graphik-m-font">Reset password</span><br/>
                            <span className="graphik-r-font ">Please enter your new password</span>
                        </p>
                    </div>
                    <div style={{"zIndex": 99999}} className="form-wrapper reset-form">
                        <div className="illustration">
                            <img src={illustration} alt="illustration"/>
                        </div>
                        <Form onSubmit={formBuilder.handleSubmit}>
                            <Form.Control      
                                autoComplete="off"
                                onChange={formBuilder.handleChange}
                                value={formBuilder.values.email}
                                type="hidden"
                                name="email"/>
                            {formBuilder.errors.email ?
                                <p className="errors">{formBuilder.errors.email}</p> : null}
                            <Form.Group>
                                <Form.Label>New password</Form.Label>
                                <Form.Control  
                                    autoComplete="off"
                                    onChange={formBuilder.handleChange}
                                    value={formBuilder.values.password}
                                    name="password"
                                    type="password"/>
                                {formBuilder.errors.password ?
                                    <p className="errors">{formBuilder.errors.password}</p> : null}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Confirm password</Form.Label>
                                <Form.Control    
                                    autoComplete="off"
                                    onChange={formBuilder.handleChange}
                                    value={formBuilder.values.passwordConfirm}
                                    name="passwordConfirm"
                                    type="password"/>
                                {formBuilder.errors.passwordConfirm ?
                                    <p className="errors">{formBuilder.errors.passwordConfirm}</p> : null}
                            </Form.Group>
                            <Button type="submit" className="spare_btn spare_btn_login">
                                {!resetLoading && <span>Login</span>}
                                <div className={resetLoading ? "showLoader" : ""} id="loader"></div>
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>

        </div>
    )
}