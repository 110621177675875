export enum StoreKeys {
    SESSION_ID = 'SESSION_ID',
    ACCOUNT_STATUS = 'ACCOUNT_STATUS',
    TOKEN = '9ms=',
    REFRESH_TOKEN = 'IeuQJ/L96OXa3A==',
    TOKEN_EXPIRED = "TOKEN_EXPIRED",
    RESET_TOKEN = 'Iesoj/4/WkI=',
    BROWSER_ID = 'BROWSER_ID',
    EC_PRIVATE_KEY = 'vRrCuq9khyDaSCtUml/1VA==',
    EC_PUBLIC_KEY = 'myrneVGY1ucS2VYPJCiynA=='
}