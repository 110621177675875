import {AxiosError, AxiosInstance, AxiosRequestConfig} from "axios";
import {SecureStore} from "../../Security/Crypto/Storage/SecureStore";
import {StoreKeys} from "../../Security/Crypto/Storage/StoreKeys";

const authInterceptor = (axiosInstance: AxiosInstance) => {
    axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
        if (SecureStore.isExists(StoreKeys.TOKEN)) {
            const token = `Bearer ${SecureStore.getItem(StoreKeys.TOKEN, true)}`;
            const sessionId = SecureStore.getItem(StoreKeys.SESSION_ID,true);
            if (token && config.headers) {
                config.headers["Authorization"] = token;
                if (sessionId) config.headers["sessionId"] = sessionId;
                return config;
            }
        }
        return config;

    }, (_: AxiosError) => {
    })
}

export default authInterceptor;