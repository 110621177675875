import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { CSSProperties, RefObject } from 'react';
import './Button.scss';

type Class = "primary" | "secondary" | "warning" | "danger";
type size = "small" | "large";
type type = "reset" | "submit" | "button"
interface IState {
    label: string
    class?: Class
    icon?: IconDefinition
    size?: size
    type?:type
    disabled?:boolean
    onClick?:()=>void
    ref?:RefObject<HTMLButtonElement>
    style?:CSSProperties
}
function SButton(props: IState) {
    const classes = `spare-btn  ${props.class??'primary'} ${props.size??''}`
    return (
        <button style={props.style} ref={props.ref} disabled={props.disabled} type={props.type}  className={classes} onClick={props.onClick}>
            {props.icon&&
             <FontAwesomeIcon icon={props.icon} />
            }
            <div>{props.label}</div>
        </button>
    )
}

export default SButton