import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import "../settings.scss";
import "./CustomerInformation.scss"
import {Information} from "./Information";
import {
    getCustomerInformation,
    initialField,
} from "../../../Mocks/CustomerInvoiceMock";
import {CustomerPaymentInformationProp} from "../../../Models/Props/Settings/CustomerPaymentInformationProp";
import {FieldArray, Formik} from "formik";
import {faAdd, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {InvoiceModel} from "../../../Models/Api/Invoice/InvoiceModel";
import {getInvoiceFormValidators} from "./FormValidators";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {InvoiceField} from "./InvoiceField";
import {InvoiceFieldModel} from "../../../Models/Api/Invoice/InvoiceFieldModel";
import {useAppDispatch, useAppSelector} from "../../../Store/hooks";
import {
    blockNavigation,
    formActionLoading, formBlockNavigation,
    formDataTypes,
    formInvoice, unBlockNavigation
} from "../../../Store/Form/FormSlice";
import {GetCustomerInformation, ListDataTypeAsync, UpdateCustomerInformation} from "../../../Api/Form/FormApi";
import loader from "../../../assets/Images/Double Ring-1s-200px.gif";
import SButton from "../../UI/Button/Button";

export const CustomerInformation: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    /**
     * State
     */
    const loading = useAppSelector(formActionLoading);
    const dataTypes = useAppSelector(formDataTypes);
    const form = useAppSelector(formInvoice);
    const block = useAppSelector(formBlockNavigation);
    const [customerInformation, setCustomerInformation] = useState<CustomerPaymentInformationProp[]>([])
    useEffect(() => {
        dispatch(ListDataTypeAsync());
        dispatch(GetCustomerInformation());
    }, [])


    /**
     * Submit form
     * @param values
     * @constructor
     */
    const Submit = (values: InvoiceModel) => {
        values = {
            ...values,
            customerInformation: customerInformation.filter(x => x.value).map(info => info.information),
        }
        dispatch(unBlockNavigation());
        dispatch(UpdateCustomerInformation({model: values}))
    }

    /**
     * Selected customer information
     * @param values
     */
    const getSelectedInformation = (values: CustomerPaymentInformationProp[]) => {
        setCustomerInformation(values);
    }

    const HandleOnChange = (event: FormEvent) => {
        if (event) {
            dispatch(blockNavigation());
        }
    }
    /**
     * Template
     */
    return (<div className="settings-container">
        {!form && loading && <div className="settings-container_loader">
            <img src={loader} alt="loader"/>
        </div>}
        {form && <Formik
            initialValues={form}
            validationSchema={() => getInvoiceFormValidators()}
            onSubmit={Submit}
        >
            {(props) => {

                return (

                    <form onChange={(e) => HandleOnChange(e)} onSubmit={props.handleSubmit}>
                        <div className="form-wrapper">
                            <div className="settings-header">
                                <h4>Customer Information</h4>
                                
                                <SButton type="submit" label="Save"/>
                            </div>
                            <div className="w-100 ">
                                {loading && <div className="progress">
                                    <div className="progress-bar  indeterminate">
                                    </div>
                                </div>}

                            </div>
                            <div className="customer-information">
                                <div className="customer-information_description">
                                    <p>
                                        Customers can pay you as a biller in the Spare app. This section allows you to
                                        customize
                                        which
                                        information you collect from customers when they make their bill payments.</p>
                                </div>
                                <Information data={getCustomerInformation(form?.customerInformation)}
                                             getInformation={getSelectedInformation}/>
                                <FieldArray
                                    name="form.fields"
                                    render={helper => (
                                        <div>
                                            {props.values.form.fields && props.values.form.fields.length > 0 &&
                                                (props.values?.form.fields.map((field: InvoiceFieldModel, index: number) => (
                                                    <div className="field-wrapper" key={index}>
                                                        <div
                                                            className="remove_btn"
                                                            onClick={() => helper.remove(index)}>
                                                            <FontAwesomeIcon icon={faTimes}/>
                                                        </div>
                                                        <InvoiceField
                                                            dataTypes={dataTypes}
                                                            name={`form.fields.${index}`}
                                                            index={index}
                                                            form={props}
                                                            formValues={props.values}/>
                                                    </div>
                                                )))}
                                            
                                            <div className="d-flex justify-content-center">
                                            <SButton label="Add More" class="secondary" icon={faAdd} onClick={()=>helper.push(initialField)}/>
                                            </div>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </form>
                )
            }
            }
        </Formik>}
        {block && <div className="alert-message-text">
            Please make sure to save prior to existing !
        </div>}
    </div>)
}