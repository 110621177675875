import {FunctionComponent, useEffect, useRef, useState} from "react";
import {ProfileModel} from "../../../Models/Api/Settings/ProfileModel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import "./Profile.scss";
import {ImageCropper} from "../ImageCropper/ImageCropper";
import {ProfileForm} from "./ProfileForm";
import {useAppDispatch, useAppSelector} from "../../../Store/hooks";
import {
    GetProfileAsync,
    UploadProfileLogo
} from "../../../Api/Settings/SettingsApi";
import {settingActionLoading, settingBusinessProfile} from "../../../Store/Settings/SettingsSlice";
import {ImageUploadModel} from "../../../Models/Api/Settings/ImageUploadModel";
import LoadingUi from "../../UI/LoadingProfile/LoadingProfile";
import SButton from "../../UI/Button/Button";

export const Profile: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    /**
     * State
     */
    const businessProfile = useAppSelector(settingBusinessProfile);
    const loading = useAppSelector(settingActionLoading)
    const [profile, setProfile] = useState<ProfileModel | null>()
    const [openForm, setOpenForm] = useState<boolean>(false)
    const submitBtnRef = useRef<HTMLButtonElement>();

    useEffect(() => {
        dispatch(GetProfileAsync()).then(e => {
            if (e.meta.requestStatus === "fulfilled") {
                setProfile(businessProfile);
            }
        })
    }, [])
    /**
     * Get profile image from cropper
     * @param image
     * @param file
     * @param filename
     */
    const getLogoImage = (image: string, file?: File, filename?: string) => {
        if (image && filename && file) {
            const model: ImageUploadModel = {
                Image: file,
                fileName: filename
            };
            dispatch(UploadProfileLogo({model})).then(e => {
                if (e.meta.requestStatus === "fulfilled") {
                    setProfile({
                        ...profile,
                        profilePictureUrl:image
                    })
                }
            });
        }
    }

    const getUpdateProfile = () => {
        setOpenForm(false)
    }

    /**
     * close form
     */
    const closeForm = () => {
        setOpenForm(false)
    }

    /**
     * Template
     */
    const submitForm = ()=>{
        submitBtnRef.current?.click();
    }
    return (
        <div className="profile">
            <div className="account_header">
                <div onClick={() => setOpenForm(true)} className="dialog-footer">
                    <h4>General Account Settings</h4>
                    <FontAwesomeIcon icon={faPencil}/>
                </div>
                {openForm && <div className="dialog-footer">
                   <SButton type="button" label="Cancel" class="warning" onClick={()=>{closeForm()}}/> 
                    <SButton type="submit" label="Submit" onClick={submitForm}/>
                </div>}

            </div>
               
               {loading && <div className="progress">
                    <div className="progress-bar  indeterminate">
                    </div>
                </div>}
            <div className="profile_body">
                {!openForm && !loading
                    ? <div className="profile_body_list">
                        <div className="account_body_item">
                            <label>Account manager name:</label>
                            <p>{businessProfile?.accountManagerName}</p>
                        </div>
                        <div className="account_body_item">
                            <label>Email address</label>
                            <p>{businessProfile?.email}</p>
                        </div>
                        <div className="account_body_item">
                            <label>Phone number:</label>
                            <p>{businessProfile?.countryCode+" "+businessProfile?.phone}</p>
                        </div>
                        <div className="account_body_item">
                            <label>Location:</label>
                            <p>Bahrain</p>
                        </div>
                    </div>
                    : businessProfile && !loading &&
                    <ProfileForm submitRef={submitBtnRef} close={() => closeForm()} data={businessProfile}
                                 getData={getUpdateProfile}/>
                }
                {!openForm && !loading && <div>
                    <ImageCropper image={businessProfile?.profilePictureUrl} title="Update profile image"
                                  getImage={getLogoImage}/>
                </div>}
            </div>
        </div>
    )
}