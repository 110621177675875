import React, {FunctionComponent} from "react";
import "./UsersPage.scss";
import {Outlet} from "react-router-dom";

export const UsersPage: FunctionComponent = () => {
    return (
        <div className="users-page">
            <Outlet/>
        </div>
    )
}