import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {LoginProp} from "../../Models/Props/Login/LoginProp";
import image from "../../assets/Images/Spare Business Logo-01-b.svg";
import imageMobile from "../../assets/Images/Spare Business Logo-01.svg";
import {Button, Form} from "react-bootstrap";
import illustration1 from "../../assets/Images/Dashboard 1.svg";
import illustration2 from "../../assets/Images/Dashboard illustration 2.svg";
import "./Login.scss"
import useWindowDimensions from "../../Hooks/windowsDimensions";
import {useFormik} from "formik";
import {LoginModel} from "../../Models/Api/Login/LoginModel";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {AppRoutes} from "../../Models/Enums/AppRoutes";
import {useAppDispatch} from "../../Store/hooks";
import {EcdhAesEncryption} from "../../Security/Crypto/Ecc/EcdhAesEncryption";
import {EccKeys} from "../../Security/Crypto/Ecc/EccKeys";
import {SecureStore} from "../../Security/Crypto/Storage/SecureStore";
import {StoreKeys} from "../../Security/Crypto/Storage/StoreKeys";
import {v4 as uuidv4} from 'uuid';
import {CaptchaVerify, LoginAsync} from "../../Api/Login/LoginApi";
import {EncryptedHeader} from "../../Api/Login/encryptedHeader";
import {useSelector} from "react-redux";
import {captchaResetAction, loginActionLoading, loginCaptchaSuccess, loginLoggedIn} from "../../Store/Login/LoginSlice";
import animation from "../../assets/Images/lottie-loader.json";
import Lottie from "lottie-web";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons"
import {Social} from "../Social/Social";
import Reaptcha from 'reaptcha';
import {CaptchaModel} from "../../Models/Api/Login/CaptchaModel";


export const Login: FunctionComponent<LoginProp> = () => {
    const captchaKey = process.env.REACT_APP_CAPTCHA_KEY;
    const {width} = useWindowDimensions();
    const [mobile, setMobile] = useState(width <= 1024);
    const [hide, setHide] = useState(true);
    const recaptchaRef = useRef<Reaptcha>(null);
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);

    /**
     * Manage layout and animations
     */
    useEffect(() => {
        setMobile(width <= 1024);
        dispatch(captchaResetAction());
        const anim = Lottie.loadAnimation({
            container: document.getElementById('loader') ?? new Element(),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            name: 'animBtn',
            animationData: animation,
        })
    }, [width])

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    /**
     * State
     */
    const [keys, setKeys] = useState(new EccKeys());
    const loggedIn = useSelector(loginLoggedIn);
    const actionLoading = useSelector(loginActionLoading);
    const captchaVerifyStatus = useSelector(loginCaptchaSuccess);
    const serverKey = process.env.REACT_APP_SERVER_KEY;

    /**
     * Manage keys and authentication state
     */
    useEffect(() => {
        const getKeys = () => {
            let key = EcdhAesEncryption.fetchKeys();
            setKeys(key);
        }
        if (keys.privateKey === "" && keys.publicKey === "") {
            getKeys();
        }
        if (loggedIn && !actionLoading) {
            navigate(AppRoutes.DASHBOARD);
        }
    }, [keys, loggedIn])

    useEffect(() => {
        if (!captchaVerifyStatus && captchaToken !== null) {
            recaptchaRef!.current!.reset();
        }
    }, [captchaVerifyStatus])

    const prepareLoginRequest = (values: LoginModel) => {
        const requestData: LoginModel = values;
        let headers: EncryptedHeader = {
            nonce: "",
            publicKey: "",
            uniqueId: "",
        };
        if (serverKey) {
            const cipher = EcdhAesEncryption.encrypt(
                keys.privateKey,
                serverKey,
                Buffer.from(requestData.password)
            )
            const uniqueId = SecureStore.getItem(StoreKeys.BROWSER_ID) ?
                SecureStore.getItem(StoreKeys.BROWSER_ID) : uuidv4();
            headers.uniqueId = uniqueId ?? "";
            headers.nonce = cipher.base64Nonce ?? "";
            requestData.password = cipher.base64Cipher ?? "";
            requestData.email = values.email;
            headers.publicKey = Buffer.from(keys.publicKey).toString('base64');
            return {requestData, headers};
        }
    }

    const VerifyCaptcha = () => {
        if (recaptchaRef && recaptchaRef.current) {
            recaptchaRef.current.getResponse().then(res => {
                setCaptchaToken(res);

                let headers: EncryptedHeader = {
                    nonce: "",
                    publicKey: "",
                };
                if (serverKey) {
                    const cipher = EcdhAesEncryption.encrypt(
                        keys.privateKey,
                        serverKey,
                        Buffer.from(res)
                    )
                    headers.nonce = cipher.base64Nonce ?? "";
                    headers.publicKey = Buffer.from(keys.publicKey).toString('base64');

                    const data: CaptchaModel = {
                        response: cipher.base64Cipher ?? "",
                    };
                    
                    dispatch(CaptchaVerify({data, headers}));

                }

            })
        }

    }

    /**
     * Submit form
     * @param values
     * @constructor
     */
    const Submit = async (values: LoginModel) => {
        if (captchaVerifyStatus) {
            const data = prepareLoginRequest(Object.assign({}, values));
            const model = data?.requestData;
            const headers = data?.headers;
            if (model && headers) {

                dispatch(LoginAsync({model, headers})).then(
                    async () => {
                        dispatch(captchaResetAction());
                        await recaptchaRef!.current!.reset();
                        formBuilder.values = {
                            ...formBuilder.values,
                            password: ""
                        }

                    }
                );
            }
        }

    }

    /**
     * Form builder
     */
    const formBuilder = useFormik<LoginModel>({
        initialValues: {
            email: "",
            password: "",
            rememberLogin: false,
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required("Email is mandatory"),
            password: Yup.string().required("Password is mandatory"),
        }),
        onSubmit: Submit
    });


    const forgetPasswordHandler = () => {
        navigate(AppRoutes.FORGET_PASSWORD);
    }

    const GoToRegister = () => {
        navigate(AppRoutes.REGISTER);
    }


    /**
     * Template
     */
    return (
        <div className="wrapper">
            <div className="content-container">
                <div className="illustration illustration-left">
                    {!mobile && <img src={illustration2} alt="illustration"/>}
                </div>
                <div id="form" className="form-container">
                    {mobile && <img className="woman-img" src={illustration2} alt="illustration"/>}
                    <div className="form-header">
                        <div className="logo">
                            {!mobile ? <img src={image} alt="logo"/> : <img src={imageMobile} alt="logo"/>}
                        </div>
                        <h4 className="business-title">SPARE BUSINESS</h4>
                        <p className="page-info">
                            <span className="graphik-m-font">Welcome back!</span> <br/>
                            <span className="instruction-text graphik-r-font">Please login to proceed</span>
                        </p>
                    </div>
                    <div className="form-wrapper">
                        <Form onSubmit={formBuilder.handleSubmit}>
                            <Form.Group className="mb-2">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    autoComplete="off"
                                    onChange={formBuilder.handleChange}
                                    onBlur={formBuilder.handleBlur}
                                    value={formBuilder.values.email}
                                    name="email"
                                    type="email"
                                    aria-autocomplete={"none"}/>
                                {formBuilder.errors.email && formBuilder.touched.email ?
                                    <p className="errors">{formBuilder.errors.email}</p> : null}
                            </Form.Group>
                            <Form.Group className="password-helper-wrapper mb-2">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    autoComplete="off"
                                    onChange={formBuilder.handleChange}
                                    onBlur={formBuilder.handleBlur}
                                    value={formBuilder.values.password}
                                    name="password"
                                    type={hide ? "password" : "text"}/>

                                <div onClick={() => setHide(prev => !prev)}>
                                    {hide && <FontAwesomeIcon className="cursor password-helper" icon={faEyeSlash}/>}
                                    {!hide && <FontAwesomeIcon className="cursor password-helper" icon={faEye}/>}
                                </div>
                                {formBuilder.errors.password && formBuilder.touched.password ?
                                    <p className="errors">{formBuilder.errors.password}</p> : null}
                            </Form.Group>
                            <div style={{display: "none"}} className="checkbox-group">
                                <Form.Check
                                    className=""
                                    type="checkbox"
                                    name="rememberLogin"
                                    label="Remember me"
                                >
                                </Form.Check>
                            </div>
                            {captchaKey && <Reaptcha
                                ref={recaptchaRef}
                                sitekey={captchaKey}
                                onVerify={VerifyCaptcha}
                                onExpire={() => dispatch(captchaResetAction())}
                            />}
                            <Button disabled={!captchaVerifyStatus} className="spare_btn spare_btn_login"
                                    type="submit">
                                {!actionLoading && <span>Login</span>}
                                <div className={actionLoading ? "showLoader" : ""} id="loader"></div>
                            </Button>
                            <div className="help-section">
                                 <span onClick={() => GoToRegister()}
                                       className="cursor graphik-m-font">Don't have and account, Create one </span>
                            </div>
                            <div className="help-section">
                                <span onClick={() => forgetPasswordHandler()}
                                      className="cursor graphik-m-font">Forgot Password </span>
                                <span onClick={() => window.location.href = 'https://help.tryspare.com'}
                                      className="cursor graphik-r-font">Need Help?</span>

                            </div>
                            <Social/>

                        </Form>
                    </div>
                </div>
                <div className="illustration illustration-right">
                    {!mobile && <img src={illustration1} alt="illustration"/>}
                </div>
            </div>

        </div>
    )
}