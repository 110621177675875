import {createAsyncThunk} from "@reduxjs/toolkit";
import {ServerResponse} from "../../Models/Api/ServerResponse";
import {AverageOrderResponseModel} from "../../Models/Api/Statistics/AverageOrderResponseModel";
import {StatisticsActionNames} from "../../Models/Enums/Statistics/StatisticsActionNames";
import {api} from "../api";
import {StatisticsRoutes} from "../ApiConstants";
import {AxiosResponse} from "axios";
import {PaymentByLocationModel} from "../../Models/Api/Statistics/PaymentByLocationModel";
import {PaymentStatusModel} from "../../Models/Api/Statistics/PaymentStatusModel";
import {PaymentByGrowthModel} from "../../Models/Api/Statistics/PyamentByGrowthModel";


export const getAverageOrders = createAsyncThunk<ServerResponse<AverageOrderResponseModel>, { startDate: string, endDate: string }>(
    StatisticsActionNames.getAverageOrdersAction,
    async (data) => {
        const response: AxiosResponse<ServerResponse<AverageOrderResponseModel>>
            = await api.get(`${StatisticsRoutes.getAverageOrdersUrl}?startDate=${data.startDate}&endDate=${data.endDate}`)
        return response.data;
    }
)

export const getPaymentByLocation = createAsyncThunk<ServerResponse<PaymentByLocationModel[]>, { startDate: string, endDate: string }>(
    StatisticsActionNames.paymentByLocationAction,
    async (data) => {
        const response: AxiosResponse<ServerResponse<PaymentByLocationModel[]>>
            = await api.get(`${StatisticsRoutes.PaymentsByLocationUrl}?startDate=${data.startDate}&endDate=${data.endDate}`)
        return response.data;
    }
)

export const getPaymentByStatus = createAsyncThunk<ServerResponse<PaymentStatusModel[]>, { startDate: string, endDate: string }>(
    StatisticsActionNames.paymentByStatusAction,
    async (data) => {
        const response: AxiosResponse<ServerResponse<PaymentStatusModel[]>>
            = await api.get(`${StatisticsRoutes.GetPaymentsByStatusUrl}?startDate=${data.startDate}&endDate=${data.endDate}`)
        return response.data;
    }
)

export const getPaymentByGrowth = createAsyncThunk<ServerResponse<PaymentByGrowthModel[]>, { startDate: string, endDate: string }>(
    StatisticsActionNames.paymentByGrowthAction,
    async (data) => {
        const response: AxiosResponse<ServerResponse<PaymentByGrowthModel[]>>
            = await api.get(`${StatisticsRoutes.GetPaymentByGrowthUrl}?startDate=${data.startDate}&endDate=${data.endDate}`)
        return response.data;
    }
)

export const getPaymentAnnualGrowth = createAsyncThunk<ServerResponse<PaymentByGrowthModel[]>, { startDate: string, endDate: string }>(
    StatisticsActionNames.paymentAnnualGrowthAction,
    async (data) => {
        const response: AxiosResponse<ServerResponse<PaymentByGrowthModel[]>>
            = await api.get(`${StatisticsRoutes.GetPaymentByGrowthUrl}?startDate=${data.startDate}&endDate=${data.endDate}`)
        return response.data;
    }
)