export enum FdRuleApplication {
    AMOUNT = "AMOUNT",
    VOLUME_OF_TRANSACTION_PER_DEBTOR = "VOLUME_OF_TRANSACTION_PER_DEBTOR",
    VOLUME_OF_TRANSACTION_PER_CREDITOR = "VOLUME_OF_TRANSACTION_PER_CREDITOR",
    VOLUME_OF_INITIATED_TRANSACTION_PER_DEBTOR = "VOLUME_OF_INITIATED_TRANSACTION_PER_DEBTOR",
    VOLUME_OF_INITIATED_TRANSACTION_PER_CREDITOR = "VOLUME_OF_INITIATED_TRANSACTION_PER_CREDITOR",
    VOLUME_OF_TRANSACTION_PER_DEBTOR_IDENTIFICATION = "VOLUME_OF_TRANSACTION_PER_DEBTOR_IDENTIFICATION",
    VOLUME_OF_TRANSACTION_PER_CREDITOR_IDENTIFICATION = "VOLUME_OF_TRANSACTION_PER_CREDITOR_IDENTIFICATION",
    VOLUME_OF_INITIATED_TRANSACTION_PER_DEBTOR_IDENTIFICATION = "VOLUME_OF_INITIATED_TRANSACTION_PER_DEBTOR_IDENTIFICATION",
    VOLUME_OF_INITIATED_TRANSACTION_PER_CREDITOR_IDENTIFICATION = "VOLUME_OF_INITIATED_TRANSACTION_PER_CREDITOR_IDENTIFICATION",
    VOLUME_OF_TRANSACTION_PER_DEBTOR_IP_ADDRESS = "VOLUME_OF_TRANSACTION_PER_DEBTOR_IP_ADDRESS",
    VOLUME_OF_INITIATED_TRANSACTION_PER_DEBTOR_IP_ADDRESS = "VOLUME_OF_INITIATED_TRANSACTION_PER_DEBTOR_IP_ADDRESS"
}