import loader from "../../../assets/Images/Double Ring-1s-200px.gif";
import './SpareLoader.scss';
function SpareLoader() {
    return (
        <div className="loader">
            <img src={loader} alt="loader" />
        </div>
    )
}

export default SpareLoader