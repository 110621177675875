import {CustomerPaymentInformationProp} from "../Models/Props/Settings/CustomerPaymentInformationProp";
import {CustomerPaymentInformation} from "../Models/Api/Invoice/CustomerPaymentInformation";
import {InvoiceModel} from "../Models/Api/Invoice/InvoiceModel";
import {InvoiceFieldModel} from "../Models/Api/Invoice/InvoiceFieldModel";

export const getCustomerInformation = (data: string[]) => {
    customerInformationList.forEach(x => {
        if (data.includes(x.information)) {
            x.value = true;
        }
    })
    return customerInformationList;
}


export const customerInformationList: CustomerPaymentInformationProp[] = [
    {
        value: false,
        information: CustomerPaymentInformation.DATE_OF_BIRTH,
    },
    {
        value: false,
        information: CustomerPaymentInformation.EMAIL,
    },
    {
        value: false,
        information: CustomerPaymentInformation.FULLNAME,
    },
    {
        value: false,
        information: CustomerPaymentInformation.GENDER,
    },
    {
        value: false,
        information: CustomerPaymentInformation.NATIONAL_ID,
    },
    {
        value: false,
        information: CustomerPaymentInformation.PHONE_NUMBER,
    },
];

const initialCustomerInformation: CustomerPaymentInformation[] = [];

export const initialField: InvoiceFieldModel = {
    name: "",
    canHaveOptions: false,
    options: [{
        value: "",
    }],
    identifier: "",
    isRequired: false,
};


export const initialInvoice: InvoiceModel = {
    customerInformation: initialCustomerInformation,
    form: {
        fields: [initialField],
        name: "form",
    }
}