import { FunctionComponent, useEffect, useState } from "react";
import { faFile, faInfoCircle, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Rules.scss"
import { FdRuleModel } from "../../../Models/Api/FraudDetection/FdRuleModel";
import { GetHumanReadableRule } from "./HumanReadableRule";
import { AddRule } from "./AddRule";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { fdRulesList, fdRulesLoading } from "../../../Store/FraudMonitoring/FraudMonitoringSlice";
import { DeleteRulesAsync, ListRulesAsync } from "../../../Api/Fraudmonitoring/FraudMonitoringApi";
import SButton from "../../UI/Button/Button";


export const Rules: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    /**
     * State
     */
    const loading = useAppSelector(fdRulesLoading);
    const [openDescription, setOpenDescription] = useState<boolean>(false);
    const [ruleAdded, setRuleAdded] = useState<boolean>(false);
    const rulesList = useAppSelector(fdRulesList);
    const [selectedRule, setSelectedRule] = useState<FdRuleModel>();
    const [editing, setEditing] = useState<boolean>(false)

    useEffect(() => {
        const promise = dispatch(ListRulesAsync());
        return () => {
            promise.abort();
        }
    }, [])

    /**
     * Open rules description helper
     */
    const handelOpenDescription = () => {
        setOpenDescription(prevState => !prevState);
    }

    /**
     * Open add rule form
     */
    const addRule = () => {
        setRuleAdded(true);
    }

    /**
     * Close rule
     */
    const closeRule = () => {
        setRuleAdded(false);
        setEditing(false);
        setSelectedRule(undefined);
    }

    /**
     * Add rule
     * @constructor
     */
    const AddedRuleHandler = () => {
        if (editing) {
            setEditing(false);
            setSelectedRule(undefined);
        }
        setRuleAdded(false);
        dispatch(ListRulesAsync());


    }

    const EditRule = (rule: FdRuleModel) => {
        setEditing(true);
        setSelectedRule(rule);
        setRuleAdded(true);
        dispatch(ListRulesAsync());
    }

    const DeleteRuleHandler = (identifier?: string) => {
        if (identifier) dispatch(DeleteRulesAsync({ id: identifier })).then(e => {
            if (e.meta.requestStatus === "fulfilled") {
                dispatch(ListRulesAsync());
            }
        });
    }

    /**
     * Template
     */
    return (<div className="settings-container">
        <div className="settings-header">
            <h4>Fraud Managment Rules Tool</h4>
        </div>
        <div className="w-100 ">
            {loading && <div className="progress">
                <div className="progress-bar  indeterminate">
                </div>
            </div>}

        </div>
        <div className="rules-wrapper">
            <div className="rules-body-header">
                <div className="rules-helper">
                    <div className={openDescription ? "rules-helper-description_show" : "rules-helper-description"}>
                        <p>
                            here you can setup a list of rules that will be applied to the fraud detection process,
                            each rule has a score and when a transaction is initiated, the score of each triggered
                            rule is added together. If the combined score reaches 100 then the transaction will be
                            blocked. If the total is lower than 100, the transaction is passed to the bank.
                        </p>
                        <h5>
                            Rule Types:
                        </h5>
                        <ul>
                            <li>Transaction amount: with this rule, transactions are blocked depending on the amount
                                of money being sent
                            </li>
                            <li>Volume of transactions: this rule is triggered if the same payer reference is used
                                on multiple payments that exceed a stated amount of money in a given timeframe
                            </li>
                            <li>Number of initiated transactions: This rule looks exclusively at how many
                                transactions a payer reference tries to initiate in a given period of time.
                            </li>
                        </ul>
                    </div>
                    <div onClick={() => handelOpenDescription()} className="rules-helper-btn">
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </div>
                </div>
                <div className="d-flex justify-content-end w-100">
                    <SButton label="Add rule" class="secondary" onClick={() => addRule()} disabled={ruleAdded} />
                </div>
            </div>
            {ruleAdded &&
                <div className="add-rule-container">
                    <AddRule editing={editing} data={selectedRule} close={() => closeRule()}
                        getData={AddedRuleHandler} />
                </div>
            }
            <div className="rules-body">
                {rulesList && rulesList.length > 0
                    && (
                        <div className="w-100">
                            <div className="list-dec"></div>
                            {rulesList.map(item => (
                                selectedRule?.id !== item.id
                                && <div key={item?.id} className="rule-item">
                                    <div>
                                        <span>{GetHumanReadableRule(item)}</span>
                                    </div>
                                    <div>
                                        <span className="ml-auto">Score: {item?.score}</span>
                                    </div>
                                    <div className="rule-item-actions">
                                        <div title="edit-rule" onClick={() => EditRule(item)}>
                                            <FontAwesomeIcon icon={faPencil} />
                                        </div>
                                        <div title="delete-rule"
                                            onClick={() => DeleteRuleHandler(item?.id)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )
                }
                {!loading && rulesList.length <= 0 &&
                    <div className="rule-empty-data">
                        <FontAwesomeIcon icon={faFile} />
                        <span>No rules</span>
                    </div>
                }

            </div>
        </div>
    </div>
    )
}