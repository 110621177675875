import {AxiosInstance} from "axios";
import {SecureStore} from "../../Security/Crypto/Storage/SecureStore";
import {StoreKeys} from "../../Security/Crypto/Storage/StoreKeys";
import {RefreshAsync} from "../Login/LoginApi";
import {EncryptedHeader} from "../Login/encryptedHeader";
import {EcdhAesEncryption} from "../../Security/Crypto/Ecc/EcdhAesEncryption";
import {toast} from 'react-toastify';
import {v4 as uuidV4} from "uuid";
import {ErrorEnums} from "../ErrorEnums";

const serverKey = process.env.REACT_APP_SERVER_KEY;
const errors = require("../../assets/I18n/serverErrors.json");

/**
 * Error interceptor
 * @param api
 * @constructor
 */
const ErrorInterceptor = (api: AxiosInstance) => {
    api.interceptors.response.use(
        function (response) {
            return response;
        },
        async function (error) {
            let errorResponse = error.response;
            if (errorResponse?.status !== 401) {
                if (errorResponse?.data) {
                    showErrorToast(errorResponse?.data?.error)
                }
            }
            if (errorResponse?.status === 405) {
                showErrorToast(ErrorEnums.SERVER_ERROR)
            }

            /* Refresh case */
            if (errorResponse?.status === 401
                && SecureStore.isExists(StoreKeys.TOKEN)
                && SecureStore.isExists(StoreKeys.REFRESH_TOKEN)
                && SecureStore.isExists(StoreKeys.SESSION_ID)
                && serverKey) {
                const keys = EcdhAesEncryption.fetchKeys();
                const refreshToken = SecureStore.getItem(StoreKeys.REFRESH_TOKEN, true) ?? "";
                const cipher = EcdhAesEncryption.encrypt(
                    keys.privateKey,
                    serverKey,
                    Buffer.from(refreshToken),
                );
                const headers: EncryptedHeader = {
                    uniqueId: SecureStore.getItem(StoreKeys.BROWSER_ID) ?? uuidV4(),
                    publicKey: Buffer.from(keys.publicKey).toString('base64'),
                    nonce: cipher.base64Nonce ?? "",
                    refresh: cipher.base64Cipher
                }
                SecureStore.deleteItem(StoreKeys.TOKEN);
                SecureStore.deleteItem(StoreKeys.SESSION_ID);
                await RefreshAsync(headers);
            }
            
            if (errorResponse?.status === 401
                && !SecureStore.isExists(StoreKeys.TOKEN)
                && SecureStore.isExists(StoreKeys.REFRESH_TOKEN)
                && SecureStore.isExists(StoreKeys.SESSION_ID)
                && serverKey) {
                const keys = EcdhAesEncryption.fetchKeys();
                const refreshToken = SecureStore.getItem(StoreKeys.REFRESH_TOKEN, true) ?? "";
                const cipher = EcdhAesEncryption.encrypt(
                    keys.privateKey,
                    serverKey,
                    Buffer.from(refreshToken),
                );
                const headers: EncryptedHeader = {
                    uniqueId: SecureStore.getItem(StoreKeys.BROWSER_ID) ?? uuidV4(),
                    publicKey: Buffer.from(keys.publicKey).toString('base64'),
                    nonce: cipher.base64Nonce ?? "",
                    refresh: cipher.base64Cipher
                }
                SecureStore.deleteItem(StoreKeys.TOKEN);
                SecureStore.deleteItem(StoreKeys.SESSION_ID);
                await RefreshAsync(headers);
            }
            
           
            

            if (error.status === 401
                && !SecureStore.isExists(StoreKeys.TOKEN)
                && !SecureStore.isExists(StoreKeys.REFRESH_TOKEN)) {
                showErrorToast(errorResponse.data.error);
            }
            return Promise.reject(error)
        }
    )
}

const showErrorToast = (error: ErrorEnums) => {
    toast.error(errors.serverErrors[error], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
    });
}

export default ErrorInterceptor;