import * as Yup from "yup";
import {FdRuleModel} from "../../../Models/Api/FraudDetection/FdRuleModel";

export const ruleValidator = () => {
    return Yup.object().shape({
        score: Yup.number()
            .min(0, "Score must be greater then 0")
            .max(110, "Score must be less then 101")
            .required("Score is mandatory"),
        condition: Yup.object().shape({
            type: Yup.string().required(),
            appliedTo: Yup.string().required(),
            value: Yup.number().required("Rule condition value is mandatory"),
            option: Yup.object().shape({
                type: Yup.string().required(),
                value: Yup.string().matches(/^[0-9]*$/, "Period value need to be a number").required("Rule period is mandatory"),
                period: Yup.string().required(),
            })
        })
    })

}

export const mapRuleToForm = (data: FdRuleModel) => {
    let optionPeriod = data.condition.option.value.match(/[a-zA-Z]+|[0-9]+/g)
    if (optionPeriod) {
        data = {
            ...data,
            condition: {
                ...data.condition,
                option: {
                    ...data.condition.option,
                    value: optionPeriod[0],
                    period: optionPeriod[1]
                }
            }
        }
    }
    return data;
}