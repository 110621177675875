import {FunctionComponent, useEffect} from "react";
import { Form} from "react-bootstrap";
import {RuleCondition} from "./RuleCondtiton";
import {useFormik} from "formik";
import {mapRuleToForm, ruleValidator} from "./RuleValidator";
import {FdRuleModel} from "../../../Models/Api/FraudDetection/FdRuleModel";
import {FdRuleConditionType} from "../../../Models/Enums/FraudDetection/FdRuleConditionType";
import {FdRuleApplication} from "../../../Models/Enums/FraudDetection/FdRuleApplication";
import {FdRuleConditionOptionType} from "../../../Models/Enums/FraudDetection/FdRuleConditionOptionType";
import {FdRuleOptionPeriodType} from "../../../Models/Enums/FraudDetection/FdRuleOptionPeriodType";
import {AddRuleProp} from "../../../Models/Props/Settings/Rules/AddRuleProp";
import {FormikErrors} from "formik/dist/types";
import {useAppDispatch} from "../../../Store/hooks";
import {AddRuleAsync, UpdateRuleAsync} from "../../../Api/Fraudmonitoring/FraudMonitoringApi";
import SButton from "../../UI/Button/Button";

export const AddRule: FunctionComponent<AddRuleProp> = (props) => {
    const dispatch = useAppDispatch();

    /**
     * Submit form
     * @param values
     * @constructor
     */
    const Submit = (values: FdRuleModel) => {
        values.condition.option.value = values.condition.option.value.toString() + values.condition.option.period;
        if (props.editing) {
            dispatch(UpdateRuleAsync({model: values})).then(e => {
                if (e.meta.requestStatus === "fulfilled") {
                    props.getData(values);
                }
            })
        } else {
            dispatch(AddRuleAsync({model: values})).then(e => {
                if (e.meta.requestStatus === "fulfilled") {
                    props.getData(values);
                }
            })
        }


    }

    /**
     * Form builder
     */
    const formBuilder = useFormik<FdRuleModel>({
        initialValues: props.data ? mapRuleToForm(props.data) : {
            score: 0,
            identifier: 1,
            condition: {
                value: 0,
                type: FdRuleConditionType.GREATER_THAN,
                appliedTo: FdRuleApplication.AMOUNT,
                option: {
                    type: FdRuleConditionOptionType.IN_PERIOD,
                    value: "0",
                    period: FdRuleOptionPeriodType.m,
                }
            }
        },
        validationSchema: ruleValidator(),
        onSubmit: Submit,
    });

    useEffect(() => {
        getErrors(formBuilder.errors);
    }, [formBuilder])

    const getErrors = (errors: FormikErrors<FdRuleModel>): string[] => {
        let errorsMessages: string[] = [];
        if (errors.score) {
            errorsMessages = [...errorsMessages, errors.score];
        }
        if (errors.condition?.value) {
            errorsMessages = [...errorsMessages, errors.condition.value];
        }
        if (errors.condition?.option?.value) {
            errorsMessages = [...errorsMessages, errors.condition?.option?.value];
        }
        return errorsMessages;
    }

    /**
     * Template
     */
    return (
        <Form onSubmit={formBuilder.handleSubmit}>
            <div className="rule-box">
                <div className="rule-box_options">
                    <span>If</span>
                    <RuleCondition form={formBuilder} name="condition"/>
                    <div className="rule-box_side">
                        <div className="rule-box_score">
                            <span>Score:</span>
                            <Form.Control     autoComplete="off"   
                                value={formBuilder.values.score}
                                onChange={formBuilder.handleChange}
                                name="score" type="number"/>
                        </div>
                    </div>
                </div>
                <div className="rule-box_footer">
                    
                    <SButton label="cancel" type="button" onClick={() => props.close()} class="warning" />
                 
                    <SButton type="submit" label={props.editing?'Update':'Save'}/>
                </div>
            </div>
            {getErrors(formBuilder.errors).map(err => (<p key={err} className="errors">{err}</p>))}
        </Form>
    )
}