import { FunctionComponent, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faUsers } from "@fortawesome/free-solid-svg-icons";
import "./UsersIndex.scss"
import { UsersTable } from "../UsersTable/UsersTable";
import { usersColumns } from "../../../Mocks/UsersMock";
import { AddUser } from "../AddUser/AddUser";
import { EditUser } from "../EditUser/EditUser";
import { UserModalNames } from "../../../Models/Enums/Users/UserModalNames";
import { EditUserModel } from "../../../Models/Api/Users/EditUserModel";
import { UserModel } from "../../../Models/Api/Users/UserModel";
import { DeleteUser } from "../DeleteUser/DeleteUser";
import { SetRole } from "../SetRole/SetRole";
import { EnableUser } from "../EnableUser/EnableUser";
import { DisableUser } from "../DisableUser/DisableUser";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { ListUsers, setEditedUser, usersFiler, usersLoading } from "../../../Store/Users/UsersSlice";
import { UserFilterModel } from "../../../Models/Api/Users/UserFilterModel";
import { FetchUsersListAsync } from "../../../Api/Users/UsersApi";
import SearchInput from "../../UI/SearchInput/SearchInput";
import SButton from "../../UI/Button/Button";
import LoadingTable from "../../UI/LoadingTable/LoadingTable";

export const UsersIndex: FunctionComponent = () => {

    const dispatch = useAppDispatch();

    /**
     * States
     */
    const list = useAppSelector(ListUsers);
    const loading = useAppSelector(usersLoading);
    const [filter, setFilter] = useState<UserFilterModel>(useAppSelector(usersFiler));
    const [showAddUser, setShowAddUser] = useState(false);
    const [showEditUser, setShowEditUser] = useState(false);
    const [showEditRole, setShowEditRole] = useState(false);
    const [showDeleteUser, setShowDeleteUser] = useState(false);
    const [showEnableUser, setShowEnableUser] = useState(false);
    const [showDisableUser, setShowDisableUser] = useState(false);
    const [selecteduser, setSelectedUser] = useState<EditUserModel>({
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",

    });



    useEffect(() => {
        if (!loading) {
            const promise = dispatch(FetchUsersListAsync({ filter }));

            return () => {

                promise.abort();
            }
        }
    }, [filter, dispatch])

    const getSelectedUser = (user: UserModel): EditUserModel => {
        return {
            id: user.id ?? '',
            firstName: user.fullname.split(' ')[0],
            lastName: user.fullname.split(' ')[1],
            phone: user.phone,
            email: user.email,
            roles: user.roles,
        }
    }

    /**
     * Get menu action
     * @param action
     * @param payload
     */
    const getMenuAction = (action: UserModalNames, payload?: UserModel) => {
        if (payload) {
            setSelectedUser(getSelectedUser(payload));
            dispatch(setEditedUser(getSelectedUser(payload)))
        }

        switch (action) {
            case UserModalNames.EDIT_USER:
                setShowEditUser(true);
                break;
            case UserModalNames.DELETE_USER:
                setShowDeleteUser(true);
                break;
            case UserModalNames.EDIT_ROLE:
                setShowEditRole(true);
                break;
            case UserModalNames.ENABLE_USER:
                setShowEnableUser(true);
                break;
            case UserModalNames.DISABLE_USER:
                setShowDisableUser(true)
                break;
            default:
                break;
        }
    }

    /**
     * Get added user
     */
    const getAddedUser = () => {
        setShowAddUser(false);
        dispatch(FetchUsersListAsync({ filter }));
    }

    /**
     * Get edited user
     */
    const getEditedUser = () => {
        setShowEditUser(false);
        dispatch(FetchUsersListAsync({ filter }));
    }

    /**
     * Delete user
     * @constructor
     */
    const DeleteUserAction = () => {
        setShowDeleteUser(false);
        dispatch(FetchUsersListAsync({ filter }));
    }

    /**
     * Set user roles
     * @constructor
     */
    const SetRoleAction = () => {
        setShowEditRole(false);
        dispatch(FetchUsersListAsync({ filter }));
    }

    /**
     * Enable user
     * @constructor
     */
    const EnableUserAction = () => {
        setShowEnableUser(false);
        dispatch(FetchUsersListAsync({ filter }));
    }

    /**
     * Disable user
     * @constructor
     */
    const DisableUserAction = () => {
        setShowDisableUser(false);
        dispatch(FetchUsersListAsync({ filter }));
    }

    /**
     * Modal close handler
     * @param ref
     */
    const closeModal = (ref: UserModalNames) => {
        switch (ref) {
            case UserModalNames.ADD_USER:
                setShowAddUser(false);
                break;
            case UserModalNames.EDIT_USER:
                setShowEditUser(false);
                break;
            case UserModalNames.ENABLE_USER:
                setShowEnableUser(false);
                break;
            case UserModalNames.DISABLE_USER:
                setShowDisableUser(false);
                break;
            case UserModalNames.DELETE_USER:
                setShowDeleteUser(false);
                break;
            case UserModalNames.EDIT_ROLE:
                setShowEditRole(false);
                break;
        }
    }

    /**
     * Template
     */

    const onFilterUsers = (val: string) => {
        setFilter(prevState => ({ ...prevState, keyword: val }));
    }
    return (
        <div className="users">
            <div className="users_card">
                <div className="users_card_header">
                    <h4 className="card-header_title">
                        <FontAwesomeIcon icon={faUsers} />
                        Users</h4>

                    <div className="users_card_body_filter">
                        <SearchInput value={filter.keyword} callBack={onFilterUsers} />

                        <SButton label="Create user" icon={faUserPlus} onClick={() => setShowAddUser(true)} />
                    </div>

                </div>
                <div className="users_card_body">
                    {!loading ? <div className="users_card_body_table">
                        <UsersTable loading={loading} columns={usersColumns} data={list} getAction={getMenuAction} />
                    </div> :
                        <div className="pt-3">
                            <LoadingTable />
                        </div>
                    }
                </div>
            </div>
            <AddUser
                show={showAddUser}
                getData={getAddedUser}
                close={closeModal}
            />
            <EditUser
                user={selecteduser}
                show={showEditUser}
                getData={getEditedUser}
                close={closeModal} />
            <DeleteUser
                title={`${selecteduser.firstName} ${selecteduser.lastName}`}
                show={showDeleteUser}
                id={selecteduser.id}
                close={closeModal}
                getData={DeleteUserAction} />
            <EnableUser
                title={`${selecteduser.firstName} ${selecteduser.lastName}`}
                show={showEnableUser}
                id={selecteduser.id}
                close={closeModal}
                getData={EnableUserAction} />
            <DisableUser
                title={`${selecteduser.firstName} ${selecteduser.lastName}`}
                show={showDisableUser}
                id={selecteduser.id}
                close={closeModal}
                getData={DisableUserAction} />
            <SetRole title={`${selecteduser.firstName} ${selecteduser.lastName}`}
                roles={selecteduser.roles !== undefined ? selecteduser.roles : []}
                id={selecteduser.id}
                show={showEditRole}
                close={closeModal}
                getData={SetRoleAction}
            />
        </div>
    )
}