import {FunctionComponent, useEffect, useState} from "react";
import {InformationProp} from "../../../Models/Props/Settings/InformationProp";
import {CustomerPaymentInformationProp} from "../../../Models/Props/Settings/CustomerPaymentInformationProp";
import "react-toggle/style.css"
import Toggle from "react-toggle";
import {CustomerPaymentInformation} from "../../../Models/Api/Invoice/CustomerPaymentInformation";

export const Information: FunctionComponent<InformationProp> = (props) => {
    const [informationProps, setInformationProps] = useState<CustomerPaymentInformationProp[]>(props.data);


    useEffect(() => {
        props.getInformation(informationProps);
    }, [informationProps])

    /**
     * Handel change
     * @param information
     */
    const handelChange = (information: CustomerPaymentInformationProp) => {
        let state = !information.value;
        information = {
            ...information,
            value: state,
        }

        setInformationProps(prevState => prevState.map(item => {
            if (item.information === information.information) {
                return information;
            } else {
                return item;
            }
        }));
    }

    /**
     * Get customer information text
     * @param information
     */
    const getInformationText = (information: CustomerPaymentInformation) => {
        switch (information) {
            case CustomerPaymentInformation.DATE_OF_BIRTH:
                return "Date of birth";
            case CustomerPaymentInformation.EMAIL:
                return "Email";
            case CustomerPaymentInformation.FULLNAME:
                return "Fullname";
            case CustomerPaymentInformation.GENDER:
                return "Gender";
            case CustomerPaymentInformation.NATIONAL_ID:
                return "National id";
            case CustomerPaymentInformation.PHONE_NUMBER:
                return "CPR";
        }
    }

    /**
     * Template
     */
    return (
        <div className="informations">
            {informationProps.map(item => (
                <label key={item.information} className="informations_item">
                    <Toggle
                        defaultChecked={item.value}
                        className="custom-toggle"
                        icons={{
                            checked: null,
                            unchecked: null,
                        }}
                        onChange={() => handelChange(item)}/>
                    <span className="informations_text">{getInformationText(item.information)}</span>
                </label>
            ))}
        </div>
    )
}