export enum SettingsActionNames {
    GetBusinessAccountAction = "settings/GetBusinessAccount",
    UpdateBusinessAccountAction = "settings/UpdateBusinessAccount",
    GetProfileAction = "settings/GetProfile",
    UpdateProfileAction = "settings/UpdateProfileAction",
    GetInvoiceCallbackAction = "settings/GetInvoiceCallbacks",
    UpdateCallBackUrlAction = "settings/UpdateCallBackUrl",
    ListApiKeysAction = "settings/ListApiKeysUrl",
    CreateKeyAction = "settings/CreateKey",
    RevokeApiKeyAction = "settings/RevokeApiKeyAction",
    ActivateApiKeyAction = "settings/ActivateApiKey",
    RegenerateApiKeyAction = "settings/RegenerateApiKey",
    DeleteApiKeyAction = "settings/DeleteApiKeyAction",
    UpdatePasswordAction = "settings/UpdatePasswordAction",
    UpdateEmailAction = "settings/UpdateEmailUrlAction",
    UploadBusinessLogoAction = "settings/UploadBusinessLogo",
    UploadProfileImageAction = "settings/UploadProfileImage",
    GetBankProviders="settings/GetBankProviders",
    GetBankAccount="settings/GetBankAccount",
    UpdateBankAccount="settings/UpdateBankAccount",
    DeleteBankAccount="settings/DeleteBankAccount",
    AddBankAccount="settings/AddankAccount",
    UpdateApiCallBackUrl="settings/UpdateApiCallBackUrl"
}