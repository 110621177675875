import {FunctionComponent} from "react";
import "./Waiting.scss"
import {useAppDispatch} from "../../Store/hooks";
import {logOutAction} from "../../Store/Login/LoginSlice";
import {useNavigate} from "react-router-dom";
import {AppRoutes} from "../../Models/Enums/AppRoutes";
import image from "../../assets/Images/vector.svg"
import {Social} from "../Social/Social";


export const Waiting: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const logOut = () => {
        navigate(AppRoutes.LOGIN);
        dispatch(logOutAction());
    }
    return (
        <div className="waiting-container">
            <div className="waiting-message">
                <h5 onClick={() => logOut()} className="waiting-logout">Logout</h5>
                <h4>We're verifying your account</h4>
                <div className="waiting-image-container">
                    <img src={image} alt="waiting - image"/>
                </div>
                <div className="waiting-message-block"> 
                    <span className="waiting-message-title">Thanks for signing up</span>
                    <p className="waiting-message-text"> We're verifying your account details and will be in touch with
                        you
                        as soon as it's ready</p>
                </div>
                <div className="waiting-social-links">
                    <Social/>
                </div>
            </div>
        </div>
    )
}