import {createAsyncThunk} from "@reduxjs/toolkit";
import {DashboardActionNames} from "../../Models/Enums/Dashboard/DashboardActionNames";
import {AxiosResponse} from "axios";
import {ServerResponse} from "../../Models/Api/ServerResponse";
import {LastPaymentsModel} from "../../Models/Api/Dashboard/LastPaymentsModel";
import {api} from "../api";
import {DashboardRoutes} from "../ApiConstants";
import {TotalPaymentsModel} from "../../Models/Api/Dashboard/TotalPaymentsModel";
import {TotalSalesModel} from "../../Models/Api/Dashboard/TotalSalesModel";

/**
 * Get last payment request
 */
export const FetchAsyncLastPayments =
    createAsyncThunk<ServerResponse<LastPaymentsModel[]>>(
        DashboardActionNames.LastPaymentsAction,
        async () => {
            const response: AxiosResponse<ServerResponse<LastPaymentsModel[]>>
                = await api.get(DashboardRoutes.lastPaymentsUrl);
            return response.data;
        }
    )

/***
 * Get total payments
 */
export const FetchAsyncTotalPayments =
    createAsyncThunk<ServerResponse<TotalPaymentsModel>, { startDate: string, endDate: string }>
    (
        DashboardActionNames.TotalPaymentsAction,
        async ({startDate, endDate}) => {
            const response: AxiosResponse<ServerResponse<TotalPaymentsModel>>
                = await api.get(`${DashboardRoutes.getTotalPaymentUrl}?startDate=${startDate}&endDate=${endDate}`);
            return response.data;
        }
    )

/**
 * Get total sales
 */
export const FetchAsyncTotalSales =
    createAsyncThunk<ServerResponse<TotalSalesModel>, { startDate: string, endDate: string }>
    (
        DashboardActionNames.TotalSalesAction,
        async ({startDate, endDate}) => {
            const response: AxiosResponse<ServerResponse<TotalSalesModel>>
                = await api.get(`${DashboardRoutes.getTotalSalesUrl}?startDate=${startDate}&endDate=${endDate}`)
            return response.data;
        }
    )