export enum SettingsRoutes {
    INDEX = "Index",
    PROFILE = "Profile",
    SECURITY = "Security",
    KEYS = "Keys",
    CALLBACKS = "Callback",
    CUSTOMER_INFORMATION = "CustomerInformation",
    FM_RULES = "Rules",
    BLACK_LIST = "BlackList",
    BANK_ACCOUNT = "BankAccount"
}