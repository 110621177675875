import React, {FunctionComponent, useEffect, useState} from "react";
import {TableUsersProp} from "../../../Models/Props/Users/TableUsersProp";
import Dropdown from "react-bootstrap/Dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faLock, faTrashCan, faUnlock, faUserTag} from "@fortawesome/free-solid-svg-icons";
import Avatar from "react-avatar";
import {Table} from "react-bootstrap";
import useWindowDimensions from "../../../Hooks/windowsDimensions";
import "./UsersTable.scss"
import {UserModalNames} from "../../../Models/Enums/Users/UserModalNames";
import loader from "../../../assets/Images/Double Ring-1s-200px.gif";
import NoData from "../../UI/NoData/NoData";

export const UsersTable: FunctionComponent<TableUsersProp> = (props) => {
    const {width} = useWindowDimensions();

    /**
     * State
     */
    const [mobile, setMobile] = useState(width <= 1024);

    useEffect(() => {
        setMobile(width <= 1024)
    }, [mobile])

    /**
     * Template
     */
    return (<div className="table-users">
        <Table striped={!mobile} className="table-responsive">
            <thead>
            <tr>
                {props.columns.map(column => (
                    <th key={column}>{column}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            
            {!props.loading && props.data.length > 0 && props.data.map(item => (
                <tr role="rows" key={item.id}>
                    <td>
                        <Avatar round size="30" name={item.fullname}/>
                    </td>
                    <td>
                        {item.fullname}</td>
                    <td>{item?.roles?.join(" ")}</td>
                    <td>{item.email}</td>
                    <td>{item.phone} </td>
                    <td>{item.state ? <span>Enabled</span> : <span>Disabled</span>}</td>
                    <td>15 Aug 2022</td>
                    {!mobile && <td>
                        <Dropdown>
                            <Dropdown.Toggle role="dropdown" id="dropdown-basic">
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item title="edit-user"
                                               onClick={() => props.getAction(UserModalNames.EDIT_USER, item)}
                                               className="dropdown-item"
                                ><FontAwesomeIcon className="dropdown-item_icon"
                                                  icon={faEdit}/> Edit user
                                </Dropdown.Item>
                                <Dropdown.Item title="role-user"
                                               onClick={() => props.getAction(UserModalNames.EDIT_ROLE, item)}
                                               className="dropdown-item"><FontAwesomeIcon
                                    className="dropdown-item_icon" icon={faUserTag}/> Set role
                                </Dropdown.Item>
                                {item.state ?
                                    <Dropdown.Item title="disable-user"
                                                   onClick={() => props.getAction(UserModalNames.DISABLE_USER, item)}
                                                   role="item" className="dropdown-item"><FontAwesomeIcon
                                        className="dropdown-item_icon" icon={faLock}/> Disable user
                                    </Dropdown.Item>
                                    :
                                    <Dropdown.Item title="enable-user"
                                                   onClick={() => props.getAction(UserModalNames.ENABLE_USER, item)}
                                                   role="item" className="dropdown-item"><FontAwesomeIcon
                                        className="dropdown-item_icon" icon={faUnlock}/> Enable user
                                    </Dropdown.Item>
                                }
                                <Dropdown.Item title="delete-user"
                                               onClick={() => props.getAction(UserModalNames.DELETE_USER, item)}
                                               className="dropdown-item"><FontAwesomeIcon
                                    className="dropdown-item_icon" icon={faTrashCan}/> Delete
                                </Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>
                    </td>}
                    {mobile && <td className="mobile-menu-actions">
                        <Dropdown.Item onClick={() => props.getAction(UserModalNames.EDIT_USER, item)}
                                       className="dropdown-item"
                        ><FontAwesomeIcon className="dropdown-item_icon"
                                          icon={faEdit}/>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => props.getAction(UserModalNames.EDIT_ROLE, item)}
                                       className="dropdown-item"><FontAwesomeIcon
                            className="dropdown-item_icon" icon={faUserTag}/>
                        </Dropdown.Item>
                        {item.state ?
                            <Dropdown.Item onClick={() => props.getAction(UserModalNames.DISABLE_USER, item)}
                                           role="item" className="dropdown-item"><FontAwesomeIcon
                                className="dropdown-item_icon" icon={faLock}/>
                            </Dropdown.Item>
                            :
                            <Dropdown.Item onClick={() => props.getAction(UserModalNames.ENABLE_USER, item)}
                                           role="item" className="dropdown-item"><FontAwesomeIcon
                                className="dropdown-item_icon" icon={faUnlock}/>
                            </Dropdown.Item>
                        }
                        <Dropdown.Item onClick={() => props.getAction(UserModalNames.DELETE_USER, item)}
                                       className="dropdown-item"><FontAwesomeIcon
                            className="dropdown-item_icon" icon={faTrashCan}/>
                        </Dropdown.Item>
                    </td>}
                </tr>
            ))
            }
            </tbody>
        </Table>
        {props.data.length <= 0 && !props.loading &&
            <NoData/>
        }
    </div>)
}