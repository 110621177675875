import {FunctionComponent, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {AppRoutes} from "../../../Models/Enums/AppRoutes";
import {SettingsRoutes} from "../../../Models/Enums/Routes/SettingsRoutes";
import "./Navigations.scss"
import useWindowDimensions from "../../../Hooks/windowsDimensions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleChevronLeft, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {useAppSelector} from "../../../Store/hooks";
import {formBlockNavigation} from "../../../Store/Form/FormSlice";

export const Navigation: FunctionComponent = () => {
    const {width} = useWindowDimensions();


    useEffect(() => {
        setMobile(width <= 1024)
    }, [width])

    /**
     * State
     */
    const [mobile, setMobile] = useState(width <= 1024);
    const [mobileMenu, setMobileMenu] = useState<boolean>(false);
    const block = useAppSelector(formBlockNavigation)

    /**
     * Mobile menu handler
     */
    const mobileMenuHandler = () => {
        setMobileMenu(prevState => !prevState);
    }
    /**
     * Template
     */
    return (<div className={block ? "navigations block-navigation" : "navigations"}>
        {!mobile && <ul className="navigations_list">
            <NavLink className={({isActive}) => (isActive ? "isActive navigations_list_item" : "navigations_list_item")}
                     to={`${AppRoutes.Settings}/${SettingsRoutes.INDEX}`}>Account</NavLink>
            <NavLink className={({isActive}) => (isActive ? "isActive navigations_list_item" : "navigations_list_item")}
                     to={`${AppRoutes.Settings}/${SettingsRoutes.PROFILE}`}>User
                Profile</NavLink>
            <NavLink className={({isActive}) => (isActive ? "isActive navigations_list_item" : "navigations_list_item")}
                     to={`${AppRoutes.Settings}/${SettingsRoutes.SECURITY}`}>Security
                and Login</NavLink>

            <NavLink className={({isActive}) => (isActive ? "isActive navigations_list_item" : "navigations_list_item")}
                     to={`${AppRoutes.Settings}/${SettingsRoutes.KEYS}`}>Api
                Keys</NavLink>


            <NavLink className={({isActive}) => (isActive ? "isActive navigations_list_item" : "navigations_list_item")}
                     to={`${AppRoutes.Settings}/${SettingsRoutes.CALLBACKS}`}>
                Default Callback</NavLink>


            <NavLink className={({isActive}) => (isActive ? "isActive navigations_list_item" : "navigations_list_item")}
                     to={`${AppRoutes.Settings}/${SettingsRoutes.CUSTOMER_INFORMATION}`}>Customer
                information</NavLink>

            <NavLink className={({isActive}) => (isActive ? "isActive navigations_list_item" : "navigations_list_item")}
                     to={`${AppRoutes.Settings}/${SettingsRoutes.FM_RULES}`}>Fraud
                Managment Tool Rules</NavLink>

            <NavLink className={({isActive}) => (isActive ? "isActive navigations_list_item" : "navigations_list_item")}
                     to={`${AppRoutes.Settings}/${SettingsRoutes.BLACK_LIST}`}>Black
                List</NavLink>
                <NavLink className={({isActive}) => (isActive ? "isActive navigations_list_item" : "navigations_list_item")}
                     to={`${AppRoutes.Settings}/${SettingsRoutes.BANK_ACCOUNT}`}>Bank
                Account</NavLink>
        </ul>}
        {mobile &&
            <div className="navigations_mobile">
                <div onClick={() => mobileMenuHandler()} className="navigations_mobile_btn">
                    {!mobileMenu ? <FontAwesomeIcon icon={faCircleChevronLeft}/>
                        : <FontAwesomeIcon icon={faTimesCircle}/>
                    }
                </div>
                <div className={mobileMenu ? "navigations_mobile_list expand" : "navigations_mobile_list"}>
                    <NavLink
                        className={({isActive}) => (isActive ? "mobile_isActive navigations_mobile_list_item" : "navigations_mobile_list_item")}
                        to={`${AppRoutes.Settings}/${SettingsRoutes.INDEX}`}>Account</NavLink>
                    <NavLink
                        className={({isActive}) => (isActive ? "mobile_isActive navigations_mobile_list_item" : "navigations_mobile_list_item")}
                        to={`${AppRoutes.Settings}/${SettingsRoutes.PROFILE}`}>User
                        Profile</NavLink>
                    <NavLink
                        className={({isActive}) => (isActive ? "mobile_isActive navigations_mobile_list_item" : "navigations_mobile_list_item")}
                        to={`${AppRoutes.Settings}/${SettingsRoutes.SECURITY}`}>Security
                        and Login</NavLink>

                    <NavLink
                        className={({isActive}) => (isActive ? "mobile_isActive navigations_mobile_list_item" : "navigations_mobile_list_item")}
                        to={`${AppRoutes.Settings}/${SettingsRoutes.KEYS}`}>Api
                        Keys</NavLink>


                    <NavLink
                        className={({isActive}) => (isActive ? "mobile_isActive navigations_mobile_list_item" : "navigations_mobile_list_item")}
                        to={`${AppRoutes.Settings}/${SettingsRoutes.CALLBACKS}`}>
                        Default Callback</NavLink>


                    <NavLink
                        className={({isActive}) => (isActive ? "mobile_isActive navigations_mobile_list_item" : "navigations_mobile_list_item")}
                        to={`${AppRoutes.Settings}/${SettingsRoutes.CUSTOMER_INFORMATION}`}>Customer
                        information</NavLink>

                    <NavLink
                        className={({isActive}) => (isActive ? "mobile_isActive navigations_mobile_list_item" : "navigations_mobile_list_item")}
                        to={`${AppRoutes.Settings}/${SettingsRoutes.FM_RULES}`}>Fraud
                        Managment Tool Rules</NavLink>

                    <NavLink
                        className={({isActive}) => (isActive ? "mobile_isActive navigations_mobile_list_item" : "navigations_mobile_list_item")}
                        to={`${AppRoutes.Settings}/${SettingsRoutes.BLACK_LIST}`}>Black
                        List</NavLink>
                        <NavLink
                        className={({isActive}) => (isActive ? "mobile_isActive navigations_mobile_list_item" : "navigations_mobile_list_item")}
                        to={`${AppRoutes.Settings}/${SettingsRoutes.BANK_ACCOUNT}`}>Bank Account</NavLink>
                </div>
            </div>
        }
    </div>)
}