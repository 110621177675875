import * as Yup from "yup"

export const invoiceValidators = () => {
    return Yup.object().shape({
        fullName: Yup.string().required("Fullname is mandatory"),
        email: Yup.string().required("Email is mandatory"),
        reference: Yup.string().required("Invoice number is mandatory"),
        phoneNumber: Yup.string().required("Phone number is mandatory"),
        dueDate: Yup.string().required("Due date is mandatory"),
        address: Yup.string().required("Address is mandatory"),
        items: Yup.array().of(Yup.object().shape({
            name: Yup.string().required("Name is mandatory"),
            description: Yup.string().required("Description is mandatory"),
            unit: Yup.string().required("unit is mandatory"),
            tax: Yup.string().required("Tax is mandatory"),
            price: Yup.string().required("Price is mandatory"),
        }))
    });
}