export const version = "v2.0";
export const BaseUrl = process.env.REACT_APP_BASE_URL
export const BaseUrlAuth = process.env.REACT_APP_BASE_URL_AUTH
const version_one ="v1.0";
const version_three ="v3.0";
export const LoginRoutes = BaseUrlAuth ? {
    SignIn: `${BaseUrlAuth}/api/v3.1/auth/web/BusinessWebLogin/SignIn`,
    Refresh: `${BaseUrlAuth}/api/v3.1/auth/web/BusinessWebLogin/Refresh`,
    RequestPasswordReset: `${BaseUrlAuth}/api/v3.1/auth/web/BusinessWeb/RequestPasswordReset`,
    ResetPasswordVerify: `${BaseUrlAuth}/api/v3.1/auth/web/BusinessWeb/ResetPasswordVerify`,
    ResetPassword: `${BaseUrlAuth}/api/v3.1/auth/web/BusinessWeb/ResetPassword`,
    Register: `${BaseUrlAuth}/api/v3.1/auth/web/BusinessWeb/Register`,
    Captcha: `${BaseUrlAuth}/api/v3.1/auth/web/BusinessWeb/VerifyCaptcha`

} : {
    SignIn: `${BaseUrl}/api/v3.1/auth/web/BusinessWebLogin/SignIn`,
    Refresh: `${BaseUrl}/api/v3.1/auth/web/BusinessWebLogin/Refresh`,
    RequestPasswordReset: `${BaseUrl}/api/v3.1/auth/web/BusinessWeb/RequestPasswordReset`,
    ResetPasswordVerify: `${BaseUrl}/api/v3.1/auth/web/BusinessWeb/ResetPasswordVerify`,
    ResetPassword: `${BaseUrl}/api/v3.1/auth/web/BusinessWeb/ResetPassword`,
    Register: `${BaseUrl}/api/v3.1/auth/web/BusinessWeb/Register`,
    Captcha: `${BaseUrl}/api/v3.1/auth/web/BusinessWeb/VerifyCaptcha`
}

export const DashboardRoutes = {
    getTotalPaymentUrl: `${BaseUrl}/api/${version}/business/web/Dashboard/GetTotalPayments`,
    getTotalSalesUrl: `${BaseUrl}/api/${version}/business/web/Dashboard/GetTotalSales`,
    lastPaymentsUrl: `${BaseUrl}/api/${version}/business/web/Dashboard/GetLastPayments`
}

export const PaymentsRoutes = {
    listPaymentsUrl: `${BaseUrl}/api/${version}/business/web/Payment/ListPayments`,
    getPdfExportUrl: `${BaseUrl}/api/${version}/business/web/Payment/GetPdfFileExport`,
    getCsvExportUrl: `${BaseUrl}/api/${version}/business/web/Payment/GetCsvFileExport`,
    getExcelExport: `${BaseUrl}/api/${version}/business/web/Payment/GetExcelFileExport`,
    createPaymentUrl: `${BaseUrl}/api/${version}/business/web/Payment/CreatePayment`,
    paymentDetailUrl: `${BaseUrl}/api/${version}/business/web/Payment/PaymentDetail`,
}

export const StatisticsRoutes = {
    getAverageOrdersUrl: `${BaseUrl}/api/${version}/business/web/Statistics/GetAverageOrders`,
    PaymentsByLocationUrl: `${BaseUrl}/api/${version}/business/web/Statistics/PaymentsByLocation`,
    GetPaymentsByStatusUrl: `${BaseUrl}/api/${version}/business/web/Statistics/GetPaymentsByStatus`,
    GetPaymentByGrowthUrl: `${BaseUrl}/api/${version}/business/web/Statistics/GetPaymentByGrowth`,
    GetPaymentsByAnnualGrowthUrl: `${BaseUrl}/api/${version}/business/web/Statistics/GetPaymentsByAnnualGrowth`,
}

export const InvoiceRoutes = {
    ListInvoiceUrl: `${BaseUrl}/api/${version}/business/web/Invoice/ListInvoice`,
    AddInvoiceUrl: `${BaseUrl}/api/${version}/business/web/Invoice/Add`,
    GetInvoiceUrl: `${BaseUrl}/api/${version}/business/web/Invoice/Get`,
    GetInvoicePdfUrl: `${BaseUrl}/api/${version}/business/web/Invoice/GetPdf`,
    DeleteInvoiceUrl: `${BaseUrl}/api/${version}/business/web/Invoice/Delete`,
}


export const UsersRoutes = {
    ListUsersUrl: `${BaseUrl}/api/${version}/business/web/Users/ListUsers`,
    AddUsersUrl: `${BaseUrl}/api/${version}/business/web/Users/Add`,
    UpdateRoleUrl: `${BaseUrl}/api/${version}/business/web/Users/UpdateRole`,
    UpdateUserUrl: `${BaseUrl}/api/${version}/business/web/Users/Update`,
    LockUserUrl: `${BaseUrl}/api/${version}/business/web/Users/Lock`,
    UnLockUserUrl: `${BaseUrl}/api/${version}/business/web/Users/UnLock`,
    DeleteUserUrl: `${BaseUrl}/api/${version}/business/web/Users/Delete`,
    GetUserUrl: `${BaseUrl}/api/${version}/business/web/Users/Delete`,
}

export const SettingsRoutes = {
    GetBusinessAccountUrl: `${BaseUrl}/api/${version}/business/web/Settings/GetBusinessAccount`,
    UpdateBusinessAccountUrl: `${BaseUrl}/api/${version}/business/web/Settings/UpdateBusinessAccount`,
    InvoiceCallbacksUrl: `${BaseUrl}/api/${version}/business/web/Settings/GetCallbackUrl`,
    UpdateInvoiceCallBacksUrl: `${BaseUrl}/api/${version}/business/web/Settings/UpdateCallBackUrl`,
    ListApiKeysUrl: `${BaseUrl}/api/${version}/business/web/ApiKey/List`,
    CreateApiKeyUrl: `${BaseUrl}/api/${version}/business/web/ApiKey/Create`,
    RevokeApiKeyUrl: `${BaseUrl}/api/${version}/business/web/ApiKey/Revoke`,
    ActivateApiKeyUrl: `${BaseUrl}/api/${version}/business/web/ApiKey/Activate`,
    RegenerateApiKeyUrl: `${BaseUrl}/api/${version}/business/web/ApiKey/Regenerate`,
    UpdateCallBackApiUrl:`${BaseUrl}/api/${version}/business/web/ApiKey/UpdateCallBackUrl`,
    DeleteApiKeyUrl: `${BaseUrl}/api/${version}/business/web/ApiKey/Delete`,
    UpdatePasswordUrl: `${BaseUrl}/api/${version}/business/web/Settings/UpdatePassword`,
    UpdateEmailUrl: `${BaseUrl}/api/${version}/business/web/Settings/UpdateEmail`,
    UploadBusinessLogoUrl: `${BaseUrl}/api/${version}/business/web/Settings/UploadBusinessLogo`,
    GetBankProviders: `${BaseUrl}/api/${version_one}/business/web/Provider/List`,
    GetBankAccount: `${BaseUrl}/api/${version_one}/business/web/BankAccount/Get`,
    UpdateBankAccount: `${BaseUrl}/api/${version_one}/business/web/BankAccount/Update`,
    DeleteBankAccount: `${BaseUrl}/api/${version_one}/business/web/BankAccount/Delete`,
    AddBankAccount: `${BaseUrl}/api/${version_one}/business/web/BankAccount/Add`
}
export const BusinessAccountRoutes ={
    GetBusinessAccountProfile:`${BaseUrl}/api/${version_three}/profile/web/businessaccount/Get`,
    UpdateBusinessAccountProfile:`${BaseUrl}/api/${version_three}/profile/web/businessaccount/UpdateInfo`,
    UpdatePicture:`${BaseUrl}/api/${version_three}/profile/web/businessaccount/UpdatePicture`,
}

export const FraudMonitoringRoutes = {
    ListRulesUrl: `${BaseUrl}/api/${version}/business/web/FraudMonitoring/ListRules`,
    AddRuleUrl: `${BaseUrl}/api/${version}/business/web/FraudMonitoring/AddRule`,
    UpdateRuleUrl: `${BaseUrl}/api/${version}/business/web/FraudMonitoring/FdRuleUpdate`,
    DeleteRuleUrl: `${BaseUrl}/api/${version}/business/web/FraudMonitoring/FdDeleteRule`,
    GetRuleUrl: `${BaseUrl}/api/${version}/business/web/FraudMonitoring/GetRule`,
    BlackListUrl: `${BaseUrl}/api/${version}/business/web/FraudMonitoring/BlackList`,
    AddBlackListUrl: `${BaseUrl}/api/${version}/business/web/FraudMonitoring/AddBlackList`,
    GetBlackListUrl: `${BaseUrl}/api/${version}/business/web/FraudMonitoring/GetBlackListItem`,
    UpdateBlackListUrl: `${BaseUrl}/api/${version}/business/web/FraudMonitoring/UpdateBlackList`,
    DeleteBlackListUrl: `${BaseUrl}/api/${version}/business/web/FraudMonitoring/DeleteBackList`,
}

export const FormRoutes = {
    ListDataTypesUrl: `${BaseUrl}/api/${version}/business/web/Form/ListDataTypes`,
    GetCustomerInformationUrl: `${BaseUrl}/api/${version}/business/web/Form/GetCustomerInformation`,
    UpdateCustomerInformationUrl: `${BaseUrl}/api/${version}/business/web/Form/UpdateCustomerInformation`,
}

