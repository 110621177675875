import './NoData.scss';
import logo from '../../../assets/Images/empty-state.svg'
function NoData() {
    return (
        <div className="no_data">
            <img alt="empty-logo" src={logo} />
        </div>
    )
}

export default NoData