import {FunctionComponent, useEffect} from "react";
import {PaymentsStatus} from "../../../Models/Enums/Payments/PaymentsStatus";
import GetPaymentStatusColor from "../../../Hooks/getPaymentStatusColor";
import GetPaymentStatusText from "../../../Hooks/getPaymentStatusText";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faCopy} from "@fortawesome/free-solid-svg-icons"
import "./PaymentDetail.scss";
import {useAppDispatch, useAppSelector} from "../../../Store/hooks";
import {paymentSelected} from "../../../Store/Payment/PaymentSlice";
import {useParams} from "react-router-dom";
import {PaymentDetailAsync} from "../../../Api/Payments/PaymentsApi";
import {toast} from "react-toastify";

export const PaymentDetail: FunctionComponent = () => {
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const payment = useAppSelector(paymentSelected);
    const copyText = async (text: string) => {
        await navigator.clipboard.writeText(text);
        toast.success("Link copied", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
        });
    }

    useEffect(() => {
        if (id) {
            dispatch(PaymentDetailAsync({id}));
        }
    }, [id])


    /**
     * Template
     */
    return (
        <div className="payment-detail-container">
            {
                payment &&
                <div className="payment-detail">
                    <div className="payment-detail_header">
                        Payment Detail
                        {
                            payment?.status === PaymentsStatus.AWAITING_AUTHORIZATION &&
                            <div className="payments-detail-actions">
                                <a>
                                    <FontAwesomeIcon icon={faEnvelope}/>
                                </a>
                                <a>
                                    <FontAwesomeIcon icon={faCopy}/>
                                </a>
                            </div>
                        }
                    </div>
                    <div className="payment-detail_body">
                        <div className="payment-detail_body_item">
                            <p className="payment-detail_body_item_title">Reference</p>
                            <p className="payment-detail_body_item_description">{payment?.reference}</p>
                        </div>
                        <div className="payment-detail_body_item">
                            <p className="payment-detail_body_item_title">Description:</p>
                            <p className="payment-detail_body_item_description">{payment?.description}</p>
                        </div>
                        <div className="payment-detail_body_item">
                            <p className="payment-detail_body_item_title">Date:</p>
                            <p className="payment-detail_body_item_description">{payment?.createAt}</p>
                        </div>
                        <div className="payment-detail_body_item">
                            <p className="payment-detail_body_item_title">Currency:</p>
                            <p className="payment-detail_body_item_description">{payment.currency}</p>
                        </div>
                        <div className="payment-detail_body_item">
                            <p className="payment-detail_body_item_title">Amount:</p>
                            <p className="payment-detail_body_item_description">{payment?.amount}</p>
                        </div>
                        {payment?.fee &&
                            <div className="payment-detail_body_item">
                                <p className="payment-detail_body_item_title">Fee:</p>
                                <p className="payment-detail_body_item_description">{payment?.fee}</p>
                            </div>
                        }
                        {payment?.vat &&
                            <div className="payment-detail_body_item">
                                <p className="payment-detail_body_item_title">VAT:</p>
                                <p className="payment-detail_body_item_description">{payment.vat}</p>
                            </div>

                        }
                        {
                            payment?.status === PaymentsStatus.AWAITING_AUTHORIZATION &&
                            payment?.link &&
                            <div className="payment-detail_body_item">
                                <p className="payment-detail_body_item_title">Link:</p>
                                <a className="copy-text cursor" onClick={async () => copyText(payment?.link as string)}>
                                    Copy
                                </a>
                            </div>
                        }
                        <div className="payment-detail_body_item no-border">
                            <p className="payment-detail_body_item_title">Status:</p>
                            <p className="payment-detail_body_item_description">
                                <div style={{'backgroundColor': GetPaymentStatusColor(payment.status)}}
                                     className="payment-status-dot"></div>
                                {GetPaymentStatusText(payment.status)}
                            </p>
                        </div>
                        {
                            payment.debtor &&
                            <div>
                                <hr/>
                                <div className="debtor-header">
                                    <h5>Debtor Detail</h5>
                                </div>
                                <div className="payment-detail_body_item">
                                    <p className="payment-detail_body_item_title">Full name:</p>
                                    <p className="payment-detail_body_item_description">{payment.debtor.fullname}</p>
                                </div>
                                <div className="payment-detail_body_item">
                                    <p className="payment-detail_body_item_title">Email:</p>
                                    <p className="payment-detail_body_item_description">{payment.debtor.email}</p>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            }
        </div>
    )
}
