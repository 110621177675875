import { BuildEnviroment } from "../../BuildEnviroment";

export enum AppEnviroment {
    LOCAL = 'LOCAL',
    DEV = 'DEV',
    STAGING = 'STAGING',
    PROD = 'PROD'
}

export const onReadMore = () => {
    switch (BuildEnviroment.GetEnv()) {
        case AppEnviroment.PROD:
            return "https://docs.tryspare.com";
        case AppEnviroment.STAGING:
            return "https://docs.staging.tryspare.com";
        default:
            return "https://docs.dev.tryspare.com";
    }
}
