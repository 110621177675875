import {FunctionComponent, useEffect, useState, useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import "./Account.scss"
import {BusinessAccountModel} from "../../../Models/Api/Settings/BusinesAccountModel";
import {ImageCropper} from "../ImageCropper/ImageCropper";
import {AccountForm} from "./AccountForm";
import {CategoryModel} from "../../../Models/Api/Settings/CategoryModel";
import {useAppDispatch, useAppSelector} from "../../../Store/hooks";
import {settingActionLoading, settingBusinessAccount} from "../../../Store/Settings/SettingsSlice";
import {GetBusinessAccountAsync, UploadBusinessLogo} from "../../../Api/Settings/SettingsApi";
import {ImageUploadModel} from "../../../Models/Api/Settings/ImageUploadModel";
import LoadingUi from "../../UI/LoadingProfile/LoadingProfile";
import SButton from "../../UI/Button/Button";

export const Account: FunctionComponent = () => {
    const dispatch = useAppDispatch();


    /**
     * State
     */
    const businessAccount = useAppSelector(settingBusinessAccount)
    const loading = useAppSelector(settingActionLoading)
    const [account, setAccount] = useState<BusinessAccountModel | null>();
    const categories: CategoryModel[] = [{
        name: "finances",
        id: "7418258"
    },
        {
            name: "restaurants",
            id: "78945612"
        },
    ]

    useEffect(() => {
        dispatch(GetBusinessAccountAsync()).then(e => {
            if (e.meta.requestStatus === "fulfilled") {
                setAccount(businessAccount)
            }
        })
    }, [])

    /**
     * State
     */
    const [openForm, setOpenForm] = useState<boolean>(false);
    const submitBtnRef = useRef<HTMLButtonElement>();

    /**
     * Get image from cropper
     * @param image
     * @param filename
     * @param file
     */
    const getLogoImage = (image: string, file?: File, filename?: string) => {
        if (image && filename && file) {
            const model: ImageUploadModel = {
                Image: file,
                fileName: filename
            };
            dispatch(UploadBusinessLogo({model})).then(e => {
                if (e.meta.requestStatus === "fulfilled") {
                    setAccount({...account, logo: image})
                }
            });
        }
    }

    /**
     * Get submitted account
     */
    const getSubmittedAccount = () => {
        dispatch(GetBusinessAccountAsync()).then(e => {
            if (e.meta.requestStatus === "fulfilled") {
                setAccount(businessAccount)
                setOpenForm(false);
            }
        });
    }


    /**
     * Close form
     */
    const closeForm = () => {
        setOpenForm(false);
    }


    /**
     * Template
     */
    return (<div className="account">
        <div className="account_header">
            <div onClick={() => setOpenForm(true)} className="dialog-footer">
                <h4> Business Account</h4>
                <FontAwesomeIcon icon={faPencil}/>
            </div>
            <div>
                {openForm && <div className="dialog-footer">
                    <SButton label="Cancel" class="warning" type="button" onClick={closeForm}/>
                    <SButton type="button" label="Save" onClick={()=>submitBtnRef?.current?.click()} />
                    
                </div>}
            </div>

        </div>

        {loading && <div className="progress">
                    <div className="progress-bar  indeterminate">
                    </div>
                </div>}
        <div className="account_body">
            {!openForm && !loading
                && <div className="account_body_list">
                    <div className="account_body_item">
                        <label>Registration Number:</label>
                        <p>{businessAccount?.registrationNumber}</p>
                    </div>
                    <div className="account_body_item">
                        <label>Business name:</label>
                        <p>{businessAccount?.businessName}</p>

                    </div>
                    <div className="account_body_item">
                        <label>Sector:</label>
                        <p>{businessAccount?.sector}</p>

                    </div>
                    <div className="account_body_item">
                        <label>Website:</label>
                        <p>{businessAccount?.businessWebSite}</p>
                    </div>
                    <div className="account_body_item">
                        <label>Phone number:</label>
                        <p>{businessAccount?.businessPhoneNumber}</p>

                    </div>
                    <div className="account_body_item">
                        <label>Email:</label>
                        <p>{businessAccount?.contactEmail}</p>
                    </div>
                    <div className="account_body_item">
                        <label>Status:</label>
                        {businessAccount?.isVerified
                            ? <p>Verified</p>
                            : <p>Not verified</p>
                        }
                    </div>
                </div>
            }
          
          
            
            {!openForm && !loading &&<ImageCropper image={businessAccount?.logo} title="Update Logo" getImage={getLogoImage}/>}

            {openForm && businessAccount && <div>
                <AccountForm submitRef={submitBtnRef} data={businessAccount} getData={getSubmittedAccount}
                             categories={categories}
                             close={closeForm}/>
            </div>
            }
        </div>
    </div>)
 
}