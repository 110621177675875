import { FunctionComponent, useEffect, useRef, useState } from "react";
import "./LineChart.scss";
import { LineChartProp } from "../../../Models/Props/LineChartProp";
import { LineChartType } from "../../../Models/Props/LineChartType";
import { Chart } from 'primereact/chart';
import { LineChartOptionsModel } from "./line-chart.model";
import { Button } from 'primereact/button';
import SpareLoader from "../../UI/SpareLoader/SpareLoader";



export const LineChart: FunctionComponent<LineChartProp> = (data: LineChartProp) => {

    /**
     * Set chart value
     */
    const getChartValueFormat = () => {
        if (data.value) {
            switch (data.type) {
                case LineChartType.COUNT:
                    return data.value
                case LineChartType.CURRENCY:
                    return `BHD ${data.value.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                default:
                    return data.value
            }
        }

    }

    /**
     * Template
     */

    const [chartData, setData] = useState<LineChartOptionsModel>({
        labels: [],
        datasets: [
            {
                label: '',
                data: [],
                fill: true,
                borderColor: '#00a4a0',
                backgroundColor: '#00a4a03d',
                borderWidth: 1,
                tension: .4,
                pointStyle: 'circle',
                pointRadius: 4.5,
                pointHoverRadius: 6
            },

        ]
    });

    useEffect(() => {
        if (data.data) {
            setData((prevSate) => {
                return {
                    labels: data.data?.labels,
                    datasets: [
                        {
                            ...prevSate.datasets[0],
                            data: data.data?.data,
                            label: data.title
                        },

                    ]
                }
            })
        }
    }, [data, setData]);


    /** Chart theme */
    const chartOptions = {
        maintainAspectRatio: false,
        aspectRatio: .8,
        interaction: {
            mode: 'nearest',
            intersect: false,
        },

        plugins: {

            legend: {
                display: false,
                align: 'end',
                position: 'top',
                labels: {
                    boxWidth: 10,
                    boxHeight: 9,
                    color: '#000',
                    padding: 10, //20 original value
                    usePointStyle: true,
                },

            },
            tooltip: {
                boxWidth: 20,
                padding: 10,
                usePointStyle: true,

            }


        },

        scales: {

            x: {
                ticks: {
                    color: '#495057' // axe x labels colors

                },
                grid: {
                    color: '#ebedef' // axe x line color
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        },
    }

    const chartRef = useRef<Chart | null>(null);
    const handleDownload = () => {
        if (chartRef.current) {

            const imgLink = document.createElement('a');
            const canvas = chartRef.current.getCanvas();
            var ctx = canvas.getContext('2d');
            ctx!.globalCompositeOperation = 'destination-over'
            ctx!.fillStyle = "white";
            ctx!.fillRect(0, 0, canvas.width, canvas.height);
            imgLink.download = `${data.title}.png`;
            imgLink.href = canvas.toDataURL('image/png', 1);
            imgLink.click();
            imgLink.remove();
            ctx!.globalCompositeOperation = 'source-over'; // re init to original value;

        }
    };
    return (
        <div className="chart_card">
            <div className="chart_card_header">
                <h4 className="chart_card_header_title">{data.title}</h4>
                {data.data && <span className="chart_card_header_value">{getChartValueFormat()}</span>}
            </div>
            {
                !data.loading! &&
                <div className="chart_card_body">
                    {data.data && <div className="title_download-btn">

                        <span className="chart_card_body_description">{data.description?.toUpperCase()}</span>
                        <Button icon="pi pi-download" onClick={handleDownload} className="p-button-rounded p-button-outlined " />

                    </div>}

                    {data.title &&
                        <div className="legend">
                            <div className="legend_circle"></div>

                            <div className="legend_title">
                                {data.title}
                            </div>
                        </div>
                    }

                    <div className="chart_card_body_chart-container">
                        {!data.loading && <Chart ref={chartRef} type="line" data={chartData} options={chartOptions} />}
                        {/* {!data.loading && <ReactECharts option={options} />} */}
                    </div>
                </div>
            }

            {
                data.loading &&
                <SpareLoader/>

            }

        </div>
    )
}

{/* {data.loading && <img src={loader} alt="loader" />} */ }
