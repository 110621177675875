import {InvoiceState} from "../../Models/Store/Invoice/InvoiceState";
import {CustomerInvoiceModel} from "../../Models/Api/CustomerInvoice/CustomerInvoiceModel";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    AddInvoiceAsync,
    DeleteInvoiceAsync,
    GetInvoiceAsync,
    GetInvoicePdf,
    ListInvoiceAsync
} from "../../Api/InvoiceApi/InvoiceApi";
import {ServerResponse} from "../../Models/Api/ServerResponse";
import {SimpleServerResponse} from "../../Models/Api/SimpleServerResponse";
import {RootState} from "../store";
import {downloadFile} from "../Payment/PaymentSlice";


const initialState: InvoiceState = {
    actionLoading: false,
    selectedInvoice: null,
    invoiceList: new Array<CustomerInvoiceModel>(),
    selectedInvoiceId: null,
    pdfFile: null,
}

export const invoiceSlice = createSlice({
    initialState,
    name: "invoice",
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(ListInvoiceAsync.pending, (state) => {
            state.actionLoading = true;
        });
        builder.addCase(ListInvoiceAsync.fulfilled,
            (state, action: PayloadAction<ServerResponse<CustomerInvoiceModel[]>>) => {
                state.actionLoading = false;
                state.invoiceList = action.payload.data;
            });
        builder.addCase(ListInvoiceAsync.rejected, (state) => {
            state.actionLoading = false;
        });

        builder.addCase(AddInvoiceAsync.pending, (state) => {
            state.actionLoading = true;
        });
        builder.addCase(AddInvoiceAsync.fulfilled,
            (state, action: PayloadAction<ServerResponse<string>>) => {
                state.actionLoading = false;
                state.selectedInvoiceId = action.payload.data;
            });
        builder.addCase(AddInvoiceAsync.rejected, (state) => {
            state.actionLoading = false;
        });

        builder.addCase(GetInvoiceAsync.pending, (state) => {
            state.actionLoading = true;
        });
        builder.addCase(GetInvoiceAsync.fulfilled,
            (state, action: PayloadAction<ServerResponse<CustomerInvoiceModel>>) => {
                state.actionLoading = false;
                state.selectedInvoice = action.payload.data;
                state.selectedInvoiceId = action.payload.data.id ?? null;
            });
        builder.addCase(GetInvoiceAsync.rejected, (state) => {
            state.actionLoading = false;
        });


        builder.addCase(GetInvoicePdf.pending, (state) => {
            state.actionLoading = true;
        });
        builder.addCase(GetInvoicePdf.fulfilled,
            (state, action: PayloadAction<File>) => {
                state.actionLoading = false;
                state.pdfFile = action.payload
                downloadFile(action.payload)
            });
        builder.addCase(GetInvoicePdf.rejected, (state) => {
            state.actionLoading = false;
        });

        builder.addCase(DeleteInvoiceAsync.pending, (state) => {
            state.actionLoading = false;
        });
        builder.addCase(DeleteInvoiceAsync.fulfilled,
            (state, action: PayloadAction<SimpleServerResponse>) => {
                state.actionLoading = false;
            });
        builder.addCase(DeleteInvoiceAsync.rejected, (state) => {
            state.actionLoading = false;
        });


    }
})

/**
 * Selectors
 */
export const invoiceList = (state: RootState) => state.invoice.invoiceList;
export const invoiceSelected = (state: RootState) => state.invoice.selectedInvoice;
export const invoiceSelectedId = (state: RootState) => state.invoice.selectedInvoiceId;
export const invoiceActionLoading = (state: RootState) => state.invoice.actionLoading;